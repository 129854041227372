// hooks/useImageFetch.js
import { useState, useEffect } from 'react';
import axios from 'axios';

export const useImageFetch = ({ packageName, pictureType }) => {

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, {
          packageName,
          pictureType
        });
        setImages(response.data);
      } catch (err) {
        setError(`Error fetching ${pictureType}: ${err.message || String(err)}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, [packageName, pictureType]);

  return { images, isLoading, error };
};