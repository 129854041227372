import React, { useState, useEffect } from 'react';
import getSvg from '../../utils/getSvg';
import BreadCrumbs from '../libs/BreadCrumbs';
import Header from './Header';
import PageTitle from "../libs/PageTitle";
import {useParams} from 'react-router-dom';
import graphicFolder from  '../../images/graphic-folder-purple.png';
import Axios from 'axios';
import ThreeSixtyComponent from './ThreeSixtyComponent';

function PhotoFoldersGallery() {
    const [currentPath, setCurrentPath] = useState([]);
    const {packageName, mediaType} = useParams();
    const [structureData, setStructureData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentPicture, setCurrentPicture] = useState('');
    const [packages, setPackages] = useState([]);
    const [showLoader, setShowLoader] = useState(true);
    const bucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const userType = sessionStorage.getItem('userType');
  

  const updatePath = (path) => {
    setCurrentPath(path);
  }; // Helper function to update path

  const resetPath = () => {
    setCurrentPath([]); // Reset to initial state
  };

  const refactorObject = (data) => {
    const newObj = {};

    data.forEach((item) => {
        const keyParts = item.Key.split('/').slice(2);
        const fileName = keyParts.pop();
        let currentObj = newObj;

        keyParts.forEach((part, index) => {
            if (!currentObj[part]) {
                if (index === keyParts.length - 1) {
                    currentObj[part] = {
                        [fileName]: {
                            "_isFile": true,
                            ...item
                        }
                    };
                } else {
                    currentObj[part] = {};
                }
            } else if (index === keyParts.length - 1) {
                currentObj[part][fileName] = {
                    "_isFile": true,
                    ...item
                };
            }
            currentObj = currentObj[part];
        });
    });

    return newObj;
};
    const getPackage = () => {
        try{
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, {packageName: packageName}).then((response) => {
                // setShowLoader(false);
                if(response.data.length > 0){
                    setPackages(response.data);
                }
            }).catch(function (error) {
                console.log(error);
                setShowLoader(false);
            })
        }catch (err){
            setShowLoader(false);
        }
    }

    const getFoldersAndFiles = (mediaType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getFoldersFiles`, {packageName: packageName, mediaType: mediaType}).then((response) => {
            const tempData = refactorObject(response.data);
            setStructureData(tempData);
            setShowLoader(false);
        }).catch(function (error) {
            console.log(error)
        })
    }

    useEffect(() => {
        if(sessionStorage.getItem('isPropertyOwner') === 'true'){
            document.body.classList.add('dark-mode');
        }else{
            document.body.classList.remove('dark-mode');
        }
        getPackage(packageName)
        getFoldersAndFiles(mediaType)
    }, []);

  const content = renderContent(currentPath, updatePath, structureData, setCurrentPicture, setShowModal, bucketPrefix);

  const getPageTitle = (mediaType) => {
    let pageTitle = '';
    switch(mediaType){
        case 'still-pictures':
            pageTitle = 'Still Pictures';
            break;
        case '360-pictures':
            pageTitle = '360 Pictures';
            break;
        case 'three-sixty-pictures':
            pageTitle = '360 Pictures';
            break;
      }
      return pageTitle;
  }

  const getPictureName = (picture) => {
    const fullName = picture.split('/').pop();
    return fullName
  }

  const getLink = (userType) => {
    let link = '';
    if(userType === '500001'){
        link = `survey`;
    }else if(userType === '500002'){
        link = `profeed`;
    }else{
        link = `showdetails/${packageName}`;
    }
    return link;
  }
  const getDetailLink = (userType) => {
    let link = '';
    if(userType === '500001'){
        link = `survdetails/${packageName}`;
    }else if(userType === '500002'){
        link = `prodetails/${packageName}`;
    }else{
        link = `showdetails/${packageName}/0`;
    }
    return link;
  }

  return (
    <main id="fazzad-main">
        <Header />
        <div className="App">
            <div id="app-container">
                {userType === '500001' || userType === '500002' ?
                    <BreadCrumbs
                        header1={'Home'}
                        link1={getLink(userType)}
                        header2={'Package Details'}
                        link2={getDetailLink(userType)}
                        header3={getPageTitle(mediaType)}
                        link3={'&nbsp;'}
                        isLink1Clickable={true}
                        isLink2Clickable={true}
                        isLink2Navigation={true}
                        setIsPackageUpdated={null}
                    />
                    :
                    <BreadCrumbs
                        header1={'Project'}
                        link1={getDetailLink(userType)}
                        header2={getPageTitle(mediaType)}
                        link2={''}
                        link3={''}
                        
                        isLink1Clickable={true}
                        isLink2Clickable={false}
                        isLink2Navigation={false}
                        setIsPackageUpdated={null}
                    />
                }
                <PageTitle
                    headerText={''}
                    subHeaderText={packages.length > 0 ? `${packages[0].packageAddress}${packages[0].packageAddress2.length > 0 ? ` ${packages[0].packageAddress2},` : ','} ${packages[0].packageCity}, ${packages[0].packageState}, ${packages[0].packageZip}` : ''}
                    showSubHeader={true}
                />  
                {showLoader ? <span className="loader loader--l"></span>:
                    <div id="create-package">
                        <div id="folder-container">    
                            <section className="container-nav__layout">
                                <div className="table-container">
                                    <div className='fz-block-sm'></div>
                                    <PageBreadcrumbs path={currentPath} onCrumbClick={updatePath} resetPath={resetPath} getPageTitle={getPageTitle} mediaType={mediaType} />
                                    {content}
                                </div>
                            </section>
                        </div>
                    </div>
                }
            </div>
        </div>
        {showModal && (
            <div className="modal-container">
                <div className="modal modal-lg">
                    <div className="modal__header">
                        <p>{getPictureName(currentPicture)}</p>
                        <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => {setShowModal(false)}}>
                            {getSvg('close')}
                        </button>
                    </div>
                    <div className='modal-content'>
                        {mediaType === 'three-sixty-pictures' ?
                            <div className='row'>
                                <ThreeSixtyComponent image={`${bucketPrefix}/${currentPicture}`}/>
                            </div>
                        :
                            <div className='row'>
                                <img src={`${bucketPrefix}/${currentPicture}`} style={{width: '100%'}}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )}
    </main>
  );
}

function renderContent(path, updatePath, structureData, setCurrentPicture, setShowModal, bucketPrefix) {
    const currentItem = path.reduce((acc, key) => acc[key], structureData);
    if (currentItem.ETag) {
      // This is a file
      return <FileContent file={currentItem} />;
    } else {
      // Get all keys and sort them: folders first, then files
      const sortedKeys = Object.keys(currentItem).sort((a, b) => {
        const isAFile = currentItem[a]._isFile;
        const isBFile = currentItem[b]._isFile;
        if (isAFile && !isBFile) {
          // If A is a file and B is a folder, B should come first
          return 1;
        } else if (!isAFile && isBFile) {
          // If A is a folder and B is a file, A should come first
          return -1;
        }
        // If both are files or both are folders, maintain original order
        return 0;
      });
  
      return (
        <div className="pic-grid">
          {sortedKeys.map((key) => {
            const item = currentItem[key];
            return (
              !item._isFile ? (
                <figure key={key}>
                    <a href="#" onClick={(e) => {e.preventDefault(); updatePath(path.concat(key));}}>                    
                        <img src={graphicFolder} alt={key} />
                        <figcaption>{key}</figcaption>                    
                    </a>
                </figure>
              ) : (
                item.Size > 0 &&
                <figure key={`figure_${key}`}>
                  <img
                    onClick={(e) => { 
                        setCurrentPicture(item.Key);
                        e.preventDefault(); 
                        setShowModal(true);
                    }}
                    style={{width: '100%'}}
                    alt={key}
                    src={`${bucketPrefix}/${item.Key}`}
                  /> 
                  <figcaption>{key}</figcaption>
                </figure>
              )
            );
          })}
        </div>
      );
    }
  }
  

function PageBreadcrumbs({ path, onCrumbClick, resetPath, getPageTitle, mediaType}) {
  return (
    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
        <ol>
            <li>
                <a href="#" onClick={(e) => { e.preventDefault(); resetPath()}}>{getPageTitle(mediaType)}</a>
                {path.length > 0 && <span className="separator">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
                </span>} 
            </li>
           
            {path.map((crumb, index) => (
                <li key={index}>
                    {index !== path.length - 1 ?
                    <a href="#" onClick={(e) => { 
                        e.preventDefault(); 
                        onCrumbClick(path.slice(0, index + 1));
                    }}>
                        {crumb}
                    </a>
                    : crumb
                    }
                    {index !== path.length - 1 && <span className="separator">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>} 
                </li>
            ))}
      </ol>
    </nav>
  );
}

function FileContent({ file }) {
  return (
    <div>
      <p>{file.Key.split('/').pop()}</p>
    </div>
  );
}

export default PhotoFoldersGallery;
