import React, { useState, useEffect } from 'react';
import Header from '../libs/Header';
import getSvg from '../../utils/getSvg';
import AWS from 'aws-sdk';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import UploadSection from '../libs/UploadSection';
import UploadingFilesSection from '../libs/UploadingFilesSection';
import PageTitle from "../libs/PageTitle";
import Axios from 'axios';
import BreadCrumbs from '../libs/BreadCrumbs';
import planAndPicture from '../../images/p&p_walkthru.gif';
import * as formValidator from '../../utils/formValidation';
import { useNavigate, useParams } from 'react-router-dom';
import { uploadWithSignedUrl } from "../../utils/awsHelpers";

const initialFolderStructure = [];
const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
const numberOfSubFolders = [];

const addSubfolderToStructure = (structure, parentId, newSubfolder) => {
  return structure.map(folder => {
    if (folder.id === parentId) {
      return { ...folder, subfolders: [...folder.subfolders, newSubfolder] };
    } else if (folder.subfolders.length > 0) {
      return { ...folder, subfolders: addSubfolderToStructure(folder.subfolders, parentId, newSubfolder) };
    }
    return folder;
  });
};

const deleteFolderFromStructure = (structure, folderId) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      numberOfSubFolders.splice(0, 1);
      return null;
    } else if (folder.subfolders && folder.subfolders.length > 0) {
      return { ...folder, subfolders: deleteFolderFromStructure(folder.subfolders, folderId) };
    }
    return folder;
  }).filter(Boolean);
};

const renameFolderInStructure = (structure, folderId, newName) => {
  return structure.map(folder => {
    if (folder.id === folderId) {
      return { ...folder, name: newName };
    } else if (folder.subfolders && folder.subfolders.length > 0) {
      return { ...folder, subfolders: renameFolderInStructure(folder.subfolders, folderId, newName) };
    }
    return folder;
  });
};

const Folder = (
  {
    folder,
    level = 1,
    isLastInParent = false,
    onAddSubfolder,
    onDeleteFolder,
    onRenameFolder,
    onSelectFiles,
    getWrongFileFormatMessage,
    showWrongImageFormat,
    currentOption,
    setShowWrongImageFormat,
    setShowNoFiles,
    isUploadButtonDisabled
  }
) => {
  const [isVisible, setIsVisible] = useState(true);
  const hasSubfolders = folder.subfolders && folder.subfolders.length > 0;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedFiles, setSelectedFiles] = useState(folder.selectedFiles || []);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameFolderId, setRenameFolderId] = useState('');
  const [renameFolderName, setRenameFolderName] = useState('');

  const handleAddSubfolderClick = () => {
    const newSubfolder = { id: Date.now(), name: newFolderName, subfolders: [], selectedFiles: [] }; // Initialize selectedFiles as an empty array
    onAddSubfolder(folder.id, newSubfolder);
    numberOfSubFolders.push(newSubfolder);
    setIsModalOpen(false);
    setNewFolderName('');
  };

  const handleFileSelect = (event) => {
    try {
      const files = Array.from(event.target.files); // Convert FileList to Array
      setShowNoFiles(false);
      let hasWrongFileFormat = false;
      files.filter((file) => {
        if (!imageExtensionsRegex.test(file.name)) {
          hasWrongFileFormat = true;
        }
      });

      onSelectFiles(folder.id, files); // Pass selected files to the parent component
      setSelectedFiles(files); // Update selected files state for the current folder
    } catch (error) {
      console.error('Error selecting files:', error);
    }
  };


  const handleDeleteFolder = () => {
    onDeleteFolder(folder.id);
  };

  const handleRenameFolderClick = (id, name) => {
    setRenameFolderId(id);
    setRenameFolderName(name);
    setIsRenameModalOpen(true);
  };

  const handleRenameFolderConfirm = () => {
    onRenameFolder(renameFolderId, renameFolderName);
    setIsRenameModalOpen(false);
  };

  const checkNewFolderName = (event) => {
    const newValue = event.target.value;
    // Check if newValue matches the regex pattern
    if (/^[a-zA-Z0-9\s]*$/.test(newValue)) {
      setRenameFolderName(newValue); // Update the state if the input is valid
    }
  };

  return (

    <div className={`l${level > 3 ? 3 : level} ${hasSubfolders && !isLastInParent ? '' : 'no-backbone'} ${isLastInParent ? 'last' : ''}`}>
      {hasSubfolders && !isLastInParent && <div className="backbones"></div>}
      {level > 1 && <div className="curve"></div>}
      <section className="sub-folder">
        <div className="folder-title rounded">
          <div className="container-title">
            {hasSubfolders ?
              <button type="button" className="button-s button-icon" onClick={() => setIsVisible(!isVisible)}>
                <span className="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </button>
              : <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
            <h3 className="h5">{folder.name}</h3>
          </div>
          <div className="button-group">
            <button title="New folder" type="button" className="button-s button-icon" onClick={() => {
              setIsModalOpen(true)
            }}>
              <span className="icon">
                {getSvg('folder')}
              </span>
            </button>

            <button title="Delete folder" type="button" className="button-s button-icon" onClick={handleDeleteFolder}>
              <span className="icon">
                {getSvg('delete')}
              </span>
            </button>
            <button title="Rename folder" type="button" className="button-s button-icon" onClick={() => handleRenameFolderClick(folder.id, folder.name)}>
              <span className="icon">
                {getSvg('edit')}
              </span>
            </button>
          </div>
        </div>
        <div className="file-upload__container">
          {hasSubfolders && (
            <div className="vertical-track">
              <div className="line"></div>
            </div>
          )}
          <div className="content">
            <div className={`form-field file-input ${showWrongImageFormat ? 'error' : ''}`}>
              <input type="file" id={`fileupload-${folder.id}`} onChange={handleFileSelect} className="file-input__input" multiple />
              <label htmlFor={`fileupload-${folder.id}`} className="button button-s button-tertiary">
                <span className="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                </span>
                <span className="text">Choose pictures…</span>
              </label>
              {selectedFiles.map((file, index) => (
                <p key={index}>{file.name}</p>
              ))}
              {showWrongImageFormat && (
                <p className="validation-message">{getWrongFileFormatMessage(currentOption)}</p>
              )}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <div id="modal-5" className="modal-container">
            <div className="modal">
              <div className="modal__content">
                <div className="form-field text-input">
                  <label htmlFor="new-folder-name">New Folder Name</label>
                  <input id="new-folder-name" type="text" value={newFolderName} onChange={(e) => { formValidator.clearEmojis(e.target.value); setNewFolderName(e.target.value) }} placeholder="Folder name" />
                </div>
              </div>
              <div className="modal__action">
                <div className="button-group">
                  <button className="button button-m button-secondary" type="button" onClick={handleAddSubfolderClick}>Create</button>
                  <button className="button button-m button-primary" type="button" onClick={() => setIsModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRenameModalOpen && (
          <div id="modal-5" className="modal-container">
            <div className="modal">
              <div className="modal__content">
                <div className="form-field text-input">
                  <label htmlFor="rename">Rename folder</label>
                  <input id="rename" type="text" value={renameFolderName} onChange={(e) => checkNewFolderName(e)} placeholder="New folder name" />
                </div>
              </div>
              <div className="modal__action">
                <div className="button-group">
                  <button className="button button-m button-secondary" type="button" onClick={handleRenameFolderConfirm}>Rename</button>
                  <button className="button button-m button-primary" type="button" onClick={() => setIsRenameModalOpen(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isVisible && folder.subfolders.map((subfolder, index) => (
          <Folder
            folder={subfolder}
            level={level + 1}
            key={subfolder.id}
            isLastInParent={isLastInParent && index === folder.subfolders.length - 1}
            onAddSubfolder={onAddSubfolder}
            onDeleteFolder={onDeleteFolder}
            onRenameFolder={onRenameFolder}
            onSelectFiles={onSelectFiles}
            getWrongFileFormatMessage={getWrongFileFormatMessage}
            showWrongImageFormat={showWrongImageFormat}
            setShowWrongImageFormat={setShowWrongImageFormat} // Ensure this is passed down
            currentOption={currentOption}
            setShowNoFiles={setShowNoFiles}
            isUploadButtonDisabled={isUploadButtonDisabled}
          />
        ))}
      </section>
    </div>
  );
};

export default function SurveyorFolderStructure() {
  const [folderStructure, setFolderStructure] = useState(initialFolderStructure);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [showDynamicFolders, setShowDynamicFolders] = useState(true);
  const [currentDwgIndex, setCurrentDwgIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [currentStillPictureIndex, setCurrentStillPictureIndex] = useState(0);
  const [currentThreeSixtyIndex, setCurrentThreeSixtyIndex] = useState(0);
  const [droneErrorFileUploaded, setDroneErrorFileUploaded] = useState([]);
  const [dwgErrorFileUploaded, setDwgErrorFileUploaded] = useState([]);
  const [has360Pictures, setHas360Pictures] = useState(0);
  const [hasDronePictures, setHasDronePictures] = useState(0);
  const [hasDwg, setHasDwg] = useState(0);
  const [hasPdfs, setHasPdfs] = useState(0);
  const [hasStillPictures, setHasStillPictures] = useState(0);
  const [pdfErrorFileUploaded, setPdfErrorFileUploaded] = useState([]);
  const [selectedDwg, setSelectedDwg] = useState([]);
  const [selectedFilePdf, setSelectedFilePdf] = useState([]);
  const [selectedFileStillPicture, setSelectedFileStillPicture] = useState([]);
  const [selectedFileThreeSixty, setSelectedFileThreeSixty] = useState([]);
  const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
  const [showWarningContainerMessage, setShowWarningContainerMessage] = useState(false);
  const [stillPictureErrorFileUploaded, setStillPictureErrorFileUploaded] = useState([]);
  const [threeSixtyPictureErrorFileUploaded, setThreeSixtyPictureErrorFileUploaded] = useState([]);
  const [uploadStarted, setUploadStarted] = useState(false);
  const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
  const pdfExtensionsRegex = /\.(pdf)$/i;
  const cadExtensionsRegex = /\.(dwg)$/i;
  const [selectedFileDrone, setSelectedFileDrone] = useState([]);
  const [currentOption, setCurrentOption] = useState('Still Pictures');
  const { packageName } = useParams();
  const [showNoFiles, setShowNoFiles] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [hasFolderName, setHasFolderName] = useState(true);
  const [showDuplicatedFolderNameMessage, setShowDuplicatedFolderNameMessage] = useState(false);
  const [duplicatedFolderName, setDuplicatedFolderName] = useState('');
  const [allDronesUploaded, setAllDronesUploaded] = useState(false);
  const [allPdfsUploaded, setAllPdfsUploaded] = useState(false);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [currentFolderName, setCurrentFolderName] = useState('');
  const [currentFolderFileIndex, setCurrentFolderFileIndex] = useState(0);
  const [folderType, setFolderType] = useState('folder');
  const [totalFolderFiles, setTotalFolderFiles] = useState(0);
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // navigate(`/editpackagefolderspictures/${packageName}/three-sixty-pictures`);
    //   setFolderStructure(initialFolderStructure);
    // if(currentOption === 'Still Pictures'){
    //   setFolderStructure(JSON.parse(sessionStorage.getItem('folderStructure_Still Pictures')));
    // } else if(currentOption === '360 Pictures'){
    //   setFolderStructure(JSON.parse(sessionStorage.getItem('folderStructure_360 Pictures')));
    // } else {
    //   setFolderStructure(initialFolderStructure);
    // }
    getPackage(packageName)
  }, [packageName]);

  const getPackage = (packageName) => {
    try {
      Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackage`, { packageName: packageName }).then((response) => {
        if (response.data.length > 0) {
          setPackages(response.data);
        }
      }).catch(function (error) {
        console.log(error);
      })
    } catch (err) {
      // setShowLoader(false);
    }
  }

  const handleFilesChange = (event, type) => {
    const wrongFiles = [];
    setShowWrongImageFormat(false);
    event.preventDefault();
    const files = [...event.target.files];

    files.filter((file) => {
      if (type.toLowerCase() === 'pdf') {
        setPdfErrorFileUploaded([]);
        files.filter((file) => {
          if (!pdfExtensionsRegex.test(file.name)) {
            wrongFiles.push(file.name);
          }
        });
        setPdfErrorFileUploaded(wrongFiles);
      } else if (type.toLowerCase() === 'dwg') {
        setDwgErrorFileUploaded([]);
        files.filter((file) => {
          if (!cadExtensionsRegex.test(file.name)) {
            wrongFiles.push(file.name);
          }
        });
      } else {
        if (type.toLowerCase() === '360') {
          setThreeSixtyPictureErrorFileUploaded([]);
        } else if (type.toLowerCase() === 'drone') {
          setDroneErrorFileUploaded([]);
        } else if (type.toLowerCase() === 'still') {
          setStillPictureErrorFileUploaded([]);
        }
        if (!imageExtensionsRegex.test(file.name)) {
          wrongFiles.push(file.name);
        }
      }
      return wrongFiles;
    });

    switch (type.toLowerCase()) {
      case 'drone':
        setHasDronePictures(1);
        setDroneErrorFileUploaded(wrongFiles);
        setSelectedFileDrone(event.target.files);
        break;
      case 'still':
        setHasStillPictures(1);
        setStillPictureErrorFileUploaded(wrongFiles);
        setSelectedFileStillPicture(event.target.files);
        break;
      case '360':
        setHas360Pictures(1);
        setThreeSixtyPictureErrorFileUploaded(wrongFiles);
        setSelectedFileThreeSixty(event.target.files);
        break;
      case 'dwg':
        setHasDwg(1);
        setDwgErrorFileUploaded(wrongFiles);
        setSelectedDwg(event.target.files);
        break;
      case 'pdf':
        setHasPdfs(1);
        setPdfErrorFileUploaded(wrongFiles);
        setSelectedFilePdf(event.target.files);
        break;
      default:
        break;
    }
  }

  const handleAddSubfolder = (parentId, newSubfolder) => {
    const updatedStructure = addSubfolderToStructure(folderStructure, parentId, newSubfolder);
    setFolderStructure(updatedStructure);
    sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, JSON.stringify(updatedStructure));
  };

  const handleDeleteFolder = (folderId) => {
    const updatedStructure = deleteFolderFromStructure(folderStructure, folderId);
    setFolderStructure(updatedStructure);
    sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, JSON.stringify(updatedStructure));
  };

  const handleRenameFolder = (folderId, newName) => {
    const updatedStructure = renameFolderInStructure(folderStructure, folderId, newName);
    setFolderStructure(updatedStructure);
    sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, JSON.stringify(updatedStructure));
  };

  const handleCreateFolder = () => {
    setNewFolderName('');
    setShowDuplicatedFolderNameMessage(false);
    setIsModalOpen(true);
  };

  const checkFolderName = (folders, name) => {
    return folders.some(folder => folder.name.toLowerCase() === name.toLowerCase());
  };

  const handleAddFolderConfirm = () => {
    setShowDuplicatedFolderNameMessage(false);
    const newFolder = { id: Date.now(), name: newFolderName, subfolders: [], selectedFiles: [] };
    const updatedStructure = [...folderStructure, newFolder];
    if (checkFolderName(folderStructure, newFolderName)) {
      setShowDuplicatedFolderNameMessage(true);
      setDuplicatedFolderName(newFolderName);
      return;
    }
    if (newFolderName === '') {
      setHasFolderName(false);
      return;
    }

    setFolderStructure(updatedStructure);
    sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, JSON.stringify(updatedStructure));
    setIsModalOpen(false);
    setNewFolderName('');
  };

  const handleSelectFiles = (folderId, files) => {
    const updateSelectedFilesInStructure = (structure, folderId, files) => {
      return structure.map(folder => {
        if (folder.id === folderId) {
          return { ...folder, selectedFiles: files };
        } else if (folder.subfolders) {
          return {
            ...folder,
            subfolders: updateSelectedFilesInStructure(folder.subfolders, folderId, files)
          };
        }
        return folder;
      });
    };

    const updatedStructure = updateSelectedFilesInStructure(folderStructure, folderId, files);
    setFolderStructure(updatedStructure);
    sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, JSON.stringify(updatedStructure));
  };

  const uploadAllFilesToS3 = async () => {
    let option = currentOption;
    if (currentOption === 'Still Pictures') {
      option = 'still-pictures';
    }
    if (currentOption === '360 Pictures') {
      option = 'three-sixty-pictures';
    }

    const uploadFilesRecursively = async (files, folderPath) => {
      await Promise.all(files.map(async (file) => {
        const key = `${packageName}/${option}/${folderPath}/${file.name}`;
        await uploadWithSignedUrl(file, process.env.REACT_APP_S3_BUCKET, key);

        setCurrentFolderFileIndex(prevIndex => prevIndex + 1); // Correctly increment index after each upload
      }));
    };

    const uploadFolderRecursively = async (folders, parentPath = '') => {
      for (const folder of folders) {
        const isEmpty = folder.selectedFiles.every(obj => obj.hasOwnProperty('name'));
        if (!isEmpty) {
          const folderPath = parentPath ? `${parentPath}/${folder.name}` : folder.name;
          if (folder.selectedFiles && folder.selectedFiles.length > 0) {
            setCurrentFolderName(folder.name); // Set the name of the current folder being processed
            setTotalFolderFiles(folder.selectedFiles.length); // Set total files for the current folder
            setCurrentFolderFileIndex(0); // Reset the index for the new folder
            await uploadFilesRecursively(folder.selectedFiles, folderPath);
          }
          if (folder.subfolders && folder.subfolders.length > 0) {
            await uploadFolderRecursively(folder.subfolders, folderPath);
          }
        }
      }
    };

    if (currentOption === 'Still Pictures' || currentOption === '360 Pictures') {
      let option = 'still-pictures';
      if (currentOption === '360 Pictures') {
        option = 'three-sixty-pictures';
      }
      if (folderStructure.length > 0 && folderStructure[0].selectedFiles.length > 0) {
        setIsUploadModalOpen(true);
        await uploadFolderRecursively(folderStructure);
        setAllFilesUploaded(true); // Moved here to indicate all uploads are complete
        sessionStorage.setItem(`folderStructure_${currentOption.trim()}`, initialFolderStructure);
        updatePackageInfo(packageName, option);
      } else {
        setShowNoFiles(true);
      }
    } else {
      if (selectedFilePdf.length > 0 || selectedFileDrone.length > 0 || selectedDwg.length > 0) {
        setIsUploadModalOpen(true);
        await handleUpload(currentOption.toLowerCase(), packageName);
        setUploadStarted(true);
        setAllFilesUploaded(true); // Ensure this is also correctly placed for other conditions
      } else {
        setShowNoFiles(true);
      }
    }
  };

  const updatePackageInfo = async (packageName, pictureType) => {
    try {
      Axios.post(`${process.env.REACT_APP_LOCALHOST}/updatePackagePicturesDetail`, {
        packageName: packageName,
        pictureType: pictureType
      }).then((response) => {
        return;
      });
    } catch (err) {
      console.log('error sending email to property owner when package is created = ', err);
    }
  }


  const handleUpload = async (type, packageName) => {
    try {
      setShowWarningContainerMessage(false);
      switch (type) {
        case 'pdf files':
          try {
            if (selectedFilePdf.length > 0) {
              setIsUploadModalOpen(true);
              if (!uploadStarted) {
                setUploadStarted(true);
              }
              let indPdf = 0;
              for (const pdf of selectedFilePdf) {
                await uploadPromise([pdf], 'pdf', packageName);
                indPdf++;
                setCurrentPdfIndex(indPdf);
              }
              setAllPdfsUploaded(true);
              setAllFilesUploaded(true);
              updatePackageInfo(packageName, 'pdf-files');
            } else {
              setAllFilesUploaded(false);
              setUploadStarted(false);
            }
          } catch (e) {
            console.error(`Uploading PDF file error: ${e}`);
          }
          break;
        case 'drone pictures':
          try {
            if (selectedFileDrone.length > 0) {
              setIsUploadModalOpen(true);
              if (!uploadStarted) {
                setUploadStarted(true);
              }
              let indDrone = 0;
              for (const image of selectedFileDrone) {
                await uploadPromise([image], 'drone', packageName);
                indDrone++;
                setCurrentIndex(indDrone);
              }
              setAllDronesUploaded(true);
              setAllFilesUploaded(true);
              updatePackageInfo(packageName, 'drone-pictures');
            } else {
              setAllFilesUploaded(false);
              setUploadStarted(false);
            }
          } catch (e) {
            console.error(`Uploading Drone picture file error: ${e}`);
          }
          break;
        case 'cad file':
          try {
            if (selectedDwg.length > 0) {
              setIsUploadModalOpen(true);
              if (!uploadStarted) {
                setUploadStarted(true);
              }
              let indDwg = 0;
              for (const image of selectedDwg) {
                const fileExtension = image.name.substr(image.name.length - (image.name.length - image.name.lastIndexOf('.')))
                if (fileExtension === '.dwg') {
                  await uploadPromise([image], 'dwg', packageName);
                  indDwg++;
                  setCurrentDwgIndex(indDwg);
                }
              }
              setAllFilesUploaded(true);
              updatePackageInfo(packageName, 'dwg-files');
            } else {
              setAllFilesUploaded(false);
              setUploadStarted(false);
            }
          } catch (e) {
            console.error(`Uploading Dwg picture file error: ${e}`);
          }
          break;
        default:
          break;
      }
    } catch (e) {
      setUploadStarted(false);
      console.log('error =  ', e);
    }
  }

  const getWrongFileFormatMessage = (type) => {
    let arrayLength = 0;
    let addS = '';
    let isAre = 'is';
    let theyIt = 'It';
    let fileType = 'image';
    let fileFormat = '.jpg, .jpeg, .png';
    switch (type) {
      case '360':
        arrayLength = threeSixtyPictureErrorFileUploaded.length;
        if (arrayLength > 1) {
          addS = 's';
          isAre = 'are';
          theyIt = 'They';
        }
        break;
      case 'drone':
        arrayLength = droneErrorFileUploaded.length;
        if (arrayLength > 1) {
          addS = 's';
          isAre = 'are';
          theyIt = 'They';
        }
        break;
      case 'still':
        arrayLength = stillPictureErrorFileUploaded.length;
        if (arrayLength > 1) {
          addS = 's';
          isAre = 'are';
          theyIt = 'They';
        }
        break;
      case 'dwg':
        arrayLength = dwgErrorFileUploaded.length;
        if (arrayLength > 1) {
          addS = 's';
          isAre = 'are';
          theyIt = 'They';
        }
        fileFormat = '.dwg';
        break;
      case 'pdf':
        fileType = 'pdf';
        arrayLength = pdfErrorFileUploaded.length;
        if (arrayLength > 1) {
          addS = 's';
          isAre = 'are';
          theyIt = 'They';
        }
        fileFormat = '.pdf';
        break;
    }
    setShowWrongImageFormat(true)
    return `You have selected file${addS} that ${isAre} not a ${fileType}${addS} file${addS}. All files must have the proper format. (${fileFormat})`;
  }

  const uploadPromise = async (arr, type, packageName) => {
    try {
      let s3BucketName = 'three-sixty-pictures';
      switch (type) {
        case 'drone':
          s3BucketName = 'drone-pictures';
          break;
        case 'pdf':
          s3BucketName = 'pdf-files';
          break;
        case 'still':
          s3BucketName = 'still-pictures';
          break;
        case 'dwg':
          s3BucketName = 'dwg-files';
          break;
        default:
          s3BucketName = 'three-sixty-pictures';
          break;
      }
      const contentType = type === 'pdf' ? "application/pdf" : "/*";
      for (let i = 0; i < arr.length; i++) {
        const file = arr[i];
        const uploadFileName = cleanMediaUrl(file.name);
        const uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
        try {
          const params = {
            Bucket: 'fazzad-projects',
            Key: uploadPath,
            Body: file,
            ContentType: contentType
          };

          await uploadWithSignedUrl(file, 'fazzad-projects', uploadPath);
          console.log(`File uploaded successfully. File location: ${uploadPath}`);
        } catch (error) {
          console.error(`Upload failed for ${file.name}: ${error}`);
        }
      }
    } catch (error) {
      console.error(`Batch upload failed: ${error}`);
    }
  };

  const clearFolderName = (name) => {
    const folderName = name.trim();
    setNewFolderName(folderName);
  }

  const handleChange = (event) => {
    setDuplicatedFolderName('');
    const newValue = event.target.value;
    // Check if newValue matches the regex pattern
    if (/^[a-zA-Z0-9\s]*$/.test(newValue)) {
      setNewFolderName(newValue); // Update the state if the input is valid
    }
  };
  const clearSelectedFiles = () => {
    setSelectedFileDrone([]);
    setSelectedDwg([]);
    setSelectedFilePdf([]);
    setCurrentDwgIndex(0);
    setCurrentPdfIndex(0);
    setCurrentIndex(0);
    setFolderStructure([]);
    setAllFilesUploaded(false);
  };

  const getFileType = () => {
    switch (currentOption) {
      case 'Still Pictures':
        return 'Still pictures';
      case '360 Pictures':
        return '360 pictures';
      case 'Drone Pictures':
        return 'Drone pictures';
      case 'CAD File':
        return 'CAD file';
      case 'PDF Files':
        return 'PDF file';
      default:
        return '';
    }
  }

  const isUploadButtonDisabled = () => {
    if (
      folderStructure.length === 0 &&
      selectedDwg.length === 0 &&
      selectedFilePdf.length === 0 &&
      selectedFileDrone.length === 0
    ) {
      return true;
    }

    return showWrongImageFormat;
  };

  const resetFiles = () => {
    setSelectedDwg([]);
    setSelectedFilePdf([]);
    setSelectedFileDrone([]);
    setSelectedFileStillPicture([]);
    setSelectedFileThreeSixty([]);
    setDroneErrorFileUploaded([]);
    setDwgErrorFileUploaded([]);
    setPdfErrorFileUploaded([]);
    setStillPictureErrorFileUploaded([]);
    setThreeSixtyPictureErrorFileUploaded([]);
    setShowWrongImageFormat(false);
    setShowNoFiles(false);
    setAllFilesUploaded(false);
    setUploadStarted(false);
    setAllDronesUploaded(false);
    setAllPdfsUploaded(false);
    setHasDronePictures(0);
    setHasDwg(0);
    setHasPdfs(0);
    setHasStillPictures(0);
    setHas360Pictures(0);
  };

  return (
    <div id="fazzad-site-container">
      <Header />
      <main id="fazzad-main">
        <div id="app-container">
          <BreadCrumbs
            header1={'Feed'}
            link1={`survey`}
            header2={'File Upload'}
            link2={''}
            isLink1Clickable={true}
            isLink2Clickable={false}
            isLink2Navigation={false}
            header3={''}
            link3={''}
            setIsPackageUpdated={null}
          />
          <div id="create-package">
            <PageTitle
              headerText={'Upload pictures and files'}
              subHeaderText={packages.length > 0 ? `${packages[0].packageAddress}${packages[0].packageAddress2.length > 0 ? ` ${packages[0].packageAddress2},` : ','} ${packages[0].packageCity}, ${packages[0].packageState}, ${packages[0].packageZip}` : ''}
              showSubHeader={true}
            />
            <div id="folder-container">
              <section className="container-nav__layout">
                <aside>
                  <nav className="nav-file-type">
                    <ul>
                      <li className={currentOption === 'Still Pictures' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(true); setCurrentOption('Still Pictures'); navigate(`/editpackagefolderspictures/${packageName}/still-pictures`) }}>Still Pictures</a>
                      </li>
                      <li className={currentOption === '360 Pictures' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(true); setCurrentOption('360 Pictures'); navigate(`/editpackagefolderspictures/${packageName}/three-sixty-pictures`) }}>360 Pictures</a>
                      </li>
                      <li className={currentOption === 'Drone Pictures' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(false); setCurrentOption('Drone Pictures') }}>Drone Pictures</a>
                      </li>
                      <li className={currentOption === 'CAD File' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(false); setCurrentOption('CAD File') }}>CAD File</a>
                      </li>
                      <li className={currentOption === 'PDF Files' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(false); setCurrentOption('PDF Files') }}>PDF File</a>
                      </li>
                      <li className={currentOption === 'Plan & Picture' ? "current" : "item"}>
                        <a href="" onClick={(e) => { e.preventDefault(); resetFiles(); setShowDynamicFolders(false); setCurrentOption('Plan & Picture') }}>Plan & Picture</a>
                      </li>
                    </ul>
                  </nav>
                </aside>
                <div className="table-container">
                  <div className="button-group">
                    {showDynamicFolders && (
                      <button type="button" className="button-m button-tertiary" onClick={handleCreateFolder} disabled={showWrongImageFormat} >
                        <span className="icon">
                          {getSvg('folder')}
                        </span>
                        <span className="text">New folder</span>
                      </button>
                    )}
                    {currentOption !== 'Plan & Picture' && (
                      <button type="button" className="button-m button-primary" onClick={uploadAllFilesToS3} disabled={isUploadButtonDisabled()} >
                        <span className="icon">
                          {getSvg('upload')}
                        </span>
                        <span className="text">Upload {getFileType()}</span>
                      </button>
                    )}
                  </div>
                  {showNoFiles && (
                    <>
                      <div className="alert alert--error">
                        <div className="alert__content">
                          <p className="alert__heading">You haven't selected any {getFileType()} yet.</p>
                        </div>
                        <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setShowNoFiles(false)}>
                          <span className="icon">
                            {getSvg('close')}
                          </span>
                        </button>
                      </div>
                      <div className="fz-block-sm">&nbsp;</div>
                    </>
                  )}
                  {showDynamicFolders ?
                    <>
                      <div id="folder-wrapper" className={folderStructure.length > 0 ? "outlined" : ""}>
                        <div className="structure">
                          {folderStructure.map((folder, index) => (
                            <Folder
                              folder={folder}
                              key={folder.id}
                              isLastInParent={index === folderStructure.length - 1}
                              onAddSubfolder={handleAddSubfolder}
                              onDeleteFolder={handleDeleteFolder}
                              onRenameFolder={handleRenameFolder}
                              onSelectFiles={handleSelectFiles}
                              getWrongFileFormatMessage={getWrongFileFormatMessage}
                              showWrongImageFormat={showWrongImageFormat}
                              setShowWrongImageFormat={setShowWrongImageFormat}
                              currentOption={currentOption}
                              setShowNoFiles={setShowNoFiles}
                              setDuplicatedFolderName={setDuplicatedFolderName}
                              UploadingFilesSection={UploadingFilesSection}
                            />
                          ))}
                        </div>
                      </div>
                    </>
                    :
                    <>
                      {/* <section className="form-cover outlined"></section> */}
                      {currentOption === 'PDF Files' && (
                        <section className="form-pdfs outlined">
                          <UploadSection
                            header={'PDF'}
                            subHeader={'files'}
                            divId={'upload-pdfs'}
                            fileType={'pdf'}
                            selectedFiles={selectedFilePdf}
                            errorFileUploaded={pdfErrorFileUploaded}
                            handleFilesChange={handleFilesChange}
                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                            isMultiple={true}
                            showLegend={false}
                          />
                        </section>
                      )}
                      {currentOption === 'Still Pictures' && (
                        <section className="form-pictures outlined">
                          <UploadSection
                            header={'Still Pictures'}
                            subHeader={''}
                            divId={'upload-pictures'}
                            fileType={'still'}
                            selectedFiles={selectedFileStillPicture}
                            errorFileUploaded={stillPictureErrorFileUploaded}
                            handleFilesChange={handleFilesChange}
                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                            isMultiple={true}
                            showLegend={false}
                          />
                        </section>
                      )}
                      {currentOption === '360 Pictures' && (
                        <section className="form-360-pictures outlined">
                          <UploadSection
                            header={'360'}
                            subHeader={'pictures'}
                            divId={'upload-360-pictures'}
                            fileType={'360'}
                            selectedFiles={selectedFileThreeSixty}
                            errorFileUploaded={threeSixtyPictureErrorFileUploaded}
                            handleFilesChange={handleFilesChange}
                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                            isMultiple={true}
                            showLegend={false}
                          />
                        </section>
                      )}
                      {currentOption === 'Drone Pictures' && (
                        <section className="form-drone-pictures outlined">
                          <UploadSection
                            header={'Drone'}
                            subHeader={'pictures'}
                            divId={'upload-drone-pictures'}
                            fileType={'drone'}
                            selectedFiles={selectedFileDrone}
                            errorFileUploaded={droneErrorFileUploaded}
                            handleFilesChange={handleFilesChange}
                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                            isMultiple={true}
                            showLegend={false}
                          />

                        </section>
                      )}
                      {currentOption === 'CAD File' && (
                        <section className="form-cad outlined">
                          <UploadSection
                            header={'CAD'}
                            subHeader={'file'}
                            divId={'upload-cad'}
                            fileType={'dwg'}
                            selectedFiles={selectedDwg}
                            errorFileUploaded={dwgErrorFileUploaded}
                            handleFilesChange={handleFilesChange}
                            getWrongFileFormatMessage={getWrongFileFormatMessage}
                            setShowNoFiles={setShowNoFiles}
                            isMultiple={false}
                            showLegend={false}
                          />
                        </section>
                      )}
                      {currentOption === 'Plan & Picture' && (
                        <section className="form-cad outlined">
                          <p>Coming Soon!</p>
                          <img src={planAndPicture} alt="Coming Soon" />
                        </section>
                      )}
                    </>
                  }
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
      {isModalOpen && (
        <div id="modal-5" className="modal-container">
          <div className="modal">
            <div className="modal__content">
              <div className="form-field text-input">
                <label htmlFor="new-folder-name">New Folder Name</label>
                <input id="new-folder-name" type="text" value={newFolderName} onChange={handleChange} onBlur={(e) => clearFolderName(e.target.value)} placeholder="Folder name" />
              </div>
            </div>
            {duplicatedFolderName !== '' && (
              <div className="alert alert--error">
                <div className="alert__content">
                  <p className="alert__heading">The folder name "{duplicatedFolderName}" already exists. Please choose a different name.</p>
                </div>
                <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setDuplicatedFolderName('')}>
                  <span className="icon">
                    {getSvg('close')}
                  </span>
                </button>
              </div>

            )}
            {!hasFolderName && (
              <div className="alert alert--error">
                <div className="alert__content">
                  <p className="alert__heading">Please enter a folder name.</p>
                </div>
                <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setHasFolderName(true)}>
                  <span className="icon">
                    {getSvg('close')}
                  </span>
                </button>
              </div>

            )}
            <div className="modal__action">
              <div className="button-group">
                <button className="button button-m button-secondary" type="button" onClick={handleAddFolderConfirm}>Create</button>
                <button className="button button-m button-primary" type="button" onClick={() => { setIsModalOpen(false); setHasFolderName(true); setDuplicatedFolderName('') }}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isUploadModalOpen && (
        <div className="modal-container">
          <div className="modal">
            <>
              <div className="modal__header">
                <h2 className="modal-title h4">File Upload Progress</h2>
              </div>
              <div className="modal__content">
                <div className="file-uploads">
                  {currentFolderName !== '' ?
                    <section>
                      {allFilesUploaded ?
                        <p className='message'>
                          All pictures have been uploaded!&nbsp; {getSvg('green-checkmark')}
                        </p>
                        :
                        <>
                          <h5>{currentFolderName}</h5>
                          <p className="message"> Uploading picture {currentFolderFileIndex} of {totalFolderFiles} from {folderType} {currentFolderName}  <span className="loader loader--s"></span></p>
                        </>
                      }
                    </section>
                    : ''
                  }
                  {selectedFilePdf.length > 0 ?
                    <section>
                      <h5>PDFs</h5>
                      {currentPdfIndex < selectedFilePdf.length && selectedFilePdf.length > 0 ?
                        currentPdfIndex > 0 ?
                          <p className="message"> Uploading PDF {currentPdfIndex} of {selectedFilePdf.length}<span className="loader loader--s"></span></p>
                          :
                          ''
                        :
                        <p className='message'>
                          All pdf files have been uploaded!&nbsp; {getSvg('green-checkmark')}
                        </p>
                      }
                      {selectedFilePdf.length > 0 && currentPdfIndex === 0 && (<p className="message">Preparing PDF{selectedFilePdf.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></p>)}
                    </section>
                    : ''
                  }
                  {selectedFileStillPicture.length > 0 && (
                    <section>
                      <h5>Still Pictures</h5>
                      {currentStillPictureIndex < selectedFileStillPicture.length && selectedFileStillPicture.length > 0 ?
                        currentStillPictureIndex > 0 ?
                          <p className="message"> Uploading picture {currentStillPictureIndex} of {selectedFileStillPicture.length}<span className="loader loader--s"></span></p>
                          :
                          ''
                        :
                        <p className='message'>
                          All pictures have been uploaded!&nbsp; {getSvg('green-checkmark')}
                        </p>
                      }
                      {selectedFileStillPicture.length > 0 && currentStillPictureIndex === 0 && (<p className="message">Preparing picture{selectedFileStillPicture.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></p>)}
                    </section>
                  )}
                  {selectedFileThreeSixty.length > 0 && (
                    <section>
                      <h5>360 Pictures</h5>
                      {currentThreeSixtyIndex < selectedFileThreeSixty.length && selectedFileThreeSixty.length > 0 ?
                        currentThreeSixtyIndex > 0 && (
                          <p className="message">Uploading 360 picture {currentThreeSixtyIndex} of {selectedFileThreeSixty.length}<span className="loader loader--s"></span></p>
                        )
                        :
                        <p className='message'>
                          All 360 pictures have been uploaded!&nbsp; {getSvg('green-checkmark')}
                        </p>
                      }
                      {selectedFileThreeSixty.length > 0 && currentThreeSixtyIndex === 0 && (<p className="message">Preparing picture{selectedFileThreeSixty.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></p>)}
                    </section>
                  )}
                  {selectedFileDrone.length > 0 && (
                    <section>
                      <h5>Drone Pictures</h5>
                      {currentIndex < selectedFileDrone.length && selectedFileDrone.length > 0 ? currentIndex > 0 && (
                        <p className="message">Uploading Drone picture {currentIndex} of {selectedFileDrone.length}<span className="loader loader--s"></span></p>
                      )
                        :
                        <p className='message'>
                          All drone pictures have been uploaded!&nbsp; {getSvg('green-checkmark')}
                        </p>
                      }
                      {selectedFileDrone.length > 0 && currentIndex === 0 && (<p className="message">Preparing picture{selectedFileDrone.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></p>)}
                    </section>
                  )}
                  {selectedDwg.length > 0 && (
                    <section>
                      <h5>CAD File</h5>
                      {currentDwgIndex < selectedDwg.length && selectedDwg.length > 0 ?
                        currentDwgIndex > 0 && (
                          <p className="message">Uploading CAD file {currentDwgIndex} of {selectedDwg.length}<span className="loader loader--s"></span></p>
                        )
                        :
                        <p className='message'>
                          CAD file uploaded!&nbsp; {getSvg('green-checkmark')}

                        </p>
                      }
                      {selectedDwg.length > 0 && currentDwgIndex === 0 && (<p className="message">Preparing CAD file{selectedDwg.length > 1 ? 's' : ''} to be uploaded <span className="loader loader--s"></span></p>)}
                    </section>
                  )}
                </div>
                {allFilesUploaded && (
                  <div className="modal__action">
                    <div className="button-group">
                      <button className="button button-m button-primary" type="button" onClick={() => { setIsUploadModalOpen(false); setCurrentFolderName(''); clearSelectedFiles(folderStructure); }}>Close</button>
                    </div>
                  </div>
                )}
              </div>
            </>

          </div>
        </div>
      )}
    </div>
  )
}