import React from 'react';
import {useNavigate, useLocation, useParams} from 'react-router-dom';
import Header from "./Header";
import BreadCrumbs from "./BreadCrumbs";
import {useGetPdfsAndPlanAndPictures} from "./plan-and-picture/hooks/usePlanAndPicture";
import {LoadingSpinner} from "./plan-and-picture/page-select-modal/loading-spinner/loading-spinner";

const styles = {
  "planList": {
    "borderRadius": "8px",
    "width": "100%",
    "margin": "0 auto",
    "maxWidth": "var(--break-large)",
    "padding": "2rem"
  },
  "title": {
    "margin": "2rem 0",
    "color": "#333"
  },
  "fileNameHeader": {
    "fontFamily": "'Nunito', sans-serif",
    "fontSize": "16px",
    "fontWeight": "800",
    "borderBottom": "0.125rem solid var(--color-grey--300)",
    "paddingBottom": ".5rem"
  },
  "planItemList": {
    "listStyleType": "none",
    "padding": "0",
    "marginBottom": ".5rem"
  },
  "planItem": {
    "borderBottom": "0.0625rem solid var(--color-grey--200)",
    "padding": ".5rem 0 .5rem 1rem",
    "fontFamily": "'Nunito', sans-serif",
  },
  "planLink": {
    "color": "#6200ee",
    "textDecoration": "none",
    "fontSize": "16px",
    "display": "block",
  },
  "planLinkHover": {
    "textDecoration": "underline"
  },
  "imageTypeSelector": {
    "display": "flex",
    "justifyContent": "flex-start",
    "marginBottom": "20px"
  },
  "selectorButton": {
    "backgroundColor": "#f0f0f0",
    "border": "none",
    "padding": "8px 16px",
    "marginRight": "10px",
    "borderRadius": "20px",
    "cursor": "pointer",
    "fontSize": "14px"
  },
  "selectorButtonActive": {
    "backgroundColor": "#ffffff",
    "boxShadow": "0 2px 4px rgba(0, 0, 0, 0.1)"
  }
}

export default function PlanAndPictureList() {
  const navigate = useNavigate();
  const location = useLocation();
  const {packageName} = useParams();
  const { planAndPictures: plans, error } = useGetPdfsAndPlanAndPictures(packageName);
  const viewType = location.state?.viewType ?? "surveyor";

  const handleClick = async (item) => {
    const pdfId = item.pdfId.replaceAll('__', '/');
    const url = `https://fazzad-projects.s3.us-west-1.amazonaws.com/${pdfId}.pdf`;
    fetch(url).then(async (res) => {
      const pdfBlob = await res.blob();

      navigate(`/plan-and-picture/${packageName}/${item.pdfId}`, {
        state: {
          viewType,
          pdfBlob,
          packageName,
          selectedPages: item.pdfPages
        }
      })
    }).catch(error => {
      console.log('ERROR LOADING PDF', error);
    })
  };
  if (!plans?.length && !error) return <LoadingSpinner />;
  if (error) return <p>{error?.message || 'There was an error'}</p>;
  return (
      <>
        <Header/>
        <div style={styles.planList}>
          {viewType === 'surveyor' && <BreadCrumbs
              header1={'Home'}
              isLink1Clickable={true}
              link1={"survey"}
              header2={'Package Details'}
              link2={`survdetails/${packageName}`}
              isLink2Clickable={true}
              isLink2Navigation={true}
              header3={"list"}
          />}
          <h1 className={"h3"}>Plan & Picture</h1>
          <div className="table-container">
            <table>
              <thead>
              <tr>
                <th>File name</th>
              </tr>
              </thead>
              {plans?.length > 0 &&
                  <tbody>
                  {plans?.map((plan, index) => {
                    return (
                        <tr key={`tr_${index}`}>
                          <td key={`td_${index}`} className="fz-break-all">
                            <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleClick(plan);
                                }}
                            >
                              {plan.planName}
                            </a>
                          </td>
                        </tr>
                    )
                  })}
                  </tbody>}
            </table>
          </div>
        </div>
      </>
  );
};