/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import Header from './Header';
import AlertMessage from '../libs/AlertMessage';
import MailingAddressForm from '../libs/MailingAddressForm';
import * as formValidator from '../../utils/formValidation';
import useGooglePlaceAutoComplete from "../../service/google_place_autocomplete";
import { loadGoogleMapsAPI } from '../../utils/loadGoogleMapsAPI';
import { getLoginUser } from "../../utils/userHelper";
import { Modal } from "./Modal";
import { ChangePassword } from "./ChangePassword";
import { updateUser } from "../../utils/userHelper";
export default function Profiles() {
    const navigate = useNavigate();
    const handleGoToLogin = () => navigate('/login');
    const { userType } = useParams();
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const userId = sessionStorage.getItem('userId');
    const [emailProfile, setEmailProfile] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [hasPayPalInfo, setHasPayPalInfo] = useState(true);
    const [zip, setZip] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [payPalInfo, setPayPalInfo] = useState('');
    const [payPalPaymentType, setPayPalPaymentType] = useState('phone');
    const [phone, setPhone] = useState('');
    const [license, setLicense] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showNoChanges, setShowNoChanges] = useState(false);
    const [hasCompanyName, setHasCompanyName] = useState(false);
    const [hasFirstName, setHasFirstName] = useState(false);
    const [hasLastName, setHasLastName] = useState(false);
    const [hasEmail, setHasEmail] = useState(false);
    const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
    const [hasPassword, setHasPassword] = useState(false);
    const [hasConfirmPassword, setHasConfirmPassword] = useState(false);
    const [hasAddress, setHasAddress] = useState(false);
    const [hasCity, setHasCity] = useState(true);
    const [hasZip, setHasZip] = useState(true);
    const [hasBothPasswords, setHasBothPasswords] = useState(true);
    const [hasState, setHasState] = useState(true);
    const [usePayPal, setUsePayPal] = useState(true);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const addressRef = useRef();
    const googleAutoCompleteSvc = useGooglePlaceAutoComplete();
    const feedType = parseInt(userType) === 500001 ? 'survey' : 'profeed';
    let autoComplete = "";
    const checkForm = () => {
        let isValid = true;
        if (
            city.trim().length === 0 ||
            emailProfile.trim().length === 0 || state.trim().length === 0 ||
            city.trim().length === 0 || firstName.trim().length === 0 ||
            lastName.trim().length === 0 || zip.trim().length < 5 ||
            password !== confirmPassword ||
            !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip) || !emailProfile.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        ) {
            isValid = false;
        }
        if (typeof address !== 'undefined' && typeof address !== null && typeof address !== 'boolean') {
            if (addressRef.current.value.trim().length === 0) {
                isValid = false;
            }
        }

        if (usePayPal) {
            if (payPalPaymentType === 'phone') {
                if (formValidator.checkPhoneNumber(payPalInfo)) { setHasPayPalInfo(true); } else { setHasPayPalInfo(false); isValid = false; }
            } else {
                if (formValidator.checkEmail(payPalInfo)) { setHasPayPalInfo(true); } else { setHasPayPalInfo(false); isValid = false; }
            }
        }
        return isValid;
    }

    const handlePayPalTypeChange = (event) => {
        setPayPalPaymentType(event.target.value);
    };

    const handleUsePayPal = (e) => {
        if (e.target.checked) {
            setPayPalPaymentType('phone');
            setPayPalInfo('');
            setUsePayPal(0);
        } else {
            setUsePayPal(1);
        }
    }

    const updateProfile = () => {
        setShowConfirmation(false);
        if (formValidator.checkAddress(address)) { setHasAddress(true); } else { setHasAddress(false); }
        if (formValidator.checkEmail(emailProfile)) { setHasEmail(true); } else { setHasEmail(false); }
        if (formValidator.checkCity(city)) { setHasCity(true); } else { setHasCity(false); }
        if (formValidator.checkZip(zip)) { setHasZip(true); } else { setHasZip(false); }
        if (formValidator.checkState(state)) { setHasState(true); } else { setHasState(false); }


        // checkEmail(emailProfile);
        checkFirstName(firstName);
        checkLastName(lastName);
        checkCompany(company);
        checkPhoneNumber(phone);
        checkBothPasswords(password, confirmPassword);

        if (checkForm()) {
            updateUser({
                email: emailProfile,
                address: address,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                firstName: firstName,
                lastName: lastName,
                company: company,
                phone: phone,
                license: license,
                password: password,
                userId: userId,
                usePayPal: usePayPal,
                payPalPaymentType: payPalPaymentType,
                payPalInfo: payPalInfo,
            }).then((response) => {
                if (typeof response !== 'undefined' && response !== null) {
                    setShowNoChanges(false);
                    setShowConfirmation(true);
                    sessionStorage.setItem('userName', `${firstName} ${lastName}`);
                    setTimeout(() => {
                        setShowConfirmation(false);
                        setShowNoChanges(false);
                    }, 3500);
                } else {
                    console.log('it did not work');
                    // setIsPackageCreated(false);
                }

            }).catch(function (error) {
                console.log('catch error = ', error);
                // setIsPackageCreated(false);
            });
        }
    }

    const checkBothPasswords = (pwd1, pwd2) => {
        let passwordsCheck = true;
        if (pwd1 !== pwd2) {
            passwordsCheck = false;
        }
        setHasBothPasswords(passwordsCheck);
        return passwordsCheck;
    }

    const checkCompany = (company) => {
        if (company.trim().length === 0) {
            setHasCompanyName(false);
        } else {
            setHasCompanyName(true);
        }
    }

    const checkAddress = (address) => {
        if (address.trim().length === 0) {
            setHasAddress(false);
        } else {
            setHasAddress(true);
        }
    }

    const checkFirstName = (firstName) => {
        if (firstName.trim().length === 0) {
            setHasFirstName(false);
        } else {
            setHasFirstName(true);
        }
    }

    const checkLastName = (lastName) => {
        if (lastName.trim().length === 0) {
            setHasLastName(false);
        } else {
            setHasLastName(true);
        }
    }

    const checkCity = (city) => {
        if (city.trim().length === 0) {
            setHasCity(false);
        } else {
            setHasCity(true);
        }
    }

    const checkZip = (zip) => {
        if (zip.trim().length > 0) {
            const isValid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
            if (zip.trim().length < 5) {
                setHasZip(false);
            } else {
                setHasZip(true);
            }
        } else {
            setHasZip(false);
        }
    };

    const checkPhoneNumber = (phone) => {
        if (phone.trim().length > 0) {
            if (!phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
                setHasPhoneNumber(false);
            } else {
                setHasPhoneNumber(true);
            }
        } else {
            setHasPhoneNumber(false);
        }
    };

    const checkState = (state) => {
        if (state.trim().length === 0) {
            setHasState(false);
        } else {
            setHasState(true);
        }
    }

    const handleAddressSelect = async () => {
        let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
        addressRef.current.value = addressObj.address1;
        setAddress(addressObj.address1);
        setCity(addressObj.locality);
        setState(addressObj.adminArea1Short);
        setZip(addressObj.postalCode);
    };

    useEffect(() => {
        if (isLoggedIn === 'true') {
            setHasCompanyName(true);
            setHasFirstName(true);
            setHasLastName(true);
            setHasEmail(true);
            setHasPhoneNumber(true);
            setHasPassword(true);
            setHasConfirmPassword(true);
            setHasAddress(true);
            setHasZip(true);
            setHasCity(true);
            async function initGooglePlaces() {
                try {
                    await loadGoogleMapsAPI('initGoogleMaps');
                    autoComplete = await googleAutoCompleteSvc.initAutoComplete(addressRef.current, handleAddressSelect);
                } catch (error) {
                    console.error('Error loading Google Maps API:', error);
                }
            }

            initGooglePlaces();
            async function getUserProfile(userId) {
                getLoginUser().then((response) => {
                    if (response) {
                        if (addressRef.current !== null) {
                            addressRef.current.value = response.address;
                            setAddress(response.address);
                        }
                        setAddress2(response.address2 !== null ? response.address2 : '');
                        setCity(response.city);
                        setState(response.state);
                        setZip(response.zip);
                        setFirstName(response.firstName);
                        setLastName(response.lastName);
                        setCompany(response.company);
                        setPhone(formValidator.formatPhoneNumber(response.phone));
                        setLicense(response.license !== null ? response.license : '');
                        setEmailProfile(response.email);
                        setPassword(response.password);
                        setPayPalPaymentType(response.payPalType);
                        setUsePayPal(response.usePayPal);
                        setPayPalInfo(response.payPalType === 'phone' ? formValidator.formatPhoneNumber(response.payPalTypeValue) : response.payPalTypeValue);
                        setConfirmPassword(response.password);
                    }
                }).catch(function (error) {
                    console.log('tags error = ', error);
                })
            }

            getUserProfile(userId);
        } else {
            handleGoToLogin();
        }
    }, []);

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="" onClick={(e) => { navigate(`/${feedType}`) }}>Home</a>
                                <span className="separator">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>Profile</span>
                            </li>
                        </ol>
                    </nav>
                    <div className="form-container" id="proprofile">
                        <form action="">
                            <section>
                                <div className="button-group" style={{ 'float': 'right' }}>
                                    <button
                                        type="button"
                                        className="button-m button-primary"
                                        onClick={() => { setChangePasswordModal(true) }}
                                    >
                                        <span className="text">Change Password</span>
                                    </button>
                                </div>
                            </section>
                            <section className="form-details fz-block-sm">
                                <h2 className="h4">Profile Details</h2>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasCompanyName ? 'error' : ''}`}>
                                        <label htmlFor="company">Company Name</label>
                                        <input
                                            type="text"
                                            id="company"
                                            value={company}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'company'); }}
                                            onFocus={(e) => { setHasCompanyName(true); }}
                                            onChange={(e) => { setCompany(e.target.value); }}
                                        />
                                        {!hasCompanyName ?
                                            <p className="validation-message">Enter company name.</p>
                                            : ''
                                        }
                                    </div>
                                    {parseInt(userType) !== 500001 && (
                                        <div className="form-field text-input">
                                            <label htmlFor="license">License Number</label>
                                            <input
                                                id='license'
                                                name='license'
                                                type='text'
                                                value={license}
                                                onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'license'); }}
                                                onChange={(e) => { setLicense(e.target.value); }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasFirstName ? 'error' : ''}`}>
                                        <label htmlFor="firstName">First Name</label>
                                        <input
                                            id='firstName'
                                            name='firstName'
                                            type='text'
                                            value={firstName}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'firstName'); }}
                                            onFocus={(e) => { setHasFirstName(true); }}
                                            onChange={(e) => { setFirstName(e.target.value); }}
                                        />
                                        {!hasFirstName ?
                                            <p className="validation-message">Enter first name.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className={`form-field text-input ${!hasFirstName ? 'error' : ''}`}>
                                        <label htmlFor="lastName">Last Name</label>
                                        <input
                                            id='lastName'
                                            name='lastName'
                                            type='text'
                                            value={lastName}
                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'lastName'); }}
                                            onFocus={(e) => { setHasLastName(true); }}
                                            onChange={(e) => { setLastName(e.target.value); }}
                                        />
                                        {!lastName ?
                                            <p className="validation-message">Enter last name.</p>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="form-row col-2">
                                    <div className={`form-field text-input ${!hasEmail ? 'error' : ''}`}>
                                        <label htmlFor="email">Email</label>
                                        <input
                                            id='email'
                                            type='email'
                                            autoComplete='email'
                                            value={emailProfile}
                                            onFocus={(e) => { setHasEmail(true); }}
                                            onChange={(e) => { setEmailProfile(e.target.value); }}
                                        />
                                        {!hasEmail ?
                                            <p className="validation-message">Enter valid email.</p>
                                            : ''
                                        }
                                    </div>
                                    <div className={`form-field text-input ${!hasPhoneNumber ? 'error' : ''}`}>
                                        <label htmlFor="phone">Phone number (10 digits only)</label>
                                        <input
                                            id='phone'
                                            name='phone'
                                            type='tel'
                                            value={phone}
                                            onBlur={(e) => { formValidator.formatPhoneNumber(e.target.value); }}
                                            onChange={(e) => { setPhone(e.target.value); }}
                                            onFocus={(e) => { setHasPhoneNumber(true); }}
                                        />
                                        {!hasPhoneNumber ?
                                            <p className="validation-message">Enter a 10 digits phone number.</p>
                                            : ''
                                        }
                                    </div>
                                </div>
                            </section>
                            {/* <section className="form-details"> */}

                            <MailingAddressForm
                                setAddress={setAddress}
                                addressRef={addressRef}
                                hasAddress={hasAddress}
                                setHasAddress={setAddress}
                                setAddress2={setAddress2}
                                address2={address2}
                                city={city}
                                setCity={setCity}
                                hasCity={hasCity}
                                setHasCity={setHasCity}
                                state={state}
                                setState={setState}
                                hasState={hasState}
                                setHasState={setHasState}
                                zip={zip}
                                setHasZip={setHasZip}
                                hasZip={hasZip}
                                setZip={setZip}
                                formValidator={formValidator}
                                showHeader={true}
                                isNewPackage={false}
                            />
                            {/* </section> */}
                            {parseInt(userType) === 500001 && (
                                <section className='form-details fz-block'>
                                    <h2 className="h4">Earn commisions</h2>
                                    <p>By building your Fazzad network of Pros and Vendors, you can earn commisions when they contact your clients. Enter your PayPal information to begin earning commissions.</p>
                                    <div className="form-checkbox fz-block">
                                        <div className="form-field checkbox">
                                            <input
                                                type="checkbox"
                                                id="dontUsePayPal"
                                                name="dontUsePayPal"
                                                value="1"
                                                checked={!usePayPal}
                                                onChange={(e) => handleUsePayPal(e)}
                                            />
                                            <label htmlFor="dontUsePayPal">I'm not interested in earning commissions.</label>
                                        </div>
                                    </div>
                                    {usePayPal === 1 && (
                                        <div className="form-row col-2">
                                            <div className="form-radio">
                                                <div className="form-field radio">
                                                    <div className='fz-block-sm'>
                                                        <h5 className="">PayPal credentials</h5>
                                                    </div>
                                                    <input
                                                        type="radio"
                                                        name="payPalType"
                                                        id="payPalPhone" // Unique id
                                                        value="phone" // Value representing this option
                                                        checked={payPalPaymentType === 'phone'} // Check against value
                                                        onChange={handlePayPalTypeChange} // Call the function
                                                    />
                                                    <label htmlFor="payPalPhone">Phone number</label>
                                                    <input
                                                        type="radio"
                                                        name="payPalType"
                                                        id="payPalEmail" // Unique id
                                                        value="email" // Value representing this option
                                                        checked={payPalPaymentType === 'email'} // Check against value
                                                        onChange={handlePayPalTypeChange} // Call the function
                                                    />
                                                    <label htmlFor="payPalEmail">Email address</label>
                                                </div>
                                                <div className={`form-field text-input ${!hasPayPalInfo ? 'error' : ''}`}>
                                                    <label htmlFor="payPalInfo">Enter PayPal {payPalPaymentType}</label>
                                                    <input
                                                        type="text"
                                                        id="payPalInfo"
                                                        value={payPalInfo}
                                                        maxLength={payPalPaymentType === 'phone' ? 10 : 100}
                                                        onChange={(e) => setPayPalInfo(e.target.value)}
                                                        onFocus={(e) => setHasPayPalInfo(true)}
                                                    />
                                                    {!hasPayPalInfo && (
                                                        <p className="validation-message">Enter {payPalPaymentType}.</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </section>
                            )}

                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-primary"
                                    onClick={() => { updateProfile() }}
                                >
                                    <span className="text">Update profile</span>
                                </button>
                            </div>
                            <div className="fz-block"></div>
                        </form>
                    </div>
                    {showConfirmation && (
                        <div className="fz-block">
                            <AlertMessage
                                messageHeader={"Profile updated successfully."}
                                messageBody={""}
                                startDate={null}
                                setShowWarningContainerMessage={null}
                                messageType={"success"}
                                messageBodyContinuation={null}
                                showButton={false}
                            />
                        </div>
                    )}

                    {changePasswordModal &&
                        <Modal onClose={() => setChangePasswordModal(false)} modalHeader="Change Password">
                            <ChangePassword onSuccess={() => setChangePasswordModal(false)} />
                        </Modal>
                    }
                </div>
            </main>
        </div>
    )
}