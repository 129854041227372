import React, { useState, useEffect, useRef } from "react";
import Header from '../libs/Header'
import Axios from 'axios';
import getSvg from "../../utils/getSvg";
import painter from '../../images/graphic-contractor-painter.png';
import {useParams} from 'react-router-dom';
import {copyToClipBoard} from "../../utils/copyToClipBoard";
import TopPageBanner from '../libs/TopPageBanner';
import AlertMessage from '../libs/AlertMessage';
import DisplayUserInfo from '../libs/DisplayUserInfo';

function SingleLeadHomeOwner() {
	const videoRef = useRef(null);
	const [videoSource, setVideoSource] = useState('');
	const [videoThumbNailSource, setVideoThumbNailSource] = useState('');
    const [isHomeOwnerVideo, setIsHomeOwnerVideo] = useState(false);
	const [isFullScreen, setIsFullScreen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [singleLead, setSingleLead] = useState([]);
	const userId = sessionStorage.getItem('userId');
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const { leadId, leadtype } = useParams();
	sessionStorage.setItem('isLoggedIn',  'false');
	sessionStorage.setItem('userType', 500003)
    const [showMore, setShowMore] = useState({});
	const queryParams = new URLSearchParams(window.location.search);
	const newlead = queryParams.get('lt');
	const videoPrefix = 'scope-of-work-videos';
	
    useEffect(() => {
		getLead(leadId);
		document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

	useEffect(() => {
        const videoElement = videoRef.current;
        // Function to handle the full-screen change
        const handleFullScreenChange = () => {
            setIsFullScreen(document.fullscreenElement === videoElement);
        };

        // Adding event listener for full-screen change
        document.addEventListener('fullscreenchange', handleFullScreenChange);

        // Cleanup
        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    const deleteLeadOrReply = () => {
		let urlToCall= 'deleteHomeOwnerLead';
		if(!isHomeOwnerVideo){
			urlToCall= 'deleteProReplyByHomeOwner';
		}
		Axios.post(`${process.env.REACT_APP_LOCALHOST}/${urlToCall}`, {leadId: leadId, userId: singleLead.userId}).then((response) => {
			setShowDeleteModal(false);
			getLead(leadId);
		}).catch(function (error) {
			console.log('tags error = ',error);
		})
    }

	const handleCopyToClipboard = (textToCopy) => {
		copyToClipBoard(textToCopy);
    };

	const getVideoDescription = (videoDesc, index) => {
		if(!showMore[index]){
			if(videoDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH){
				return videoDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
			} else {
				return videoDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
			}
		} else {
			return videoDesc;
		}
	}
      
	const handleReadMore = (index) => {
		setShowMore({...showMore, [index]: !showMore[index]});
	}

	const setVideoInfo = (pck) => {
		if (parseInt(leadtype) === 0) {
			videoPrefix = 'pro-videos-library';
			setVideoSource(`${bucketPrefix}/${pck.packageName}/pro-videos-library/${pck.videoFileName}`);
			setVideoThumbNailSource(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${videoPrefix}/${pck.videoFileName.replace('mp4', 'jpg')}`);
			
		} else {
			setVideoSource(`${bucketPrefix}/${pck.videoFileName}`);
			setVideoThumbNailSource(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.videoFileName.replace('mp4', 'jpg')}`);
		}
	}

	const getLead = (leadId) => {
		Axios.post(`${process.env.REACT_APP_LOCALHOST}/getsinglefreelead`, {leadId: leadId, isPropertyOwner: true, newLead: newlead}).then((response) => {
			const tempData = response.data;
			tempData.map((pck) => {
				console.log(pck)
				pck.videoSource = `${s3BucketVideoPrefix}/${pck.videoFileName}`;
			});
            setSingleLead(tempData);
			setVideoInfo(tempData[0]);
		}).catch(function (error) {
			console.log('error getting free videos = ',error);
		})
	}

	const likeVideo = async (leadId) => {
		Axios.post(`${process.env.REACT_APP_LOCALHOST}/likeProFreeLeadReply`, {leadId: leadId, userId: userId}).then((response) => {
				return;
		}).catch(function (error) {
				console.log('error getting free videos = ',error);
		})
	}

	const likeVideoFunc = async (pck) => {
        await likeVideo(pck);
    }

	let bucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
	
	return (
		<div id="fazzad-site-container">
			<Header
				mode={'dark'}
			/>         
			<main id="fazzad-main">     
				<div id="po-container" className="po-freelead">	
					{singleLead.length > 0 && singleLead[0].isActive && (                
						<> 
						<div className="file-group">
							<div className="file-container fz-drop rounded">
								<img
									className="file-preview rounded"
									src={`${s3BucketVideoPrefix}/${singleLead[0].packageName}/free-lead-image/${singleLead[0].leadImage}`}
									alt="Project Picture"
								/>
							</div>
							<h2 className="h4">{singleLead[0].projectName}</h2>
							<strong>Scope of work</strong>
							<p  className='preserve-html'>{getVideoDescription(singleLead[0].projectDescription, singleLead[0].id)}
								{singleLead[0].projectDescription.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
									<>
									<br />
									<a href="/" onClick={(e) => {e.preventDefault(); handleReadMore(singleLead[0].id); e.target.blur();}}>
										{showMore[singleLead[0].id] ? 'Read less' : 'Read more'}
									</a>
									</>
								)}
							</p>
							<div className="button-group">
								<button title="Delete" className="button button-destructive " onClick={() => {setIsHomeOwnerVideo(true); setShowDeleteModal(true)}}>
									{getSvg('delete')}
									<span className="text">Delete your project</span>
								</button>
							</div>
						</div>
						<TopPageBanner
							userId={userId}
							imageBanner={painter}
							imageBannerText={"Ready to take it to the next level?"}
							h2Text={"Ready to take it to the next level?"}
							bodyText={"From dream home renovations to small additions, we can help with any project, big or small."}
							buttonText={"Learn more"}
							urlToGo={"contacts"}
							leadType={1}
						/>
						{newlead === null && singleLead.map((video, index) => {    
							return(
								!video.deletedByHomeOwner && video.userId !== null && (
									<div id="po-feed-container" className="reel-view" key={`video_pro_${index}`}>
										<div className="po-feed-item" key={`resp_${video.id}`}>
											<div className="video" key={`video_${index}`}>
												<video
													className={`rounded ${isFullScreen ? 'video-mode-contain' : ''}`}
													src={videoSource}
													poster={videoThumbNailSource}
													controls
													ref={videoRef}
												/>
											</div>
											<div className="card">
												<div className="content">
													<div className="info">
														<DisplayUserInfo
															pck={singleLead[0]}
															handleCopyToClipboard={handleCopyToClipboard}
															linkToProfile={true}
															packageName={singleLead[0].packageName}
															isHomeOwner={true}
															leadtype={leadtype}
														/>
													</div>
													<div className="actions">
														{+leadtype !== 1 && (
															<a
																href="/"
																onClick={(e) => {
																	e.preventDefault();
																	likeVideoFunc(singleLead[0], index);
																	e.target.blur();
																}}
																title={`${singleLead[0].homeOwnerLikedVideo === 1 ? 'Dislike' : 'Like'} this response`}
																className={`button button-s button-icon like ${
																	singleLead[0].homeOwnerLikedVideo === 1 ? 'thumb-like' : ''
																}`}
															>
																{getSvg('like')}
															</a>
														)}
														<a
															href="/"
															onClick={(e) => {
																e.preventDefault();
																setShowDeleteModal(true);
															}}
															className="button button-s button-destructive button-icon delete"
															title="Delete this response"
														>
															{getSvg('delete')}
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							)
						})}
						</>
					)}                    
					{singleLead.length === 0 && (
						
						<AlertMessage
							messageHeader={"You deleted this project."}
							messageBody={""}
							startDate={null}
							setShowWarningContainerMessage={''}
							messageType={"informational"}
							showButton={false}
						/>
					)}
				</div>
				{showDeleteModal && (
					<div id="modal-5" className="modal-container">
						<div className="modal">
							<div className="modal__header">
								<h2 className="modal-title h4">{process.env.REACT_APP_DELETE_PACKAGE_MESSAGE}</h2>
							</div>
							<div className="modal__content">
								<p>Once a file is deleted it cannot be recovered. You will need to upload it again.</p>
							</div>
							<div className="modal__action">
								<div className="button-group">
									<button className="button button-m button-secondary" type="button"  onClick={() => { setShowDeleteModal(false);}}>No, Cancel</button>
									<button className="button button-m button-destructive" type="button" onClick={() => deleteLeadOrReply()}>Yes, Delete</button>   
								</div>
							</div>
						</div>
					</div>
                )}       
			</main>
		</div>
	);
}

export default SingleLeadHomeOwner;