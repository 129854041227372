import {ReactComponent as EditIcon} from "../../../../images/icon-edit-02.svg";
import {ReactComponent as TrashIcon} from "../../../../images/icon-trash-03.svg";
import {ReactComponent as PlusIcon} from "../../../../images/icon-plus.svg";
import React, {useEffect, useMemo, useState} from "react";
import {useDeletePlanAndPicture, useFetchPlanAndPicturesByPdfIds} from "../hooks/usePlanAndPicture";
import {LoadingSpinner} from "../page-select-modal/loading-spinner/loading-spinner";

/**
 * List for editing and editing plan and picture objects based on pdfs
 * @param items
 * @param onEdit
 * @returns {JSX.Element}
 * @constructor
 */
const PdfList = ({items, onEdit}) => {
  const pdfIds = items.map((item) => item.Key);
  const [pdfsWithPlans, setpdfsWithPlans] = useState(new Set())
  const [loadingPlans, setLoadingPlans] = useState(false)
  const truncateText = (text, maxLength) => {
    const fileName = text.replace(/.*pdf-files\//, '');
    if (fileName.length > maxLength) {
      return fileName.substring(0, maxLength) + "...";
    }
    return fileName;
  };

  const {data, error, loading, fetchPlanAndPictures} = useFetchPlanAndPicturesByPdfIds();
  const {loading: deleteLoading, error: deleteError, deletePlanAndPicture} = useDeletePlanAndPicture();
  const onDelete = async (item) => {
    const key = item.Key.replace(/\//g, '__').replace(/\.pdf/g, '')
    const id = data?.[key]?.id;
    await deletePlanAndPicture(id);
    fetchPlanAndPictures(pdfIds.map(item => item.replace(/\//g, '__').replace('.pdf', '')));
  }


  useEffect(() => {
    if (pdfIds?.length && !data) {
      setLoadingPlans(true);
      fetchPlanAndPictures(pdfIds.map(item => item.replace(/\//g, '__').replace('.pdf', ''))).then(() => {
        setLoadingPlans(false)
      })
    }
  }, [pdfIds, fetchPlanAndPictures, data]);



  return (
      <div className="plan-and-picture">
        <h2>Plan & Picture</h2>
        {loadingPlans && <LoadingSpinner />}
        {items?.length > 0 && <table>
          <thead>
          <tr>
            <th className="file-name-header">File name</th>
            <th className="modify-header">Modify</th>
          </tr>
          </thead>
          <tbody>
          {items.map((item, index) => (
              <tr key={index} className="edit-row">
                <td title={item.Key}>{truncateText(item.Key, 55)}</td>
                <>
                {data?.[item.Key.replace(/\//g, '__').replace(/\.pdf/g, '')] && <td>
                  <button onClick={() => onEdit({item, data})} className="button">
                    <EditIcon/>
                  </button>
                  <button onClick={() => onDelete(item)} className="button">
                    <TrashIcon/>
                  </button>
                </td>}
                {!data?.[item.Key.replace(/\//g, '__').replace(/\.pdf/g, '')] && <td>
                  <button onClick={() => onEdit({item})} className="button">
                    <PlusIcon />
                  </button>
                </td>}
                </>
              </tr>
          ))}
          </tbody>
        </table>}
      </div>
  );
};

export default PdfList;
