import React from 'react';
export default function PackageFilter({numberOfPackages, pFirst, pSecond, sortByText, filterValue, updateFeed}) {
  return (
    <div className="homefeed--details">
        <div className="homefeed--details__quantity fz-block-sm">
            <p>{pFirst} <span className="count">{numberOfPackages}</span> {pSecond}</p>
        </div>
    </div>
  )
};
