import React, {useEffect} from 'react';
import painter from '../../images/freelead-banner.png';
import Header from '../libs/Header';
import getSvg from '../../utils/getSvg';
export default function LeadUnderway() {
    const learnMore = () => {
        window.open('https://www.fazzad.com/', '_blank', 'noopener, noreferrer');
    }

    useEffect(() => {
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    return(
        <div id="fazzad-site-container">
            <Header
                mode="dark"
            />
            <main id="fazzad-main">
                <div id="app-container">  
                    <div className='container-col__2'>
                        <div className='project-learn-more'>
                            <div className='card'>
                                <h1>Project underway!</h1>
                                <p>We are creating your project listing. Once completed, we'll send you an email link, where you can see Pros interested in your project.
                                </p>
                                <p className="block">Get more with Premium!</p>   
                                <div className="button-group">
                                    <button type="submit"  value="Learn more" className="button-m button-primary" onClick={(e) => {learnMore()}}>Learn more</button> 
                                </div> 
                            </div>
                        </div>
                        <div className='project-ad rounded'>
                            <div className='card fz-drop'>
                                <div className="project-ad__title">
                                    <img className='project-ad-image' src={painter} alt="Fazzad Pros &amp; Vendors" />
                                    <h2 className="h4">Get more with Fazzad Premium</h2>
                                </div>                            
                                <div className="project-comparison">
                                    <div className="project-comparison-title">
                                        <strong>Freemium</strong>
                                        <strong>Premium</strong>
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('circle-checkmark')}
                                        <span>Scope of work Picture</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Scope of work Video</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Technical Photography: Still, 360, Drone (if applicable)</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Reflected Ceiling Plan</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Roof Plan</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Exterior Elevations (if applicable)</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>Interior Elevations (if applicable)</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>AutoCAD DWG Files</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                    <div className="project-comparison-row">
                                        {getSvg('x')}
                                        <span>PDF Files</span>
                                        {getSvg('circle-checkmark')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </main>
        </div>        
  )
}
