import React, { useState, useEffect, useRef } from "react";
import {useParams, useNavigate} from 'react-router-dom';
import {copyToClipBoard} from "../../utils/copyToClipBoard";
import DisplayUserInfo from "../libs/DisplayUserInfo";
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import getSvg from '../../utils/getSvg';
import CustomHeader from '../libs/CustomHeader'
function Portfolio() {
    const {userId, leadtype} = useParams();
    const [pageType, setPageType] = useState('Portfolio');
    const [portfolios, setPortfolios] = useState([]);
    const [currentTab, setCurrentTab] = useState('');
    const [userData, setUserData] = useState([]);
    const [showNoData, setShowNoData] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const packageName = queryParams.get('packageName');
    const isHomeOwner = queryParams.get('ho');
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const thumbNailPrefix = 'fazzad-thumbnail-00001';
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    useEffect(() => {
        getUserProfile(userId);
        if(sessionStorage.getItem('currentTab') !== null){
            if(currentTab.toLowerCase() !== sessionStorage.getItem('currentTab').toLowerCase()){
                setCurrentTab(sessionStorage.getItem('currentTab'));
            }
        }else{
            setCurrentTab('portfolio');
        }
        document.body.classList.add('dark-mode');
        return () => {
            document.body.classList.remove('dark-mode');
        };
    }, []);

    const handleCopyToClipboard = (textToCopy) => {
        copyToClipBoard(textToCopy);
    };

    const getProsVideos = (videoType, userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, {videoType: videoType, userId: userId}).then((response) => {
            setShowLoader(false);
            let filteredData = [];
            if(videoType === 'portfolio'){
                setCurrentTab('portfolio');
                filteredData = response.data.filter((item) => item.videoType === 'portfolio');
            } else {
                setCurrentTab('testimonial');
                filteredData = response.data.filter((item) => item.videoType === 'testimonial');
            }
              
            setPortfolios(filteredData);
            if(filteredData.length === 0){
                setShowNoData(true);
            }else{
                setShowNoData(false);
            }
        }).catch(function (error) {
            setShowLoader(false);
            console.log('error getting pros videos = ',error);
        })
    }

    const getUserProfile = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getUserProfile`, {userId: userId}).then((response) => {
            if(response.data.length > 0){
                setUserData(response.data);
                let type = sessionStorage.getItem('currentTab') !== null ? sessionStorage.getItem('currentTab') : 'portfolio';
                if(type === 'portfolio'){
                    type = 'portfolio';
                }else if(type === 'testimonial'){  
                    type = 'testimonial';
                }
                getProsVideos(type, userId, 500002);
            }else {
                return;
            }
        }).catch(function (error) {
            console.log('download package error = ',error);
            return;
        })
    }

    return (
        <div id="fazzad-site-container">
            <CustomHeader
                packageName={packageName}
                leadtype={'none'}
            />
            <main id="fazzad-main">
                <div id="po-container">
                    <div className="po-feed-tabs back">
                        <ul>
                            <li>
                                <a className="button button-m" onClick={(e) => window.history.back()}>
                                    {getSvg('back')}
                                    <span className="text">Back</span>
                                  
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="pro-bio">
                        {userData.map((user, index) => {
                            return(
                                <div className="content" key={`main_${index}`}>
                                    <DisplayUserInfo
                                        pck={user}
                                        handleCopyToClipboard={handleCopyToClipboard}
                                        userId={user.userId}
                                        packageName={packageName}
                                        isHomeOwner={isHomeOwner}
                                        leadtype={leadtype}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="pro-feed-tabs">
                        <ul>
                            <li className={`${sessionStorage.getItem('currentTab') === 'portfolio' ? '' : 'current'}`}>
                                <a
                                    href="/"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        getProsVideos('portfolio', userId);
                                        setPageType('Portfolio');
                                        setCurrentTab('portfolio');
                                        sessionStorage.setItem('currentTab', 'portfolio');}
                                    }
                                    className="button button-m"
                                    id="pro-view-portfolio"
                                    title="View Portfolio"
                                >
                                    {getSvg('portfolio')}
                                    <span className="text">Portfolio</span>
                                </a>
                            </li>
                            <li className={`${sessionStorage.getItem('currentTab') === 'testimonial' ? '' : 'current'}`}>
                                <a href=""
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentTab('testimonial'); 
                                        sessionStorage.setItem('currentTab', 'testimonial');
                                        getProsVideos('testimonial', userId, 500002);
                                        setPageType('Testimonial');
                                    }}
                                    className="button button-m"
                                    id="pro-view-testimonial"
                                    title="View Testimonial"
                                >
                                    {getSvg('testimonial')}
                                    <span className="text">Testimonials</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {showLoader ?
                        <span className="loader loader--l"></span>
                    :
                        showNoData ?
                            <section>
                                <AlertMessage
                                    messageHeader={`No ${pageType} available.`}
                                    messageBody={"Check back soon."}
                                    startDate={null}
                                    setShowWarningContainerMessage={null}
                                    messageType={"informational"}
                                    showButton={false}
                                />
                            </section>
                        :
                            <div id="po-feed-container" className="gallery-view">
                                {portfolios.map((pck, index) => {
                                    return(
                                        <div className="po-feed-item" key={`main_${index}`}>
                                            <div className="video-frame" key={`frame_${index}`}>
                                                <a href="/" onClick={(e) => {e.preventDefault(); navigate(`/videoplayer/${pck.id}/${pck.referenceNumber}?type=${pck.videoType}&userId=${userId}&ho=${isHomeOwner}`)}}>
                                                    <img
                                                        src={`${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${pck.referenceNumber}/${pck.videoFileName.replace('mp4', 'jpg')}`}
                                                        key={`img_${index}`}
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                    }
                </div>
            </main>
        </div>
    );
}

export default Portfolio;
