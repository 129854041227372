import React, { useState, useEffect, StrictMode } from "react";
import {useParams} from 'react-router-dom';
import Axios from 'axios';
import likeVideo from "../../utils/likeVideo";
import Header from './Header'
import uuid from 'react-uuid';
import VideoComponent from "./VideoComponent";
import getSvg from "../../utils/getSvg";

function VideoPlayer() {
    const {id, packageName} = useParams();
    const [hasVideo, setHasVideo] = useState(false);
    const [packageInfo, setPackageInfo] = useState([]);
    const [packageUserInfo, setPackageUserInfo] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(true);
    const [videoType, setVideoType] = useState('');
    const [userId, setUserId] = useState(0);
    const [userType, setUserType] = useState(500003);
    const queryParams = new URLSearchParams(window.location.search);
    const s3BucketPortolioTestimonialPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const thumbNailPrefix = 'fazzad-thumbnail-00001';
    let videoUserId = 999999;

    useEffect(() => {
        setUserId(queryParams.get('userId'));
        const mode = queryParams.get('mode');
        if(typeof mode !== 'undefined' && mode === 'ho'){
            videoUserId = 999999;
        } else if(typeof mode !== 'undefined' && mode === 'video'){
            videoUserId = parseInt(queryParams.get('userId'));
        } else {
            videoUserId = parseInt(queryParams.get('userId'));
            setUserType(parseInt(sessionStorage.getItem('userType')));
        }
        setVideoType(queryParams.get('type'));
        setShowLoading(true);
        if(queryParams.get('type') === 'package'){
            getPackage(packageName, id);
        } else {
            if(queryParams.get('mode') !== 'video'){
                getProsVideos(queryParams.get('type'), videoUserId, 500002);
            } else {
                setVideoType('package');
                getSingleVideo(packageName, videoUserId);
            }
        }
        if(queryParams.get('mode') === 'ho' || queryParams.get('ho') || queryParams.get('mode') === 'video'){
            document.body.classList.add('dark-mode');
            return () => {
                document.body.classList.remove('dark-mode');
            };
        }
    }, []);

    const getPackage = async (packageName, id) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getPackagesResponsesHomeOwner`, {packageName: packageName, id: id, videoPlayer: 1}).then((response) => {
            setShowLoading(false);
            setPackageInfo(response.data[0]);
            setHasVideo(true);
        }).catch(function (error) {
            setShowLoading(false);
            console.log('error on package details gttting package = ', error);
        })
    }

    const getSingleVideo = async (packageName, userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getProReplyByPackageUser`, {packageName: packageName, userId: userId}).then((response) => {
            setShowLoading(false);
            setPackageInfo(response.data[0]);
            setPackageUserInfo(response.data[0]);
            setHasVideo(true);
            setShowUserInfo(false);
        }).catch(function (error) {
            setShowLoading(false);
            console.log('error on package details gttting package = ', error);
        })
    }

    const getProsVideos = (videoType, userId, userType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, {videoType: videoType, userId: userId, userType: userType}).then((response) => {
            const tempData = response.data;
            tempData.map((item) => {
                item.videoSource = `${s3BucketPortolioTestimonialPrefix}/${item.referenceNumber}/${item.videoFileName}`;
                item.videThumbnail = `${s3ThumbnailBucketPrefix}/${thumbNailPrefix}-${item.referenceNumber}/${item.videoFileName.replace('mp4', 'jpg')}`;
            });
            const currentVideo = tempData.filter((item) => item.referenceNumber === packageName);
            setPackageInfo(currentVideo[0]);
            setShowLoading(false);
            if(response.data.length > 0){
                setHasVideo(true);
            }else{
                setHasVideo(false);
            }
        }).catch(function (error) {
            setShowLoading(false);
            console.log('error getting pros videos = ',error);
        })
    }

    const saveVideoStats = (videoFileName) => {
        const data = {
            userId: videoUserId,
            userType: userType,
            packageName: packageName,
            videoFileName: videoFileName,
            currentPage: 'property owner video gallery'
        }
        setTimeout(() => {
            Axios.post(`${process.env.REACT_APP_LOCALHOST}/addVideosStats`, data).then((response) => {
                return;
            }).catch(function (error) {
                console.log(error);
            })
        }, 5000);
    }

    const handlePlayed = (pck) => {
        if(!pck.hasVideoBeenWatched) {
            setTimeout(() => {
                Axios.post(`${process.env.REACT_APP_LOCALHOST}/markVideoAsWatched`, {userId: userId, id: id}).then((response) => {
                    return;
                }).catch(function (error) {
                   console.log(error);
                })
            }, 3000);
        } else {
            console.log('already watched');
        }
    };

    const deleteVideo = () => {
        setShowDeleteModal(false);
        let endPoint = 'hidePackageFromHomeOwner';
        if(userType === 500002){
            endPoint = 'deleteVideosSubmissions';
        }
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/${endPoint}`, {id: id, userType: userType}).then((response) => {
            window.history.back();
        }).catch(function (error) {
            console.log(error);
        })
    }

    const likeVideoFunc = async (pck) => {
        await likeVideo(pck);
        getPackage(packageName, id);
    }

    return (
        <div id="fazzad-site-container">
            <Header
                mode={"homeOwner"}
            />
            <main id="fazzad-main">
                <div id="po-container">
                    <div className="po-feed-tabs back">
                        <ul>
                            <li>
                                <a className="button button-m" onClick={(e) => window.history.back()}>
                                    {getSvg('chevron-left')}
                                    <span className="text">Back</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id="po-feed-container" className="individual-view"> 
                        {showLoading || !hasVideo?
                            <div className="homefeed-item__image">
                                <span className="loader loader--l"></span> 
                            </div>
                        : ''
                        } 
                        {hasVideo && (
                            <div className="po-feed-item">
                                <div className="video">
                                    {videoType === 'package' ?
                                        <VideoComponent
                                            pck={packageUserInfo}
                                            videoKey={packageUserInfo.id}
                                            videoType={'package'}
                                            handleCopyToClipboard={null}
                                            setShowDeleteModal={setShowDeleteModal}
                                            hasActions={true}
                                            likeVideoFunc={likeVideoFunc}
                                            setSinglePackage={null}
                                            showDeleteModal={showDeleteModal}
                                            saveVideoStats={null}
                                            index={packageUserInfo.id}
                                            setSingleVideo={null}
                                            handlePlayed={handlePlayed}
                                            markVideoAsWatched={true}
                                            packageInfo={packageInfo}
                                            packageUserInfo={packageUserInfo}
                                            showUserInfo={showUserInfo}
                                        />
                                    : 
                                        <VideoComponent
                                            pck={packageInfo}
                                            videoKey={uuid()}
                                            videoType={'standalone'}
                                            handleCopyToClipboard={null}
                                            setShowDeleteModal={setShowDeleteModal}
                                            hasActions={false}
                                            likeVideoFunc={null}
                                            setSinglePackage={null}
                                            showDeleteModal={showDeleteModal}
                                            saveVideoStats={null}
                                            index={packageUserInfo.id}
                                            setSingleVideo={null}
                                            handlePlayed={null}
                                            packageInfo={[]}
                                            packageUserInfo={[]}
                                            showInfo={false}
                                            showUserInfo={showUserInfo}
                                        />
                                    }
                                </div>
                            </div>  
                            )  
                        } 
                    </div>
                    { showDeleteModal ?
                        <div id="modal-4" className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                </div>
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button className="button button-m button-primary" type="button" onClick={() => {deleteVideo()}}>Ok</button>   
                                        <button className="button button-m button-secondary" type="button" onClick={() => {setShowDeleteModal(false)}}>Cancel</button>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    : ''
                    }  
                </div>
            </main>
        </div>
    );
}

export default VideoPlayer;