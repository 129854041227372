import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { S3 } from '@aws-sdk/client-s3';
import { useNavigate, useParams } from 'react-router-dom';
import { Upload } from '@aws-sdk/lib-storage';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header'
import Tabs from '../libs/Tabs';
import PageTitle from "../libs/PageTitle";
import uuid from 'react-uuid';
import VideoComponent from '../libs/VideoComponent';
import * as formValidator from "../../utils/formValidation";
import { uploadWithSignedUrl } from "../../utils/awsHelpers";
window.Buffer = window.Buffer || require("buffer").Buffer;
export default function ProMedia() {
    const videoRef = useRef(null);
    const { type } = useParams();
    const proTestimonialProfileBucketName = 'fazzad-pros-portfolio-testimonial-videos-source';
    const [fileToBeUploaded, setFileToBeUploaded] = useState('');
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(5);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [errorUploadingVideo, setErrorUploadingVideo] = useState(false);
    const [videoName, setVideoName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showVideos, setShowVideos] = useState(true);
    const [singleVideo, setSingleVideo] = useState([]);
    const [showProcessingVideo, setShowProcessingVideo] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showNoVideos, setShowNoVideos] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [showFormErrorMessage, setShowFormErrorMessage] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showInfo, setShowInfo] = useState(true);
    const [videosResponses, setVideosResponses] = useState([]);
    const [hasVideoName, setHasVideoName] = useState(true);
    const [hasProjectDescription, setHasProjectDescription] = useState(true);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const processingMessage = process.env.REACT_APP_PROCESSING_MESSAGE;
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const handleGoToLogin = () => navigate('/login');
    const navigate = useNavigate();
    let tempVideoCounter = 0;
    sessionStorage.setItem('isPropertyOwner', 'false');
    const s3BucketVideoPrefix = process.env.REACT_APP_S3_BUCKET_PROS_VIDEOS_PREFIX;
    const s3ThumbnailBucketPrefix = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const messageWaiting = process.env.REACT_APP_PROCESSING_MESSAGE_WAIT;
    const userId = sessionStorage.getItem('userId');
    const uploadType = type;


    useEffect(() => {
        if (isLoggedIn !== 'true') {
            handleGoToLogin();
        } else {
            getProsVideos(userId, type);
        }
    }, []);

    const handleTabClick = (currentTab) => {
        getProsVideos(userId, currentTab);
    }

    const checkProjectDescription = (projectDescription) => {
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
        } else {
            setHasProjectDescription(true);
        }
    }

    const checkVideoName = (videoName) => {
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
        } else {
            setHasVideoName(true);
        }
    }

    const getProsVideos = (userId, type) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getMediaLibrary`, { userId: userId, videoType: type }).then((response) => {
            setVideosResponses(filterData(type, response.data));
            if (response.data.length === 0) {
                setShowNoVideos(true);
            } else {
                setShowNoVideos(false);
            }
        }).catch(function (error) {
            console.log('error getting pros videos = ', error);
        })
    }
    const filterData = (type, originalData) => {
        const filteredData = originalData.filter((item) => item.videoType === type);
        return filteredData;
    }
    const sendUploadError = (err) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendGenericErrorEmail`, { errorSubject: `Error Uploading ${type} video`, errorMessage: 'Check the cors issues' }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('error sending generic error email = ', error);
        })
    }

    const checkUploadForm = () => {
        let isValid = true;
        if (projectDescription.trim().length === 0) {
            setHasProjectDescription(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        if (videoName.trim().length === 0) {
            setHasVideoName(false);
            isValid = false;
        } else {
            setHasProjectDescription(true);
        }
        return isValid;
    }

    const saveVideoUploaded = (userId, uploadType, uploadFileName, videoName, projectDescription, referenceNumber) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProVideos`, { userId: userId, videoType: uploadType, uploadFileName: uploadFileName, projectName: videoName, projectDescription: projectDescription, referenceNumber: referenceNumber }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const changeHandlerUploads = (event) => {
        event.preventDefault();
        setShowErrorMessage(false);
        const chosenFile = event.target.files[0].name;
        setFileToBeUploaded(chosenFile);
        const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;

        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setIsValidVideo(true);
            setSelectedUploads(event.target.files);
        }
        videoRef.current.blur();
    }

    const handleUpload = async () => {
        setShowFormErrorMessage(false);
        const uuids = uuid();
        tempVideoCounter = 0;
        let selfVideoArray = [];
        try {
            if (videoName.trim().length > 0 && projectDescription.trim().length > 0) {
                if (selectedUploads.length > 0) {
                    if (checkUploadForm()) {
                        setFileProgressSelfVideo(0);
                        setShowErrorMessage(false);
                        setShowProcessingVideo(true);
                        setShowProcessing(false);
                        setErrorUploadingVideo(false);
                        selfVideoArray = [...selectedUploads];
                        let videoArray = [];
                        videoArray = selfVideoArray.splice(0, 1);
                        try {
                            await Promise.all(
                                videoArray.map(async (file) => {
                                    const fileName = cleanMediaUrl(file.name);
                                    const key = `${uuids}/${fileName}`;
                                    await uploadWithSignedUrl(file, proTestimonialProfileBucketName, key, (myTotal) => {
                                        setFileProgressSelfVideo(myTotal);
                                    });

                                    setShowProcessingVideo(false);
                                    setShowInfo(false);
                                    setShowProcessing(true);
                                    setFileProgressSelfVideo(0);
                                    saveVideoUploaded(userId, uploadType, fileName, videoName, projectDescription, uuids);
                                    setTimeout(() => {
                                        setShowInfo(true);
                                        setShowProcessing(false);
                                        setShowVideos(true)
                                        setShowUploadVideo(false);
                                        getProsVideos(userId, type);
                                        setVideoName('');
                                        setProjectDescription('');
                                        setSelectedUploads([]);
                                        setFileToBeUploaded('');
                                    }, process.env.REACT_APP_TIEMOUT);
                                })
                            );
                        } catch (e) {
                            setShowProcessingVideo(false);
                            setVideoName('');
                            setProjectDescription('');
                            setFileToBeUploaded('');
                            setSelectedUploads([]);
                            setErrorUploadingVideo(true);
                            sendUploadError(e);
                        }
                    }
                } else {
                    setShowErrorMessage(true);
                }
            } else {
                setShowFormErrorMessage(true);
            }
        } catch (e) {
            setShowInfo(true);
            setShowUploadVideo(false);
            setShowProcessing(false);
            console.error(`Uploading Videos file error: ${e}`);
        }
    };

    const deleteVideo = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteProUploadsVideos`, { singleVideo: singleVideo }).then((response) => {
            setShowDeleteModal(false);
            getProsVideos(userId, type);
        }).catch(function (error) {
            console.log('tags error = ', error);
        })
    }

    const handleAlertButtonClick = () => {
        setShowFormErrorMessage(false);
    };

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    {showVideos ?
                        <>
                            <Tabs
                                onTabClick={handleTabClick}
                                currentTab={type}
                            />
                            <PageTitle
                                headerText={`${type.charAt(0).toUpperCase()}${type.slice(1)}`}
                            />
                            <button type="button" className="fz-block-sm button-m button-primary" onClick={(e) => { setShowUploadVideo(true) }}>
                                {getSvg('upload')}
                                <span className="text">Upload {type} video</span>
                            </button>
                            <p>Upload your {type} videos here.</p>
                            {showNoVideos ?
                                <AlertMessage
                                    messageHeader={`You don't have any ${uploadType} videos uploaded.`}
                                    messageBody={""}
                                    startDate={null}
                                    onButtonClick={handleAlertButtonClick}
                                    messageType={"informational"}
                                    showButton={false}
                                />
                                : ''
                            }
                            <div id="po-container">
                                <div id="po-feed-container" className="reel-view">
                                    {videosResponses.map((pck, index) => {
                                        return (
                                            <div className="po-feed-item fz-drop rounded" key={`resp_${pck.id}`}>
                                                <VideoComponent
                                                    pck={pck}
                                                    videoKey={index}
                                                    videoType={'media'}
                                                    handleCopyToClipboard={null}
                                                    setShowDeleteModal={setShowDeleteModal}
                                                    hasActions={true}
                                                    likeVideoFunc={null}
                                                    setSinglePackage={null}
                                                    showDeleteModal={showDeleteModal}
                                                    saveVideoStats={null}
                                                    index={index}
                                                    setSingleVideo={setSingleVideo}
                                                    handlePlayed={null}
                                                    markVideoAsWatched={false}
                                                    pacakgeInfo={[]}
                                                    isPortfolioTestimonial={true}
                                                />
                                            </div>
                                        );
                                    })
                                    }
                                    {showDeleteModal ?
                                        <div id="modal-5" className="modal-container">
                                            <div className="modal">
                                                <div className="modal__header">
                                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                                </div>
                                                <div className="modal__action">
                                                    <div className="button-group">
                                                        <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                                        <button className="button button-m button-destructive" type="button" onClick={() => deleteVideo()}>Yes, Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </>
                        : ''
                    }
                    {showUploadVideo ?
                        <>
                            <div id="modal-5" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <h2 className="modal-title h4">{type.charAt(0).toUpperCase() + type.slice(1)} file upload</h2>
                                    </div>
                                    <div className="modal__content">
                                        {
                                            showProcessingVideo ?
                                                <div className='fz-block-sm'>
                                                    <AlertMessage
                                                        messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"informational"}
                                                        showButton={false}
                                                    />
                                                </div>
                                                : ''
                                        }
                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <>
                                                <div className={`form-field text-input ${!hasVideoName ? 'error' : ''}`}>
                                                    <label htmlFor="project-description">{type.charAt(0).toUpperCase() + type.slice(1)} name</label>
                                                    <input
                                                        name="videoName"
                                                        id="videoName"
                                                        type="text"
                                                        aria-required="true"
                                                        placeholder=""
                                                        value={videoName}
                                                        onFocus={(e) => { setHasVideoName(true); setShowFormErrorMessage(false); }}
                                                        onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'videoName'); checkVideoName(e.target.value); }}
                                                        onChange={(e) => { setVideoName(e.target.value); }}
                                                    >
                                                    </input>
                                                    {!hasVideoName ?
                                                        <p className="validation-message">Enter {type} name.</p>
                                                        : ''}
                                                </div>
                                                <div className={`form-field text-area ${!hasProjectDescription ? 'error' : ''}`}>
                                                    <label htmlFor="project-description">Description</label>
                                                    <div className="input-container">
                                                        <textarea
                                                            name="Project description"
                                                            id="project-description"
                                                            type="text"
                                                            aria-required="true"
                                                            placeholder=""
                                                            value={projectDescription}
                                                            onFocus={(e) => { setHasProjectDescription(true); setShowFormErrorMessage(false); }}
                                                            onBlur={(e) => { formValidator.clearEmojis(e.target.value, 'projectDescription'); checkProjectDescription(e.target.value); }}
                                                            onChange={(e) => { setProjectDescription(e.target.value); }}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    {!hasProjectDescription ?
                                                        <p className="validation-message">Enter project description</p>
                                                        : ''
                                                    }
                                                </div>
                                            </>
                                            : ''
                                        }

                                        {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                            <div className={`form-field file-input ${!isValidVideo || showErrorMessage ? 'error' : ''}`}>
                                                <input type="file" id="fileupload" className="file-input__input" onChange={(e) => { setShowErrorMessage(false); setIsValidVideo(true); changeHandlerUploads(e) }} ref={videoRef} />
                                                <label htmlFor="fileupload" className="button button-m button-tertiary">
                                                    {getSvg('upload')}
                                                    <span className="text">Choose video&hellip;</span>
                                                </label>
                                                {showErrorMessage ?
                                                    <p className="validation-message">Choose a video first.</p>
                                                    : ''
                                                }
                                                {!isValidVideo ?
                                                    <p className="validation-message">{videoFormatError}</p>
                                                    : ''
                                                }
                                                {fileToBeUploaded !== '' ? <p>{fileToBeUploaded}</p> : ''}
                                            </div>
                                            : errorUploadingVideo ?
                                                <div className="alert-container">
                                                    <div className="alert alert--error">
                                                        <div className="alert__content">
                                                            <p className="alert__heading">There was an error uploading the video. Please try it again.</p>
                                                        </div>
                                                        <button type="button" className="button-tertiary button-s button-icon alert__close" onClick={() => setErrorUploadingVideo(false)}>
                                                            <span className="icon">
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                        <div className='file-input-container block'>
                                            <div className='file-input-container block'>
                                                <div className="fz-block">
                                                    {
                                                        showProcessingVideo ?
                                                            <div className='fz-block'>
                                                                <small className='fz-align-center'>
                                                                    Uploading video&nbsp; &nbsp; &nbsp;<progress className='progress-bar' value={fileProgressSelfVideo} max='100'></progress> &nbsp; &nbsp; &nbsp;
                                                                    {parseInt(fileProgressSelfVideo)} of 100% completed&nbsp;&nbsp;&nbsp;
                                                                    <span className="loader loader--s"></span>
                                                                </small>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                                {showProcessing ?
                                                    <AlertMessage
                                                        messageHeader={processingMessage}
                                                        messageBody={messageWaiting}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"informational"}
                                                        showButton={false}
                                                        showLoader={true}
                                                    />
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        {showFormErrorMessage ?
                                            <AlertMessage
                                                messageHeader={`${type.charAt(0).toUpperCase()}${type.slice(1)} name and description are required.`}
                                                messageType={"error"}
                                                showButton={true}
                                                messageBodyContinuation={null}
                                                onButtonClick={handleAlertButtonClick}
                                            />
                                            : ''
                                        }
                                    </div>
                                    {!showProcessingVideo && showInfo && !errorUploadingVideo ?
                                        <div className="modal__action">
                                            <div className="button-group">
                                                <button
                                                    type='button'
                                                    id='uploadbutton'
                                                    className='button button-m button-primary'
                                                    onClick={() => { handleUpload() }}
                                                >
                                                    {getSvg('upload')}
                                                    Upload video
                                                </button>

                                                <button
                                                    type='reset'
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className='button button-m butto-secondary'
                                                    onClick={() => {
                                                        setShowUploadVideo(false);
                                                        setShowErrorMessage(false);
                                                        setVideoName('');
                                                        setProjectDescription('');
                                                        setIsValidVideo(true);
                                                    }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                        </div>
                                        : ''
                                    }
                                </div>
                            </div>
                        </>
                        : ''
                    }
                </div>
            </main>
        </div>
    )
}


