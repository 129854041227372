import React, {useEffect, useState} from 'react';
import {formatDate} from '../../utils/dateFormat';
import {useNavigate} from 'react-router-dom';
import Axios from 'axios';
import AlertMessage from '../libs/AlertMessage';
import getSvg from '../../utils/getSvg';
import Header from '../libs/Header'
import PageTitle from '../libs/PageTitle';
import Tabs from '../libs/Tabs';
import uuid from 'react-uuid';

export default function ProReplies() {
    const [showProReq, setShowProReq] = useState(false);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const [packagesSubmission, setPackagesSubmission] = useState([]);
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [videoAddress, setVideoAddress] = useState('');
    const [videoFileName, setVideoFileName] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showNoReplies, setShowNoReplies] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [singleReply, setSingleReply] = useState([]);
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    const handleGoToLogin = () =>  navigate('/login');

    useEffect(() => {
        if( isLoggedIn !==  'true'){
            handleGoToLogin();
        } else {
            getFreeLeadReplies(userId);
        }
    }, []);

    const goToProject = (pck) => {
        setSingleReply(pck);
        const videoNameArray = pck.videoName.split('/');
        const videoName = videoNameArray[videoNameArray.length - 1];
        if(pck.isFreeLead === 0){
            setVideoFileName(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName}`);
            setScopeOfWorkVideoThumbnail(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName.replace('mp4', 'jpg')}`);
        } else {
            setVideoFileName(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${pck.userId}/video-library/${videoName}`);
            setScopeOfWorkVideoThumbnail(`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.userId}/video-library/${videoName.replace('mp4', 'jpg')}`);
        }
        setVideoAddress(`${pck.address}${pck.address2 ? ' ' + pck.address2 : ''}, ${pck.city}, ${pck.state} ${pck.zip}`);
        setShowVideoModal(true);
    }

    const getFreeLeadReplies = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getLeadsReplies`, {userId: userId, isAdmin: false}).then((response) => {
            if(response.data.length === 0){
                setShowNoReplies(true);
            } else {
                setShowNoReplies(false);
            }
            setPackagesSubmission(response.data);
        }).catch(function (error) {
            console.log('download package error = ',error);
        })
    }

     const deleteReply = () => {
        const leadId = singleReply.id;
        const isFreeLead = singleReply.isFreeLead;
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteVideoReply`, {id: leadId, isFreeLead: isFreeLead}).then((response) => {
            getFreeLeadReplies(userId);
            setShowDeleteModal(false);
        }).catch(function (error) {
            console.log('tags error = ',error);
        })
     }

    return(
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">  
                    <Tabs
                        userType={userType}
                        setShowProReq={setShowProReq}
                        currentTab={'replies'}
                    /> 
                    <div id="pro-title" className="nowrap">
                        <PageTitle
                            headerText={'Replies'}
                            subHeaderText={'Below are the projects you have replied to.'}
                            showSubHeader={packagesSubmission.length > 0 ? true : false}
                        />
                        {showNoReplies && (
                            <AlertMessage
                                messageHeader={"You have not replied to any leads."}
                                messageBody={""}
                                startDate={null}
                                setShowWarningContainerMessage={''}
                                messageType={"informational"}
                                showButton={false}
                            />
                        )}
                    </div>            
                    <div className="replies-container">
                        {
                        packagesSubmission.map((pck, index) => { 
                            let videoName = ''; 
                            let imgSrc = '';
                            const videoNameArray = pck.videoName.split('/');
                            videoName = videoNameArray[videoNameArray.length - 1];
                            if(pck.isFreeLead === 0){
                                imgSrc = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.packageName}/${process.env.REACT_APP_PRO_VIDEOS_GALLERY_PREFIX}/${videoName.replace('mp4', 'jpg')}`;
                            } else {
                                imgSrc = `${process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX}/${process.env.REACT_APP_THUMBANAIL_PREFIX}-${pck.userId}/video-library/${videoName.replace('mp4', 'jpg')}`;
                            }
                            return(
                                <div className="reply" key={`replay_${index}`}>
                                    <div className="image" key={`div_image_${index}`}>
                                        <img
                                            src={imgSrc}
                                            alt=""
                                            key={`image_${index}`}
                                            onClick={() => {goToProject(pck)}}
                                        />
                                    </div>
                                    <div className="content"  key={`content${index}`}>
                                        <div className="inner"  key={`inner${index}`}>
                                            <h3 className="h5"
                                                key={`h3${index}`}>
                                                    <a href="/" onClick={(e) => {e.preventDefault(); goToProject(pck)}}  key={`ahref_${index}`}>
                                                    {`${pck.address}${pck.address2.length > 0 ? ' ' + pck.address2 : ''}, ${pck.city}, ${pck.state} ${pck.zip}`}
                                                    </a>
                                                </h3>
                                            <p className="submission-date"  key={`submitted${index}`}>Submitted on {formatDate(pck.dateSubmitted)}</p>
                                            {pck.homeOwnerLiked ? 
                                                <p className="action-taken"  key={`action${index}`}>
                                                    {getSvg('liked')}
                                                    <span className="text" key={`span${index}`}>Liked by property owner</span>
                                                </p>
                                            : ''
                                            }
                                            {pck.hasVideoBeenWatched && pck.homeOwnerLiked === 0 ? 
                                                <p className="action-taken"  key={`actiond_${index}`}>
                                                    {getSvg('watching')}
                                                    <span className="text" key={`span_ds${index}`}>Watched by property owner</span>
                                                </p>
                                            : ''
                                            }
                                            {pck.homeOwnerDeleted ? 
                                                <p className="action-taken"  key={`actionds_${index}`}>
                                                    {getSvg('delete')}
                                                    <span className="text" key={`span_d${index}`}>Deleted by property owner</span>
                                                </p>
                                            : ''
                                            }
                                        </div>
                                        <div className="actions" key={`actionsbuttton_${index}`}>
                                            <div className="button-group"  key={`buttongroup${index}`}>
                                                <button
                                                    type="button"
                                                    className="button-s button-destructive-outlined"
                                                    key={`button_action${index}`}
                                                    onClick={(e) => {setSingleReply(pck); setShowDeleteModal(true)}}
                                                >
                                                    {getSvg('delete',  `image_delete_${index}`)}
                                                    <span className="text"  key={`deletereply${index}`}>Delete Reply</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    } 
                    {showVideoModal ? 
                        <div className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h5">{videoAddress}</h2>
                                    <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => setShowVideoModal(false)}>
                                        <span className="icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="modal__content">
                                    <video
                                        className='fz-video'
                                        width='100%'
                                        height='auto'
                                        controls
                                        poster={scopeOfWorkThumbnail}
                                        key={uuid()}
                                    >
                                    <source
                                        src={videoFileName}
                                        type='video/mp4'
                                    />
                                    </video>
                                </div>
                            </div>
                        </div>
                    : ''
                    }                                                                                                                                          
                    </div>           
                    
                    {showProReq ?
                        <div id='modal-success' className='fz-overlay'>
                            <div className='fz-overlay-container'>
                                <div className='modal-content'>
                                    <button id='close-package' className='button button-blank icon-cancel' value='Close page' onClick={() => {setShowProReq(false)}}></button>
                                    <article className='fz-container fz-delisted'>
                                        <div className='block'>
                                            <div className="fz-alert alert-info">
                                                <i className="icon-info">Info:</i>                                    
                                                <p>This feature is for Premium customers only.<br /><br /></p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    :''
                    }
                    { showDeleteModal ?
                        <div className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">{process.env.REACT_APP_DELETE_VIDEO_MESSAGE}</h2>
                                </div>
                                <div className="modal__action">
                                    <div className="button-group">
                                        <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false)}}>No, Cancel</button>
                                        <button className="button button-m button-destructive" type="button" onClick={() => { deleteReply()}}>Yes, Delete</button>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    : ''
                    }
                </div>
            </main>
        </div>
    )
}
