import React, { useState, useEffect, useContext } from "react";
import { downloadThePackage } from '../../utils/downloadPackage';
import { formatDate } from "../../utils/dateFormat";
import { useNavigate } from 'react-router-dom';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import checkForMobile from '../../utils/checkForDeviceType';
import checkUserProfile from '../../utils/checkUserProfile';
import CityFilter from "../libs/CityFilter";
import FilterTags from "../libs/FilterTags";
import getSvg from "../../utils/getSvg";
import Header from '../libs/Header';
import JobTagsFilter from "../libs/JobTagsFilter";
import LeadsOptions from "../libs/LeadsOptions";
import ModalInfo from "../libs/ModalInfo";
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import PageTitle from "../libs/PageTitle";
import StateFilter from "../libs/StateFilter";
import Tabs from '../libs/Tabs';
import Pagination from "../libs/Pagination";
import AppContext from "../../AppContext";

function ProFeedPaid() {
    // const {userId} = useContext(AppContext);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [noPrivatePackages, setNoPrivatePackages] = useState(true);
    const [payedLeads, setPayedLeads] = useState([]);
    const [payedLeadsInitial, setPayedLeadsInitial] = useState([]);
    const [packageStates, setPackageStates] = useState([]);
    const [packageCities, setPackageCities] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [showFilters, setShowFilters] = useState(false);
    const [showMore, setShowMore] = useState({});
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showNoPackages, setShowNoPackages] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const recordsPerPage = 25;
    const folderName = 'cover-image';
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const s3BucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_PAID_PRO_USER_TYPE_TO_BE_CHECKED);
    sessionStorage.setItem('isPropertyOwner', 'false');
    const navigate = useNavigate();
    const handleGoToLogin = () => navigate('/login');
    const [currentPage, setCurrentPage] = useState(
        parseInt(sessionStorage.getItem('currentPage')) || 1
    );

    useEffect(() => {
        // Save the current page to sessionStorage whenever it changes
        sessionStorage.setItem('currentPage', currentPage);
    }, [currentPage]);


    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = payedLeads.slice(indexOfFirstRecord, indexOfLastRecord);

    const downloadPackage = async (pckId) => {
        setIsDownloading(true);
        try {
            await downloadThePackage(pckId);
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);
            console.error(`Download package error: ${e}`);
        }
    }

    const handleFilters = (event) => {
        const { value, checked } = event.target;

        setSelectedValues((prevSelectedValues) => {
            if (checked) {
                // Add the value if it's not already in the array
                if (!prevSelectedValues.includes(value)) {
                    return [...prevSelectedValues, value];
                }
            } else {
                // Remove the value from the array
                return prevSelectedValues.filter((val) => val !== value);
            }
        });
    };

    const goToPackageDetails = (packageName) => {
        sessionStorage.setItem('packageName', packageName);
        navigate(`/prodetails/${packageName}`);
    }

    const checkLogin = (userId, userType, userTypeToBeChecked) => {
        if (isLoggedIn === 'true') {
            getUserProfile(userId, userType, userTypeToBeChecked);
        } else {
            sessionStorage.setItem('pageCameFrom', 'profeed');
            handleGoToLogin();
        }
    }

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            setIsMobile(checkForMobile());
            getPayedLeads(userId);
        } else {
            setShowNotAllowed(true);
        }
    }

    const resetPage = () => {
        window.scrollTo(0, 0);
    }

    const getPayedLeads = (userId) => {
        let counter = 0;
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/allpackages`, { userId: userId, userType: userType }).then((response) => {
            if (response.data.length > 0) {
                setShowNoPackages(false);
                setPayedLeads(response.data);
                setPackageStates([...new Set(response.data.map(item => item.packageState))]);
                setPackageCities([...new Set(response.data.map(item => item.packageCity))]);
                setPayedLeadsInitial(response.data);
                response.data.forEach((item) => {
                    if (item.isPackagePrivate) {
                        counter++;
                    }
                });
                if (counter === response.data.length) {
                    setNoPrivatePackages(false);
                    setShowNoPackages(true);
                } else {
                    setNoPrivatePackages(true);
                }
            } else {
                setShowNoPackages(true);
            }
            setShowLoader(false);
        }).catch(function (error) {
            setShowLoader(false);
            setShowNoPackages(false);
        })
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    useEffect(() => {
        checkLogin(userId, userType, userTypeToBeChecked);
    }, [userId, userType, userTypeToBeChecked]);

    useEffect(() => {
        if (isLoggedIn === 'true') {
            getUserProfile(userId, userType, userTypeToBeChecked);
        }
    }, [isLoggedIn, userId, userType, userTypeToBeChecked]);

    useEffect(() => {
        filterCityStateData();
    }, [selectedCity, selectedState]);

    const filterCityStateData = () => {
        let filteredData = payedLeadsInitial; // Start with the initial data

        // Filter by selected state, if applicable
        if (selectedState && selectedState !== 'All') {
            filteredData = filteredData.filter(item => item.packageState === selectedState);
        }

        // Filter by selected city, if applicable
        if (selectedCity && selectedCity !== 'All') {
            filteredData = filteredData.filter(item => item.packageCity === selectedCity);
        }

        // Always apply tag filter
        if (selectedValues.length > 0) {
            filteredData = filteredData.filter(item => {
                return selectedValues.some(tag => (item.tagsDesign && item.tagsDesign.includes(tag)) || (item.tagsConstruction && item.tagsConstruction.includes(tag)));
            });
        }

        // Reset currentPage to 1 after applying filters
        setCurrentPage(1);

        // Update the displayed data
        setPayedLeads(filteredData);

        // Show or hide the 'no packages' message
        setShowNoPackages(filteredData.length === 0);
    };


    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <section>
                        <Tabs
                            userType={userType}
                            setShowProReq={null}
                            currentTab={'leads'}
                        />
                        <PageTitle
                            headerText={"Paid Leads"}
                            subHeaderText={"View packages uploaded by Fazzad surveyors."}
                            showSubHeader={true}
                        />
                        <fieldset className="border homefeed--interactive">
                            <legend>Filters</legend>
                            <div className="homefeed--details">
                                <LeadsOptions
                                    option1={"Paid leads"}
                                    option2={"Free leads"}
                                    navigateTo={"profeedfree"}
                                    setShowFilters={setShowFilters}
                                    filtersText={"Filters"}
                                    showFilters={true}
                                    paidChecked={true}
                                    freeChecked={false}
                                />
                                <div className="homefeed--details__filters">
                                    <CityFilter
                                        setSelectedCity={setSelectedCity}
                                        selectedCity={selectedCity}
                                        citiesArray={packageCities}
                                    />
                                    <StateFilter
                                        setSelectedState={setSelectedState}
                                        selectedState={selectedState}
                                        statesArray={packageStates}
                                    />
                                    <div className="homefeed--details__sort">
                                        <JobTagsFilter
                                            setShowFilters={setShowFilters}
                                            filterText={"Additional filters"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {payedLeads.length > 0 && noPrivatePackages ?
                            <div id="homefeed">
                                {showLoader && (<span className="loader loader--l"></span>)}
                                {currentRecords.map((pck, index) => {
                                    // Conditional rendering based on isPackagePrivate
                                    if (!pck.isPackagePrivate) {
                                        return (
                                            <div className="homefeed-item" key={index}>
                                                <div className="homefeed-item__content">
                                                    <h2 className="h4 package-name">{pck.packageAddress}{pck.packageAddress2.length > 0 ? ` ${pck.packageAddress2},` : ','} {pck.packageCity}, {pck.packageState} {pck.packageZip}</h2>
                                                    <div className="package-scope">
                                                        <h3 className="h5">Scope of work</h3>
                                                        <p className="preformatted-text">{getPackageDescription(pck.scopeOfWork)}
                                                            {pck.scopeOfWork.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH && (
                                                                <>
                                                                    <br />
                                                                    <a href="/" onClick={(e) => { e.preventDefault(); handleReadMore(pck.id); e.target.blur(); }}>
                                                                        {showMore[pck.id] ? 'Read less' : 'Read more'}
                                                                    </a>
                                                                </>
                                                            )}
                                                        </p>
                                                        <p><small>This package expires on {formatDate(pck.packageExpirationDate)}.</small></p>
                                                    </div>
                                                    <div className="button-group">
                                                        <button type="button" className="button-m button-primary" onClick={(e) => { downloadPackage(pck.packageName) }}>
                                                            {getSvg('download')}
                                                            <span className="text">Download </span>
                                                        </button>
                                                        {pck.dropBoxLink.trim() !== '' && (
                                                            <button type="button" className="button-m button-tertiary" onClick={(e) => { window.open(pck.dropBoxLink, '_blank'); }}>
                                                                {getSvg('outbound-link')}
                                                                <span className="text">Dropbox</span>
                                                            </button>
                                                        )}
                                                        <button type="button" className="button-m button-tertiary" onClick={(e) => { goToPackageDetails(pck.packageName) }}>
                                                            <span className="text">View</span>
                                                            {getSvg('chevron-right')}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="homefeed-item__image">
                                                    <img className="rounded" src={pck.coverImage !== null ? `${s3BucketPrefix}/${pck.packageName}/${folderName}/${pck.coverImage}` : NoCoverImage} />
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                                {recordsPerPage < payedLeads.length && (
                                    <Pagination
                                        recordsPerPage={recordsPerPage}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalNumberOfRecords={payedLeads.length}
                                        resetPage={resetPage}
                                    />
                                )}
                            </div>
                            :
                            showNoPackages && !showLoader ?
                                <AlertMessage
                                    messageHeader={"No paid leads available."}
                                    messageBody={""}
                                    startDate={null}
                                    setShowWarningContainerMessage={''}
                                    messageType={"informational"}
                                    showButton={false}
                                />
                                : ''
                        }
                    </section>
                    {showFilters ?
                        <FilterTags
                            handleFilters={handleFilters}
                            setSelectedValues={setSelectedValues}
                            setShowFilters={setShowFilters}
                            filterCityStateData={filterCityStateData}
                            getPayedLeads={getPayedLeads}
                            selectedValues={selectedValues}
                            userId={userId}
                        />
                        : ''
                    }
                    {isDownloading ?
                        <ModalInfo
                            messageHeader={"Downloading your package..."}
                            messageBody={`Files will be saved on your ${isMobile ? 'mobile device' : 'computer'}.`}
                            messageType={"informational"}
                            showCloseButton={false}
                            showLoader={true}
                        />
                        : ''
                    }
                </div>
            </main>
            {showNotAllowed ?
                <ModalInfo
                    messageHeader={"You are not allowed to see this page."}
                    messageBody={"This page is just for registered Pros & Vendors."}
                    messageType={"error"}
                />
                : ''
            }
        </div>
    )
}

export default ProFeedPaid;
