// components/PlanAndPicture.js
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useImageFetch} from './hooks/useImageFetch';
import styles from './PlanAndPicture.module.scss';
import EditableHeading from "./pdf-editor/editable-heading/editable-heading";
import TabbedCarouselComponent from "./pdf-editor/tabbed-carousel/tabbed-carousel";
import {PdfEditorComponent} from "./pdf-editor/pdf-editor-component/pdf-editor-component";
import {
  useCreatePlanAndPicture,
  useGetPdfsAndPlanAndPictures,
  useUpdatePlanAndPicture
} from "./hooks/usePlanAndPicture";
import {LoadingSpinner} from "./page-select-modal/loading-spinner/loading-spinner";
import BreadCrumbs from "../BreadCrumbs";
import Header from "../Header";

function convertPdfPages(pdfPages) {
  const result = {};
  pdfPages.forEach(pdfPage => {
    if (pdfPage.isSelected) {
      result[pdfPage.pageNumber] = pdfPage.pins.map(pin => ({
        x: pin.xCoordinate,
        y: pin.yCoordinate,
        page: pdfPage.pageNumber,
        img: pin.additionalData?.imageUrl,
        dataType: pin.dataType,
        id: pin.id
      }))
    }
  });
  return result;
}

export default function PlanAndPicture() {
  const location = useLocation();
  const navigate = useNavigate();
  const viewType = location.state?.viewType;
  const packageNameFromState = location.state?.packageName;
  const selectedPages = location.state?.selectedPages;
  const {pdfId, packageName: packageNameFromParams} = useParams();
  const [id, setId] = useState();
  const [currentImage, setCurrentImg] = useState('');
  const [pins, setPins] = useState({});
  const [planName, setPlanName] = useState('Untitled Plan');
  const [activeTab, setActiveTab] = useState('STILL_IMAGE');
  const [hasChanges, setHasChanges] = useState(false);
  const [initialPins, setInitialPins] = useState({});
  const [initialPlanName, setInitialPlanName] = useState('');
  const [usedImages, setUsedImages] = useState(new Set());

  const packageName = packageNameFromState || packageNameFromParams;

  const {planAndPictures, allUsedPhotos} = useGetPdfsAndPlanAndPictures(packageName);
  const data = planAndPictures?.find((it => it.pdfId === pdfId)) ?? {};
  const {updatePlanAndPicture, loading: updateLoading, error: updateError, data: updateData} = useUpdatePlanAndPicture();
  const {createPlanAndPicture, loading: createLoading, error: createError, data: createData} = useCreatePlanAndPicture();

  const {
    images: stillImages = [],
    isLoading: isLoadingStillImages,
    error: stillImagesError
  } = useImageFetch({packageName, pictureType: 'still-pictures'});

  const {
    images: images360 = [],
    isLoading: isLoading360Images,
    error: images360Error
  } = useImageFetch({packageName, pictureType: 'three-sixty-pictures'});

  const prefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com/';

  useEffect(() => {
    if (data?.planName) {
      setPlanName(data.planName);
      setInitialPlanName(data.planName);
    }
    if (data?.pdfPages) {
      const convertedPins = convertPdfPages(data.pdfPages);
      setPins(convertedPins);
      setInitialPins(convertedPins);
    }
  }, [data]);

  const addUsedImage = useCallback((imageUrl) => {
    setUsedImages(prevUsedImages => {
      const imageKey = imageUrl.replace(prefix, '');
      return new Set([...prevUsedImages, imageKey]);
    });
  }, [prefix]);

  const removeUsedImage = useCallback((imageUrl) => {
    setUsedImages(prevUsedImages => {
      const imageKey = imageUrl.replace(prefix, '');
      const newUsedImages = new Set(prevUsedImages);
      newUsedImages.delete(imageKey);
      return newUsedImages;
    });
  }, [prefix]);

  const handlePinAdd = useCallback((newPin) => {
    setPins(prevPins => {
      const pageNumber = newPin.page;
      return {
        ...prevPins,
        [pageNumber]: [...(prevPins[pageNumber] || []), newPin]
      };
    });
    addUsedImage(newPin.img);
  }, [addUsedImage]);

  const handlePinRemove = useCallback((removedPin) => {
    setPins(prevPins => {
      const newPins = {...prevPins};
      Object.keys(newPins).forEach(pageNumber => {
        newPins[pageNumber] = newPins[pageNumber].filter(pin => pin.id !== removedPin.id);
      });
      return newPins;
    });
    removeUsedImage(removedPin.img);
  }, [removeUsedImage]);

  useEffect(() => {
    const initialUsedImages = new Set(allUsedPhotos.map(photo => photo.replace(prefix, '')));
    setUsedImages(initialUsedImages);
  }, [allUsedPhotos, prefix]);

  const create = useCallback(() => {
    createPlanAndPicture({
      selectedPages,
      pins,
      planName,
      pdfId
    }).then((data) => setId(data.planAndPicture.id));
  }, [selectedPages, pins, planName, pdfId]);

  const update = useCallback(() => {
    updatePlanAndPicture(data?.id, {
      selectedPages,
      pins,
      planName,
      pdfId
    }).then(() => {
      setInitialPins(pins);
      setInitialPlanName(planName);
      setHasChanges(false);
    });
  }, [selectedPages, pins, planName, pdfId, data?.id, updatePlanAndPicture]);

  const checkForChanges = useCallback(() => {
    const pinsChanged = JSON.stringify(pins) !== JSON.stringify(initialPins);
    const planNameChanged = planName !== initialPlanName;
    setHasChanges(pinsChanged || planNameChanged);
  }, [pins, initialPins, planName, initialPlanName]);

  useEffect(() => {
    checkForChanges();
  }, [pins, planName, checkForChanges]);

  if (isLoadingStillImages || isLoading360Images) {
    return <div>Loading images...</div>;
  }

  if (stillImagesError || images360Error) {
    return <div>Error: {stillImagesError || images360Error}</div>;
  }

  return (
      <>
        <Header/>
        <div className={styles.container}>
          <section className={styles.breadcrumbs}>
            {viewType === 'edit' && <BreadCrumbs
                header1={'Home'}
                isLink1Clickable={true}
                link1={'survey'}
                header2={'Package Name'}
                link2={`editpackage/${packageName}`}
                isLink2Clickable={true}
                isLink2Navigation={true}
                header3={'Upload Files'}
                link3={`editpackagefolderspictures/${packageName}`}
                isLink3Clickable={true}
                isLink3Navigation={true}
                header4={planName}
            />}
            {viewType === 'surveyor' && <BreadCrumbs
                header1={'Home'}
                isLink1Clickable={true}
                link1={'survey'}
                isLink1Navigation={true}
                header2={"list"}
                link2={`plan-and-picture/${packageName}/list`}
                isLink2Navigation={true}
                isLink2Clickable={true}
                header3={'Plan & Picture'}
            />}
          </section>
          <section className={styles['heading-container']}>
            <EditableHeading planName={planName} setPlanName={setPlanName} viewType={viewType}/>
            {viewType === 'edit' && !createLoading && !updateLoading && <>
              {!!(id || data?.id) && <button className={"button-primary button-m"}
                                             disabled={!hasChanges}
                                             onClick={update}>Update</button>}
              {!id && !data?.id &&
                  <button className={"button-primary button-m"} onClick={create}>Save</button>}
            </>}
          </section>
          <section className={styles['content-container']}>
            {viewType === 'edit' && <TabbedCarouselComponent
                stillImages={stillImages ? stillImages.filter(img => !usedImages.has(img.Key)) : []}
                images360={images360 ? images360?.filter(img => !usedImages.has(img.Key)) : []}
                setCurrentImg={setCurrentImg}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />}
            <PdfEditorComponent
                pdfBlob={location.state?.pdfBlob}
                pins={pins}
                setPins={setPins}
                currentImage={currentImage}
                viewType={viewType}
                selectedPages={selectedPages?.sort((a, b) => a.pageNumber > b.pageNumber ? 1 : -1)}
                activeTab={activeTab}
                onPinAdd={handlePinAdd}
                onPinRemove={handlePinRemove}
            />
          </section>
        </div>
        {(updateLoading || createLoading) && <div className={styles['loading-overlay']}>
          <div>
            <LoadingSpinner/>
          </div>
        </div>}
      </>
  );
}