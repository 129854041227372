import React, { useRef, useEffect, useState } from 'react';
import { getObjectSignedUrl } from "../../utils/awsHelpers";
import { ReactComponent as ImagePlaceholder } from "../../images/image-placeholder.svg";
export function ImageWithPresignedUrl({ bucketName, fileKey, fileType, setPreSignedUrl, imageUrl }) {
    const imgRef = useRef(null);
    const [url, setUrl] = useState(imageUrl);

    const fetchPresignedUrl = async (bucketName, fileKey, fileType) => {
        const _url = await getObjectSignedUrl(bucketName, fileKey, fileType);
        if (_url) {
            setUrl(_url);
            setPreSignedUrl(_url);
        }
    }


    // Fetch the initial pre-signed URL on component mount
    useEffect(() => {
        if (!imageUrl) {
            fetchPresignedUrl(bucketName, fileKey, fileType);
        }

    }, [bucketName, fileKey, fileType]);

    return <>
        {url && <img ref={imgRef} loading="lazy" src={url || ''} alt={fileKey} />}
        {!url && <div style={{ backgroundColor: '#EFF1F3', position: "relative" }}><ImagePlaceholder /></div>}
    </>;
}