/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from './PageTitle';
import Axios from 'axios';
import Header from './Header';
import S31 from "react-aws-s3";
import graphicFolder from '../../images/graphic-folder-purple.png';
import getSvg from '../../utils/getSvg';
import { GetObjectLockConfigurationOutputFilterSensitiveLog } from '@aws-sdk/client-s3';
import PdfList from "./plan-and-picture/pdf-list/pdf-list";
import PdfThumbnailsModal from "./plan-and-picture/page-select-modal/plan-and-picture.select-page-modal";
import { folder } from "jszip";
import { ImageWithPresignedUrl } from "./ImageWithPresignedUrl";
import { getPutObjectSignedUrl } from "../../utils/awsHelpers";
import axiosInstance from '../../utils/axiosInstance';
import { getBucketNameByViewType, ViewType, getFileExt } from "../../utils/packageHelper";
import { VideoWithPresignedUrl } from "../libs/VideoWithPresignedUrl";

window.Buffer = window.Buffer || require("buffer").Buffer;

export default function EditPackagePictures() {
    const navigate = useNavigate();
    const { packageName, pictureType } = useParams();
    const [currentIndex, setCurrentIndex] = useState(0)
    const [errorMessage, setErrorMessage] = useState('Just images with format .jpg or .jpeg or .png can be uploaded');
    const [folderStructure, setFolderStructure] = useState({});
    const [selectedPdf, setSelectedPdf] = useState(undefined);
    const [showDeleteButton, setShowDeleteButton] = useState(true);
    const [foldersFiles, setFoldersFiles] = useState([]);
    const [packageData, setPackageData] = useState({});
    const [pictureTypeDisplay, setPictureTypeDisplay] = useState('');
    const [thePictureType, setThePictureType] = useState('cover-image');
    const [selectedPictures, setSelectedPictures] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [showNoFilesToDelete, setShowNoFilesToDelete] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [newScopeOfWorkVideoUrl, setNewScopeOfWorkVideoUrl] = useState('');
    const [showProcessing, setShowProcessing] = useState(false);
    const [showWrongImageFormat, setShowWrongImageFormat] = useState(false);
    const [uploadTypeText, setUploadTypeText] = useState('picture');
    const fileInputRef = useRef();
    const [currentPath, setCurrentPath] = useState([]);
    const [currentOption, setCurrentOption] = useState("Cover Image");
    const [allUsedPhotos, setAllUsedPhotos] = useState([]);
    const imageExtensionsRegex = /\.(jpg|jpeg|png)$/i;
    const cadExtensionsRegex = /\.(dwg)$/i;
    const pdfExtensionsRegex = /\.(pdf)$/i;
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const bucketPrefix = 'https://fazzad-projects-compressed.s3.us-west-1.amazonaws.com';
    const s3BucketPrefixVideo = process.env.REACT_APP_S3_PROJECTS_VIDEOS_THUMBNAILS_PREFIX;
    const videoBucket = process.env.REACT_APP_SCOPE_OF_WORK;
    const videoBucketPreview = process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX;
    const videoPreviewBucket = process.env.REACT_APP_PROJECT_VIDEOS_BUCKET_NAME;
    let wrongFiles = [];
    const bucketName = 'fazzad-projects';
    const filesToBeDeleted = [];
    let scopeOfWorkVideoUrl = '';
    let scopeOfWorkVideoUrlVideo = '';
    const config = {
        bucketName: bucketName,
        region: process.env.REACT_APP_REGION,
    };
    const configScopeOfWorkUpdateVideo = {
        bucketName: 'fazzad-projects-videos-source',
        region: process.env.REACT_APP_REGION,
    };
    const [currentFolder, setCurrentFolder] = useState('');

    useEffect(() => {
        const localStorageOption = localStorage.getItem('currentOption');
        if (typeof pictureType !== 'undefined') {
            getFileType(pictureType);
            getPackageImage(packageName, pictureType);
        } else if (localStorageOption !== null) {
            const localStoragePackage = localStorageOption === 'plan-picture' ? 'pdf-files' : localStorageOption;
            getFileType(localStorageOption);
            getPackageImage(packageName, localStoragePackage);
        } else {
            getFileType('cover-image');
            getPackageImage(packageName, 'cover-image');
        }
    }, [packageName, pictureType]);


    // const addSelectedPicture = (e, file) => {
    //     console.log('file = ', file);
    //     setSelectedPictures((currentSelectedPictures) => {
    //         // Check if the file is already selected
    //         console.log('currentSelectedPictures = ', currentSelectedPictures);
    //         if (currentSelectedPictures.includes(file)) {
    //             // If selected, remove it from the array
    //             console.log(1)
    //             return currentSelectedPictures.filter(item => item !== file);
    //         } else {
    //             console.log(2)
    //             // If not selected, add it to the array
    //             return [...currentSelectedPictures, file];
    //         }
    //     });
    // };

    const addSelectedPicture = (e, file) => {
        // e.preventDefault(); // Stop the form from submitting

        const newSelectedPictures = selectedPictures.some(f => f.Key === file.Key)
            ? selectedPictures.filter(f => f.Key !== file.Key)
            : [...selectedPictures, file];

        setSelectedPictures(newSelectedPictures);
    };

    const getFileType = (pictureType) => {
        switch (pictureType) {
            case 'still-pictures':
                setPictureTypeDisplay('Still Pictures');
                setCurrentOption('Still Pictures');
                setThePictureType('still-pictures');
                setUploadTypeText('picture');
                break;
            case 'three-sixty-pictures':
                setCurrentOption('360 Pictures');
                setPictureTypeDisplay('360 Pictures');
                setThePictureType('three-sixty-pictures');
                setUploadTypeText('picture');
                break;
            case 'drone-pictures':
                setPictureTypeDisplay('Drone Pictures');
                setCurrentOption('Drone Pictures');
                setThePictureType('drone-pictures');
                setUploadTypeText('picture');
                break;
            case 'cad-file':
                setPictureTypeDisplay('CAD File');
                setCurrentOption('CAD File');
                setThePictureType('dwg-files');
                setUploadTypeText('file');
                break;
            case 'pdf-files':
                setPictureTypeDisplay('PDF Files');
                setCurrentOption('PDF Files');
                setThePictureType('pdf-files');
                setUploadTypeText('file');
                break;
            case 'plan-picture':
                setCurrentOption('plan-picture');
                setUploadTypeText('file');
                setThePictureType('pdf-files');
                setPictureTypeDisplay('PDF Files');
                break;
            case 'cover-image':
                setPictureTypeDisplay('Cover Image');
                setCurrentOption('Cover Image');
                setThePictureType('cover-image');
                setUploadTypeText('image');
                break;
            case 'scope-of-work':
                setPictureTypeDisplay('Scope of Work');
                setCurrentOption('Scope of Work');
                setThePictureType('scope-of-work');
                setUploadTypeText('video');
                break;
            default:
                setPictureTypeDisplay('Cover Image');
                setCurrentOption('Cover Image');
                setThePictureType('cover-image');
                setUploadTypeText('image');
                break;
        }
    }

    const goToFeed = () => {
        navigate(`/survey`);
    }

    const getPackageImage = async (packageName, pictureType) => {
        setFolderStructure({});
        setCurrentPath([]);
        setCurrentFolder("");
        if (pictureType === 'cad-file') {
            pictureType = 'dwg-files';
        }
        if (packageName) {
            try {
                setShowLoading(true);
                const response = await axiosInstance.post(`${process.env.REACT_APP_LOCALHOST}/uniqueImage`, { packageName: packageName, pictureType: pictureType });
                const tempData = refactorObject(response.data);
                if (pictureType === 'still-pictures' || pictureType === 'three-sixty-pictures') {
                    if (Array.isArray(response.data)) {
                        setFolderStructure(tempData);
                    } else {
                        setFolderStructure({});
                    }
                } else {
                    if (response.data.length > 0) {
                        setFoldersFiles(response.data);
                    } else {
                        setFoldersFiles([]);
                    }
                    setShowLoading(false);
                }

            } catch (error) {
                setShowLoading(false);
                console.error('Error fetching package images:', error);
            }
        }
    };

    const updatePath = (path) => {
        setCurrentPath(path);
        setCurrentFolder(path);
    };

    const resetPath = () => {
        setCurrentPath([]);
    };

    const refactorObject = (data) => {
        const newObj = {};
        if (data.length > 0) {
            data.forEach((item) => {
                const keyParts = item.Key.split('/').slice(2);
                const fileName = keyParts.pop();
                let currentObj = newObj;

                keyParts.forEach((part, index) => {
                    if (!currentObj[part]) {
                        if (index === keyParts.length - 1) {
                            currentObj[part] = {
                                [fileName]: {
                                    "_isFile": true,
                                    ...item
                                }
                            };
                        } else {
                            currentObj[part] = {};
                        }
                    } else if (index === keyParts.length - 1) {
                        currentObj[part][fileName] = {
                            "_isFile": true,
                            ...item
                        };
                    }
                    currentObj = currentObj[part];
                });
            });
        }
        return newObj;
    };

    const updateFilesSelected = (e) => {
        e.preventDefault();
        wrongFiles = [];
        const files = [...e.target.files];
        setSelectedPictures(e.target.files);
        if (e.target.files.length > 0) {
            files.map((file) => {
                if (thePictureType === 'three-sixty-pictures' || thePictureType === 'still-pictures' || thePictureType === 'drone-pictures' || thePictureType === 'cover-image') {
                    if (!imageExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just images with format .jpg or .jpeg or .png can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'scope-of-work') {
                    if (!videoExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just video files with format .mp4 or .mov or .avi or. wmv or .webm can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'dwg-files') {
                    if (!cadExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .dwg can be uploaded');
                        wrongFiles.push(file.name);
                    }
                } else if (thePictureType === 'pdf-files') {
                    if (!pdfExtensionsRegex.test(file.name)) {
                        setErrorMessage('Just files with format .pdf can be uploaded');
                        wrongFiles.push(file.name);
                    }
                }

            });
            if (wrongFiles.length === 0) {
                setShowWrongImageFormat(false);
                showConfirmModal();
            } else {
                setShowWrongImageFormat(true);
            }
        } else {
            setSelectedPictures([]);
        }
    }

    const showConfirmModal = () => {
        setShowModal(true);
    }

    const checkForDeletion = (e) => {
        if (selectedPictures.length > 0) {
            setShowDeleteModal(true);
            setShowNoFilesToDelete(false);
        } else {
            setShowNoFilesToDelete(true);
            setShowDeleteModal(false);
        }
    }

    const updateFolderStructure = (folderStructure, fileStructure) => {
        // Iterate over each file in the fileStructure array
        fileStructure.forEach(file => {
            // Extract the filename from the current file's Key
            const fileName = file.Key.split('/').pop();

            // Remove the file from the folderStructure
            for (const floor in folderStructure) {
                const areas = folderStructure[floor];
                for (const area in areas) {
                    if (areas[area]._isFile && areas[area].Key.includes(fileName)) {
                        delete folderStructure[floor][area]; // Delete file if it's directly under an area
                    } else {
                        const images = areas[area];
                        for (const image in images) {
                            if (images[image]._isFile && images[image].Key.includes(fileName)) {
                                delete folderStructure[floor][area][image]; // Delete file if it's nested within an area
                            }
                        }
                    }
                }
            }
        });

        return folderStructure;
    };

    const updateCurrentFilesOnDb = (packageName, pictureType, selectedPictures) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateFileOnDb`, {
            packageName: packageName,
            pictureType: pictureType,
            filesName: cleanMediaUrl(selectedPictures[0].name),
        }).then((response) => {
            setNewScopeOfWorkVideoUrl(cleanMediaUrl(selectedPictures[0].name));
        }).catch(function (error) {
            console.log('error = ', error);
        });
    };

    const deleteFilesFromS3 = async (type = 'files') => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteFilesFromPackage`, {
            isVideo: false,
            isFromFolder: true,
            packageName: packageName,
            bucketName: bucketName,
            filesNames: type === 'files' ? selectedPictures : filesToBeDeleted,
        }).then((response) => {
            let remainingFiles = [];
            if (response.data.Deleted.length > 0) {
                if (thePictureType === 'still-pictures' || thePictureType === 'three-sixty-pictures') {
                    remainingFiles = updateFolderStructure(folderStructure, selectedPictures);
                    setFolderStructure(remainingFiles);
                } else {
                    const selectedPicturesKeys = new Set(selectedPictures.map(pic => pic.Key));
                    const remainingFiles = foldersFiles.filter(file => !selectedPicturesKeys.has(file.Key));
                    setFoldersFiles(remainingFiles);
                }
                setShowDeleteModal(false);
                setSelectedPictures([]);
                updateHasPictures(remainingFiles.length);
                if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                    deleteThumbnailFromS3(packageName);
                    Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateFileOnDb`, {
                        packageName: packageName,
                        pictureType: pictureType,
                        filesName: '',
                    }).then((response) => {
                        return;
                    }).catch(function (error) {
                        console.log('error = ', error);
                    });
                }
            }
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    };

    const deleteThumbnailFromS3 = async (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteThumbnailFromPackage`, {
            packageName: packageName,
            bucketName: 'fazzad-project-videos-thumbnails',
        }).then((response) => {
            return;
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    }


    const uploadWithSignedUrl = async (fileData, bucketName, key) => {
        const signedUrl = await getPutObjectSignedUrl(bucketName, key, fileData.type);

        if (!signedUrl) {
            throw new Error("cannot generate signed url");
        }

        const xhr = new XMLHttpRequest();

        xhr.open('PUT', signedUrl, true);
        // Set the appropriate headers
        xhr.setRequestHeader('Content-Type', fileData.type);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const progress = (event.loaded / event.total) * 100;
                const myTotal = progress.toString().substring(0, 4);
                console.log(myTotal);
            }
        };

        xhr.send(fileData);

        // Handle errors
        xhr.onerror = function (ev) {
            console.error('Error uploading file.', ev);
        };

        xhr.onload = function () {
            if (xhr.status === 200) {
                console.log('Request completed successfully:', xhr.responseText);
                // updateScopeOfWorkVideo(fileName, packageName);
            } else {
                console.error('Request failed with status:', xhr.status);
                throw new Error(xhr.error);
                //setUploadStarted(false);
            }
        };
    }

    const uploadPromise = async (file, packageName, currentFolder) => {
        try {
            let s3BucketName = thePictureType;
            let compressedFile = '';
            let uploadPath = '';
            compressedFile = file;
            let bucketName = config.bucketName;
            const uploadFileName = cleanMediaUrl(file.name);
            if (thePictureType === 'still-pictures' || thePictureType === 'three-sixty-pictures') {
                uploadPath = currentFolder === '' ? `${packageName}/${s3BucketName}/${uploadFileName}` : `${packageName}/${s3BucketName}/${currentFolder.join('/')}/${uploadFileName}`;
            } else if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
                bucketName = configScopeOfWorkUpdateVideo.bucketName;
            } else {
                uploadPath = `${packageName}/${s3BucketName}/${uploadFileName}`;
            }

            try {
                await uploadWithSignedUrl(compressedFile, bucketName, uploadPath)
            } catch (uploadError) {
                console.error(`Upload failed for ${file.name}: ${uploadError}`);
            }
        } catch (error) {
            console.error(`Batch upload failed: ${error}`);
        }
    };

    const updateHasPictures = (remainingFile) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/updateHasPictures`, {
            packageName: packageName,
            pictureType: localStorage.getItem('currentOption'),
            hasPictures: remainingFile > 0 ? true : false
        }).then(() => {
            return;
        }).catch(function (error) {
            console.log('error = ', error);
        });
    };

    const deleteCurrentFilesFromS3 = async (packageName, pictureType) => {
        let bucketName = 'fazzad-projects';
        if (pictureType !== 'cover-image') {
            bucketName = 'fazzad-projects-videos';
        }
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/deleteCurrentFile`, {
            packageName: packageName,
            bucketName: bucketName,
            pictureType: pictureType
        }).then((response) => {
            if (typeof response.data.Deleted !== 'undefined' && response.data.Deleted.length > 0) {
                setShowDeleteModal(false);
                setSelectedPictures([]);
            }
        }).catch(function (error) {
            setShowDeleteModal(false);
            console.log('error = ', error);
        });
    };

    const handleUpload = async () => {
        try {
            setShowModal(false);
            setShowProcessing(true);
            if (!showWrongImageFormat) {
                if (selectedPictures.length > 0) {
                    if (thePictureType === 'cover-image' || thePictureType === 'scope-of-work') {
                        deleteCurrentFilesFromS3(packageName, thePictureType);
                    }
                    if (thePictureType === 'scope-of-work-videos' || thePictureType === 'scope-of-work') {
                        deleteThumbnailFromS3(packageName);
                    }
                    setShowUploadModal(true);
                    try {
                        let fileIndex = 0;
                        for (const pict of selectedPictures) {
                            fileIndex++;
                            setCurrentIndex(fileIndex);
                            await uploadPromise(pict, packageName, currentFolder); // Passing a single file instead of an array
                        }
                        if (thePictureType === 'cover-image' || thePictureType === 'scope-of-work') {
                            updateCurrentFilesOnDb(packageName, thePictureType, selectedPictures);
                        }
                        setSelectedPictures([]);
                        updateHasPictures(selectedPictures.length);

                    } catch (e) {
                        setSelectedPictures([]);
                        console.error(`Uploading file error: ${e}`);
                    } finally {
                        setShowUploadModal(false);
                    }
                } else {
                    setSelectedPictures([]);
                    setShowModal(false);
                    setShowUploadModal(false);
                }
            }
        } catch (e) {
            setSelectedPictures([]);
            setShowModal(false);
        }
    }

    const getPageTitle = (currentOption) => {
        switch (currentOption) {
            case 'plan-picture':
                return {
                    headerText: `Edit As-Built Package`,
                    subheaderText: `All fields are required unless marked as optional`,
                }
            default:
                return {
                    headerText: `Edit ${pictureTypeDisplay}`,
                    subheaderText: `Add or remove ${pictureTypeDisplay}`,
                }
        }
    }

    const checkForDisable = (pictureType) => {
        let isDisabled = false;
        if (pictureType === '360-pictures' || pictureType === 'still-pictures') {
            if (currentFolder === '' || selectedPictures.length === 0) {
                isDisabled = true;
            }
        } else {
            isDisabled = selectedPictures.length === 0;
        }
        return isDisabled;
    }

    const hasKey = (selectedPictures) => {
        return Object.values(selectedPictures).some(pic => pic.hasOwnProperty('Key'));
    }

    const checkForChecked = (selectedPictures, file) => {
        if (hasKey(selectedPictures)) {
            return selectedPictures.some(f => f.Key === file.Key);
        }
    }

    const getText = (pictureType) => {
        if (pictureType === 'cover-image' || pictureType === 'scope-of-work') {
            return 'Replace';
        } else {
            return 'Upload';
        }
    };

    return (
        <div>
            <Header />
            {selectedPdf && (
                <PdfThumbnailsModal onClose={() => setSelectedPdf(undefined)} selectedPdfData={selectedPdf} allUsedPhotos={allUsedPhotos}/>
            )}
            {showModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to upload {selectedPictures.length} {selectedPictures.length > 1 ? 'files ' : 'file '} ?</h2>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowModal(false); setSelectedPictures([]) }}>No, Cancel</button>
                                <button className="button button-m button-primary" type="button" onClick={() => { handleUpload() }}>Yes, Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeleteModal && (
                <div id="modal-5" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Are you sure you want to delete {selectedPictures.length} file{selectedPictures.length > 1 ? 's ?' : ' ?'}</h2>
                        </div>
                        <div className="modal__content">
                            <p>Once a file is deleted it cannot be recovered. You will need to upload it again.</p>
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button className="button button-m button-secondary" type="button" onClick={() => { setShowDeleteModal(false) }}>No, Cancel</button>
                                <button className="button button-m button-destructive" type="button" onClick={() => deleteFilesFromS3()}
                                >Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showUploadModal ?
                <div id="modal-6" className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">File Upload Progress</h2>
                        </div>
                        <div className="modal__content">
                            {showProcessing && (
                                <div className="alert-container">
                                    <div className="alert alert--informational">
                                        <div className="alert__content">
                                            <div className="alert-inline__icontitle">
                                                <p className="alert__heading">{process.env.REACT_APP_PROCESSING_MESSAGE_WAIT} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="file-uploads">
                                <section>
                                    {currentIndex < selectedPictures.length && selectedPictures.length > 0 ?
                                        currentIndex > 0 ?
                                            <>
                                                <h5>{pictureTypeDisplay}</h5>
                                                <div className="message"> Uploading {uploadTypeText} {currentIndex} of {selectedPictures.length}<span className="loader loader--s"></span></div>
                                            </>
                                            :
                                            ''
                                        :
                                        currentIndex === selectedPictures.length && selectedPictures.length > 0 && (
                                            <div className='message fz-align-center'>
                                                <p>All {uploadTypeText}s have been uploaded!</p>
                                                {<span className="icon">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#007000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>}
                                            </div>
                                        )}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
            }

            <main id="fazzad-main">
                <div id="app-container">
                    <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
                        <ol>
                            <li>
                                <a href="/" onClick={(e) => { e.preventDefault(); goToFeed(); }}>Home</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li>
                                <a href="/" onClick={(e) => { e.preventDefault(); navigate(`/editpackage/${packageName}`); }}>Package Details</a>
                                <span className="separator">
                                    {getSvg('chevron-right')}
                                </span>
                            </li>
                            <li aria-current="page">
                                <span>{pictureTypeDisplay}</span>
                            </li>
                        </ol>
                    </nav>
                    <div id='title'>
                        <PageTitle
                            headerText={getPageTitle(currentOption).headerText}
                            subHeaderText={getPageTitle(currentOption).subheaderText}
                            showSubHeader={true}
                        />
                        {currentOption !== 'plan-picture' && <div className="button-group">
                            <div className="form-field file-input">
                                {currentFolder !== '' ?
                                    <><input
                                        ref={fileInputRef}
                                        type="file"
                                        id="newpicture"
                                        className="file-input__input"
                                        data-multiple-caption='{count} files selected'
                                        multiple={thePictureType !== 'cover-image' && thePictureType !== 'scope-of-work'}
                                        onChange={updateFilesSelected} /><label htmlFor="newpicture" className="button button-s button-tertiary">
                                            {getSvg('upload')}
                                            <span className="text">{getText(thePictureType)} {pictureTypeDisplay}</span>
                                        </label></>
                                    :
                                    <button disabled type="button" className="button-destructive-outlined button-s">
                                        {getSvg('upload')}
                                        <span className="text">Upload {pictureTypeDisplay}</span>
                                    </button>
                                }
                            </div>
                            <div className="form-field file-input">
                                <button type="button" className="button-destructive-outlined button-s" onClick={() => checkForDeletion()} disabled={checkForDisable(pictureType)}>
                                    {getSvg('delete')}
                                    <span className="text">Delete Selected {uploadTypeText}s</span>
                                </button>
                            </div>
                        </div>}
                    </div>
                    <div id="folder-container" >
                        <section className="container-nav__layout">
                            {showWrongImageFormat ?
                                <div id="modal-2" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            <div className="alert-container">
                                                <div className="alert alert--error">
                                                    <div className="alert__content">
                                                        <div className="alert-inline__icontitle">
                                                            <p className="alert__heading">Something wrong with the files you selected.</p>
                                                        </div>
                                                        <p className="alert__message">{errorMessage}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setSelectedPictures([]); setShowWrongImageFormat(false) }}>
                                                {getSvg('close')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            {showNoFilesToDelete ?
                                <div id="modal-2" className="modal-container">
                                    <div className="modal">
                                        <div className="modal__header">
                                            {getSvg('warning')}
                                            <h2 className="modal-title h4 warning">You have not selected any files to be deleted</h2>
                                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setShowNoFilesToDelete(false) }}>
                                                {getSvg('close')}
                                            </button>
                                        </div>
                                        <div className="modal__content">
                                            <p>In order to delete files, you need to select at least one file first.</p>
                                        </div>
                                    </div>
                                </div>
                                : ''
                            }
                            <aside>
                                <nav className="nav-file-type">
                                    <ul>
                                        <li className={currentOption === 'Cover Image' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); localStorage.setItem('currentOption', 'cover-image'); getFileType('cover-image'); getPackageImage(packageName, 'cover-image'); }}>Cover Image</a>
                                        </li>
                                        <li className={currentOption === 'Scope of Work' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); localStorage.setItem('currentOption', 'scope-of-work'); getFileType('scope-of-work'); setCurrentOption('Scope of Work'); getPackageImage(packageName, 'scope-of-work'); }}>Scope of Work</a>
                                        </li>
                                        <li className={currentOption === 'Still Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); getFileType('still-pictures'); localStorage.setItem('currentOption', 'still-pictures'); setCurrentOption('Still Pictures'); getPackageImage(packageName, 'still-pictures'); }}>Still Pictures</a>
                                        </li>
                                        <li className={currentOption === '360 Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); getFileType('three-sixty-pictures'); localStorage.setItem('currentOption', 'three-sixty-pictures'); setCurrentOption('360 Pictures'); getPackageImage(packageName, 'three-sixty-pictures') }}>360 Pictures</a>
                                        </li>
                                        <li className={currentOption === 'Drone Pictures' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); getFileType('drone-pictures'); localStorage.setItem('currentOption', 'drone-pictures'); setCurrentOption('Drone Pictures'); getPackageImage(packageName, 'drone-pictures'); }}>Drone Pictures</a>
                                        </li>
                                        <li className={currentOption === 'CAD File' ? "current" : "item"}>
                                            <a href="" onClick={(e) => { e.preventDefault(); getFileType('cad-file'); localStorage.setItem('currentOption', 'dwg-files'); setCurrentOption('CAD File'); getPackageImage(packageName, 'dwg-files'); }}>CAD File</a>
                                        </li>
                                        <li className={currentOption === 'PDF Files' ? "current" : "item"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('pdf-files');
                                                localStorage.setItem('currentOption', 'pdf-files');
                                                setCurrentOption('PDF Files');
                                                getPackageImage(packageName, 'pdf-files')
                                            }}>PDF Files</a>
                                        </li>
                                        <li className={(currentOption === 'plan-picture' ? "current" : "item") + " no-mobile"}>
                                            <a href="" onClick={(e) => {
                                                e.preventDefault();
                                                getFileType('pdf-files');
                                                localStorage.setItem('currentOption', 'plan-picture');
                                                setCurrentOption('plan-picture')
                                                getPackageImage(packageName, 'pdf-files')
                                            }}>Plan & Picture</a>
                                        </li>
                                    </ul>
                                </nav>
                            </aside>
                            {(() => {
                                switch (currentOption) {
                                    case "Still Pictures":
                                    case "360 Pictures":
                                        return (
                                            <div className="uploaded-file-grid">
                                                <RenderContent
                                                    currentPath={currentPath}
                                                    updatePath={updatePath}
                                                    structureData={folderStructure}
                                                    resetPath={resetPath}
                                                    pictureTypeDisplay={pictureTypeDisplay}
                                                    selectedPictures={selectedPictures}
                                                    thePictureType={thePictureType}
                                                    bucketPrefix={bucketPrefix}
                                                    addSelectedPicture={addSelectedPicture}
                                                    packageData={packageData}
                                                    setPackageData={setPackageData}
                                                />
                                            </div>
                                        );
                                    case "Plan & Picture":
                                    case 'plan-picture':
                                        if (foldersFiles.length > 0) {
                                            return (
                                                <PdfList onEdit={setSelectedPdf} items={foldersFiles} setAllUsedPhotos={setAllUsedPhotos}/>
                                            );
                                        }
                                        return <p>No {pictureTypeDisplay} available for this package.</p>
                                    default:
                                        return (
                                            <div className="form-container" key={`key_div_${currentOption}`}>
                                                {foldersFiles.length > 0 ? (
                                                    <div className="pic-grid" key={`pcigr${currentOption}`}>
                                                        {showLoading ? (
                                                            <div className="loading-screen">
                                                                <div className="demo-container">
                                                                    <p>Loading files...</p>
                                                                    <span className="loader loader--m"></span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            foldersFiles.map((file, index) => {
                                                                const fileName = file.Key.split("/").pop();
                                                                if (thePictureType === "scope-of-work") {
                                                                    scopeOfWorkVideoUrl = `${s3BucketPrefixVideo}/fazzad-thumbnail-00001-${packageName}/scope-of-work/${fileName}`;
                                                                    scopeOfWorkVideoUrlVideo = `${videoBucketPreview}/${packageName}/scope-of-work/${fileName.replace(/\.\w+$/, ".mp4")}`;
                                                                }
                                                                const mainClass = "uploaded-file__preview";
                                                                const secondClass = "icon-image";
                                                                const fullClass =
                                                                    thePictureType === "pdf-files" ||
                                                                        thePictureType === "dwg-files"
                                                                        ? `${mainClass} ${secondClass}`
                                                                        : mainClass;
                                                                console.log(thePictureType)
                                                                const pictureType = thePictureType === ViewType.scopeOfWork ? ViewType.scopeOfWorkThumbnail : thePictureType
                                                                return (
                                                                    <figure className="upload-file" key={`afsgures_${index}`}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={`label_checkbox_${index}`}
                                                                            value={file.imagePath}
                                                                            key={`acheckboxsa_${index}`}
                                                                            onChange={(e) => addSelectedPicture(e, file)}
                                                                            checked={checkForChecked(selectedPictures, file)}
                                                                        />
                                                                        <label htmlFor={`label_checkbox_${index}`} key={`alabel_${index}`}>
                                                                            <span className={fullClass} key={`aspan_${index}`}>
                                                                                {thePictureType === "pdf-files" && getSvg("pdf")}
                                                                                {thePictureType === "dwg-files" && getSvg("cad-illustration")}
                                                                                {thePictureType !== "pdf-files" && thePictureType !== "dwg-files" && (

                                                                                    <ImageWithPresignedUrl
                                                                                        fileKey={file.Key}
                                                                                        bucketName={getBucketNameByViewType(pictureType)}
                                                                                        fileType={getFileExt(file.Key)}
                                                                                        setPreSignedUrl={(url) => setPackageData(data => ({ ...data, [pictureType]: url }))}
                                                                                        imageUrl={packageData[pictureType] ?? null}
                                                                                        key={`aimag_${index}`}
                                                                                    />
                                                                                )}
                                                                            </span>
                                                                            <figcaption key={`figs${index}`}>
                                                                                {fileName} - {thePictureType}
                                                                                &nbsp;
                                                                                {thePictureType === "scope-of-work" && (
                                                                                    <a
                                                                                        href=""
                                                                                        key={`ahaaref_${index}`}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            setShowPreviewModal(true);
                                                                                        }}
                                                                                    >
                                                                                        Preview &raquo;
                                                                                    </a>
                                                                                )}
                                                                            </figcaption>
                                                                        </label>
                                                                    </figure>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                ) : !showLoading ? (
                                                    <p>No {pictureTypeDisplay} available for this package.</p>
                                                ) : (
                                                    <div className="loading-screen">
                                                        <div className="demo-container">
                                                            <p>Loading files...</p>
                                                            <span className="loader loader--m"></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                }
                            })()}
                        </section>
                        <nav id="step-controls">
                            <div className="button-group">
                                <button
                                    type="button"
                                    className="button-m button-tertiary"
                                    onClick={(e) => { e.preventDefault(); navigate(`/editpackage/${packageName}`); }}
                                >
                                    <span className="text">
                                        Back
                                    </span>
                                </button>
                            </div>
                        </nav>
                        {showPreviewModal && (
                            <div id="modal-2" className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <button
                                            type="button"
                                            className="button-tertiary button-s button-icon modal-close"
                                            onClick={() => setShowPreviewModal(false)}
                                        >
                                            {getSvg('close')}
                                        </button>
                                    </div>
                                    <div className="modal__content">
                                        <div className="video rounded" key={`video_`}>
                                            <VideoWithPresignedUrl
                                                fileKey={`${packageName}/scope-of-work-videos/${foldersFiles[0]?.Key?.split("/")?.pop()?.replace(/\.\w+$/, ".mp4")}`}
                                                bucketName={getBucketNameByViewType(thePictureType)}
                                                fileType={'mp4'}
                                                setPreSignedUrl={(url) => setPackageData(data => ({ ...data, [thePictureType]: url }))}
                                                postUrl={packageData[ViewType.scopeOfWorkThumbnail] ?? null}
                                                videoUrl={packageData[thePictureType] ?? null} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}

function PageBreadcrumbs({ path, onCrumbClick, resetPath, pictureTypeDisplay, folderStructure }) {
    const isEmpty = Object.keys(folderStructure).length === 0;

    // if (isEmpty) {
    //     return (<p>No {pictureTypeDisplay} available for this package.</p>)
    // }
    return (
        <nav className="breadcrumbs-container" aria-label="Breadcrumbs">
            <ol>
                <li>
                    <a href="#" onClick={(e) => { e.preventDefault(); resetPath() }}>{pictureTypeDisplay}</a>
                    {path.length > 0 && <span className="separator">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </span>}
                </li>

                {path.map((crumb, index) => (
                    <li key={index}>
                        {index !== path.length - 1 ?
                            <a href="" onClick={(e) => {
                                e.preventDefault();
                                onCrumbClick(path.slice(0, index + 1));
                            }}>
                                {crumb}
                            </a>
                            : crumb
                        }
                        {index !== path.length - 1 && <span className="separator">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>}
                    </li>
                ))}
            </ol>
        </nav>
    );
}

// Function to render the content of the current folder
function RenderContent({ updatePath, structureData, resetPath, pictureTypeDisplay, selectedPictures, thePictureType, bucketPrefix, addSelectedPicture, currentPath, setPackageData, packageData }) {
    console.log(structureData);
    const currentItem = currentPath?.reduce((acc, key) => acc[key], structureData);
    if (!currentItem || currentItem._isFile) {
        // If it's a file or the path is incorrect, you can decide how to handle this.
        // For now, let's just return null or some fallback.
        return null;
    } else {
        // Sort keys to display folders first, then files
        const sortedKeys = Object.keys(currentItem).sort((a, b) => {
            const isAFile = currentItem[a]._isFile;
            const isBFile = currentItem[b]._isFile;
            return isAFile === isBFile ? 0 : isAFile ? 1 : -1;
        });

        return (
            <>
                <PageBreadcrumbs path={currentPath} onCrumbClick={updatePath} resetPath={resetPath} pictureTypeDisplay={pictureTypeDisplay} folderStructure={structureData} />

                <div className="pic-grid">
                    {sortedKeys.length > 0 ? (
                        sortedKeys.map((key, index) => {
                            const item = currentItem[key];
                            if (!item._isFile) {
                                // For folders, allow navigation into the folder
                                return (
                                    <figure key={`figure_${index}`}>
                                        <a href="" onClick={(e) => {
                                            e.preventDefault();
                                            updatePath([...currentPath, key]); // Navigate into the folder
                                        }}>
                                            <img src={graphicFolder} alt={key} />
                                            <figcaption>{key}</figcaption>
                                        </a>
                                    </figure>
                                );
                            } else {
                                // For files, display the file without modal interaction
                                return (
                                    <figure className="upload-file" key={`figures_${index}`}>
                                        <input
                                            type="checkbox"
                                            id={`label_checkbox_${index}`}
                                            value={key}
                                            key={`checkbox_s${index}`}
                                            onChange={(e) => addSelectedPicture(e, item)}
                                            checked={Array.isArray(selectedPictures) && selectedPictures.includes(item)}
                                        />
                                        <label htmlFor={`label_checkbox_${index}`} key={`labels_s_${index}`}>
                                            <span className="uploaded-file__preview" key={`spanner_${index}`}>
                                                <ImageWithPresignedUrl
                                                    fileKey={item.Key}
                                                    bucketName={getBucketNameByViewType(thePictureType)}
                                                    fileType={getFileExt(item.Key)}
                                                    setPreSignedUrl={(url) => setPackageData(data => ({ ...data, [thePictureType]: url }))}
                                                    imageUrl={packageData[thePictureType] ?? null}
                                                />
                                                <figcaption>{key}</figcaption>
                                            </span>
                                        </label>
                                    </figure>
                                );
                            }
                        })
                    ) : (
                        <p>No {thePictureType} files available for this package.</p>
                    )}
                </div>
            </>
        );
    }
}
