import React, { useEffect, useState, useRef } from 'react';
import { cleanMediaUrl } from '../../utils/clearMediaUrl';
import { downloadThePackage } from '../../utils/downloadPackage';
import { S3 } from '@aws-sdk/client-s3';
import { useNavigate, useParams } from 'react-router-dom';
import { Upload } from '@aws-sdk/lib-storage';
import { getPackageInfo, getPackageImageInfo } from '../../utils/getPackageInfo';
import AlertMessage from '../libs/AlertMessage';
import Axios from 'axios';
import BreadCrumbs from '../libs/BreadCrumbs';
import checkForMobile from '../../utils/checkForDeviceType';
import checkUserProfile from '../../utils/checkUserProfile';
import CreditCardForm from '../libs/CreditCardForm';
import getSvg from '../../utils/getSvg';
import iconCad from '../../images/icon-cad.svg';
import icon360 from '../../images/icon-360.svg';
import iconPictures from '../../images/icon-gallery.svg';
import iconDrone from '../../images/icon-drone.svg';
import iconPdf from '../../images/icon-pdf.svg';
import Header from '../libs/Header';
import ModalInfo from '../libs/ModalInfo';
import ModalAlertMessage from '../libs/ModalAlertMessage';
import NoCoverImage from '../../images/NoCoverScopeImage.png';
import PackageDetails from '../libs/PackageDetails';
import ProgressBar from '../libs/ProgressBar';
import uuid from 'react-uuid';
import StarRating from '../libs/StarRating';
import axios from 'axios';
import PayPalForm from '../libs/PayPalForm';
import { uploadWithSignedUrl } from "../../utils/awsHelpers";
export default function ProPackageDetails() {
    const prerecordedRef = useRef(null);
    const navigate = useNavigate();
    const [errorVideoDescription, setErrorVideoDescription] = useState(false);
    const [fileProgressSelfVideo, setFileProgressSelfVideo] = useState(0);
    const [has360Pictures, setHas360Pictures] = useState(false);
    const [hasDronePictures, setHasDronePictures] = useState(false);
    const [hasDwgPictures, setHasDwgPictures] = useState(false);
    const [hasfiles, setHasFiles] = useState(false);
    const [hasNoPackage, setHasNoPackage] = useState(false);
    const [hasPdfs, setHasPdfs] = useState(false);
    const [hasStillPictures, setHasStillPictures] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [isPaymentApproved, setIsPaymentApproved] = useState(false);
    const [isValidVideo, setIsValidVideo] = useState(true);
    const [libraryReferenceNumber, setLibraryReferenceNumber] = useState('');
    const [libraryVideos, setLibraryVideos] = useState([]);
    const [noFiles, setNoFiles] = useState(false);
    const { packageName } = useParams();
    const [packages, setPackages] = useState([]);
    const [packageId, setPackageId] = useState('');
    const [packageOwner, setPackageOwner] = useState('');
    const [rating, setRating] = useState(0);
    const [scopeOfWorkVideo, setScopeOfWorkVideo] = useState('');
    const [scopeOfWorkThumbnail, setScopeOfWorkVideoThumbnail] = useState('');
    const [selectedSelfVideo, setSelectedSelfVideo] = useState([]);
    const [selectedUploads, setSelectedUploads] = useState([]);
    const [showAlreadyContacted, setShowAlreadyContacted] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCreditCardModal, setShowCreditCardModal] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showLengthCheck, setShowLengthCheck] = useState(false);
    const [showLibrary, setShowLibrary] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showLengthMessage, setShowLengthMessage] = useState(false);
    const [showMore, setShowMore] = useState({});
    const [showNotAllowed, setShowNotAllowed] = useState(false);
    const [showNoFiles, setSHowNoFiles] = useState(false);
    const [showProcessing, setShowProcessing] = useState(0);
    const [showRating, setShowRating] = useState(false);
    const [ratingInfo, setRatingInfo] = useState([]);
    const [showUploadNewVideo, setShowUploadNewVideo] = useState(false);
    const [showUploadVideo, setShowUploadVideo] = useState(false);
    const [transactionCode, setTransactionCode] = useState(null);
    const [uploadStarted, setUploadStarted] = useState(0);
    const [videoDescription, setVideoDescription] = useState('');
    const [videoFileName, setVideoFileName] = useState('');
    const [videoName, setVideoName] = useState('');
    const [userInfo, setUserInfo] = useState();
    const videoFormatError = "Only these video formats are valid: mp4, mov, avi, wmv, flv, webm.";
    const surveyorAmountPaid = parseFloat(process.env.REACT_APP_SURVEYOR_COMMISION_AMOUNT * process.env.REACT_APP_COST_TO_CONTACT_HOMEOWNER).toFixed(2);
    const isLoggedIn = sessionStorage.getItem('isLoggedIn');
    const replyBucket = process.env.REACT_APP_PROJECT_VIDEO_SOURCE;
    const leadtype = 0;
    const userId = sessionStorage.getItem('userId');
    const userType = sessionStorage.getItem('userType');
    const userTypeToBeChecked = parseInt(process.env.REACT_APP_PAID_PRO_USER_TYPE_TO_BE_CHECKED);
    const videoExtensionsRegex = /\.(mp4|mov|avi|wmv|flv|webm)$/i;
    const videoRef = useRef(null);
    sessionStorage.setItem('isPropertyOwner', 'false');
    sessionStorage.setItem('userType', userTypeToBeChecked);
    let mime = '';
    const rd = new FileReader();
    let uploadFileName = '';

    const getUserProfile = async (userId, userType, userTypeToBeChecked) => {
        const { isGoodToGo, user } = await checkUserProfile(userId, userType, userTypeToBeChecked);
        if (isGoodToGo) {
            setIsMobile(checkForMobile());
            setShowNotAllowed(false);
            getPackage(packageName, userId);
            getRatings(packageName);
            setUserInfo(user);
        } else {
            setShowNotAllowed(true);
        }
    }

    useEffect(() => {
        // tryAuth();
        if (isLoggedIn === 'true') {
            try {
                getUserProfile(userId, userType, userTypeToBeChecked);
            } catch (err) {
                console.log('error trying to get the package id = ', err);
            };
        } else {
            handleGoToLogin();
        }
    }, []);

    // const tryAuth = async () => {
    //     const response = axios.post(`${process.env.REACT_APP_LOCALHOST}/authenticate`, {withCredentials: true});
    //     console.log('response = ', response);
    // }

    const getRatings = (packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getRatings`, { packageName: packageName }).then((response) => {
            if (response.data.length > 0) {
                setRatingInfo(response.data);
            }
        }).catch(function (error) {
            console.log('getRatings error = ', error);
        })
    }

    const handleGoToLogin = () => navigate('/login');

    /*const closeTransaction = (transactionCode, type) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/closeOpenTransaction`, { transactionCode: transactionCode, type: type, serviceType: 1 }).then((response) => {
            return;
        }).catch(function (error) {
            console.log('closeTransaction error = ', error);
        })
    }*/

    const changeHandlerUploads = (event) => {
        setNoFiles(false);
        setIsValidVideo(true);
        event.preventDefault();
        const chosenFile = event.target.files[0].name;
        if (!videoExtensionsRegex.test(chosenFile)) {
            setVideoName('');
            setIsValidVideo(false);
        } else {
            setVideoName(chosenFile);
            setIsValidVideo(true);
            let file = event.target.files[0];
            const fileNameArray = file.name.split('.');
            setSelectedUploads(event.target.files);
            if (fileNameArray[fileNameArray.length - 1].toLowerCase() === 'mp4') {
                setShowLengthCheck(true);
                setShowLengthMessage(false);
                mime = file.type;
                rd.onload = function (e) {
                    let blob = new Blob([e.target.result], { type: mime }),
                        url = URL.createObjectURL(blob), // create o-URL of blob
                        video = document.createElement("video");
                    video.preload = "metadata";
                    video.addEventListener("loadedmetadata", function () {
                        if (video.duration > 200) {
                            setShowLengthCheck(false);
                            setShowLengthMessage(true);
                        } else {
                            setShowLengthCheck(false);
                            setShowLengthMessage(false);
                        }
                        (URL).revokeObjectURL(url);
                    });
                    video.src = url;
                };
                rd.readAsArrayBuffer(file);
            }
        }
    }

    const handleTransactionApproval = (approval, userId, transactionCode, amountPaid, packageName, packageId) => {
        if (approval?.isApproved) {
            setIsPaymentApproved(true);
            setShowProcessing(0);
            setShowCreditCardModal(false);
            setShowUploadVideo(true);
            setTransactionCode(approval.additionalData?.paypal_id?.toString() ?? approval.additionalData?.network_transaction_reference_id?.toString() ?? "");
        }
    };

    const saveVideoUploaded = (uploadFileName, videoDescription, userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveProPreRecordedVideos`, {
            uploadFileName: uploadFileName,
            userId: userId,
            videoDescription: videoDescription,
        }).then((response) => {
            setVideoDescription('');
            setSelectedUploads([]);
            setSelectedUploads([]);
            setVideoName('');
            getLibraryVideos(userId);
        }).catch(function (error) {
            console.log('tags error = ', error);
        });
    }

    const createPaymentEntry = (serviceType, userId, theTransactionCode, amountPaid, packageName, packageId, uniqueIdentifier) => {
        setTransactionCode(theTransactionCode);
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/createPaymentEntry`,
            {
                userId: userId,
                transactionCode: theTransactionCode,
                amountPaid: amountPaid,
                packageName: packageName,
                packageId: packageId,
                serviceType: serviceType,
                uniqueIdentifier: uniqueIdentifier
            }).then((response) => {
                return response.data;
            }).catch(function (error) {
                console.log('createPaymentEntry = ', error);
            })
    }

    const uploadSelectedVideo = (userId, packageId, packageName, fileName, uploadtype) => {
        if (videoFileName !== '') {
            setShowConfirmation(false);
            saveSubmissionInfo(userId, packageId, packageName, fileName, uploadtype);
            setSHowNoFiles(false);
        } else {
            setSHowNoFiles(true);
            setVideoFileName('');
        }
    }

    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const saveSubmissionInfo = (userId, packageId, packageName, fileName, uploadType, libraryReferenceNumber, transactionCode) => {
        const theTransactionCode = transactionCode === null ? generateRandomString(35) : transactionCode;
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/savePackageSubmission`,
            {
                packageId: packageId,
                packageName: packageName,
                userId: userId,
                videoFileName: fileName,
                uploadType: uploadType,
                libraryReferenceNumber: libraryReferenceNumber,
                transactionCode: theTransactionCode,
                serviceType: 3,
            }).then((response) => {
                setVideoFileName('');
                sendEmail(userId, theTransactionCode, packageId, uploadType);
            }).catch(function (error) {
                setVideoFileName('');
                setShowConfirmation(false);
                console.log('save Package Submission error = ', error);
            })
    }


    const sendRewardsErrorEmail = (userId, transactionCode, amountPaid, packageName, surveyor) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendErrorRewardsEmail`, { "userId": userId, "transactionCode": transactionCode, "amountPaid": amountPaid, "packageName": packageName, "surveyor": surveyor }).then((response) => {
            return response.data;
        }).catch(function (error) {
            console.log('sendRewardsErrorEmail = ', error);
        })
    }

    const downloadPackage = async (pckId) => {
        setIsDownloading(true);
        try {
            await downloadThePackage(pckId);
            setIsDownloading(false);
        } catch (e) {
            setIsDownloading(false);
            console.error(`Download package error: ${e}`);
        }
    }

    const handleVideoSelect = (referenceNumber, filename) => {
        setVideoFileName(getFileName(filename));
        setLibraryReferenceNumber(referenceNumber);
    };

    const uploadVideo = async (packageName, packageId, transactionCode) => {
        try {
            if (selectedSelfVideo.length > 0) {
                setIsPaymentApproved(false);
                setShowCreditCardModal(false);
                if (isValidVideo) {
                    let selfVideoArray = [];
                    setUploadStarted(0);
                    setFileProgressSelfVideo(0);
                    setShowErrorMessage(false);
                    selfVideoArray = [...selectedSelfVideo];
                    let videoArray = [];
                    videoArray = selfVideoArray.splice(0, 1);

                    await Promise.all(
                        videoArray.map(async (file) => {
                            setUploadStarted(1);
                            const fileName = cleanMediaUrl(file.name);
                            const key = `${packageName}/pro-videos-library/${fileName}`;

                            await uploadWithSignedUrl(file, replyBucket, key, (myProgress) => {
                                setFileProgressSelfVideo(myProgress);
                            });

                            // setShowProcessing(1);
                            setUploadStarted(0);
                            setSelectedSelfVideo([])
                            setTimeout(() => {
                                setShowProcessing(1);
                                setShowUploadVideo(false);
                                saveSubmissionInfo(userId, packageId, packageName, fileName, 'upload', libraryReferenceNumber, transactionCode);
                            }, 10000);
                        })
                    );
                }
            } else {
                setShowErrorMessage(true);
            }
        } catch (e) {
            setShowUploadVideo(false);
            console.error(`Uploading Scope of Work Videos file error: ${e}`);
        }
    }

    const getPackageImage = async (pck) => {
        const packageImageInfo = await getPackageImageInfo(pck);
        setShowLoader(false);
        if (typeof pck.packageName !== 'undefined') {
            if (typeof packageImageInfo.response[0] !== 'undefined' && typeof packageImageInfo.response[0].Key !== 'undefined') {
                setScopeOfWorkVideoThumbnail(packageImageInfo.scopeOfWorkVideoThumbnail);
                setScopeOfWorkVideo(packageImageInfo.scopeOfWorkVideo);
                setHasVideo(packageImageInfo.hasVideo);
            } else {
                setHasVideo(false);
            }
        }
    }

    const getLibraryVideos = (userId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/getProsVideosFromLibrary`, { userId: userId }).then((response) => {
            setLibraryVideos(response.data);
        }).catch(function (error) {
            console.log('error = ', error);
        });
    }

    const handleReadMore = (index) => {
        setShowMore({ ...showMore, [index]: !showMore[index] });
    }

    const getPackageDescription = (packageDesc, index) => {
        if (!showMore[index]) {
            if (packageDesc.length > process.env.REACT_APP_DISPLAY_TEXT_LENGTH) {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH) + '...';
            } else {
                return packageDesc.substring(0, process.env.REACT_APP_DISPLAY_TEXT_LENGTH);
            }
        } else {
            return packageDesc;
        }
    }

    const checkIfHasContactedHomeOwner = (userId, packageName) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkIfHasContactedHomeOwner`, { userId: userId, packageName: packageName }).then((response) => {
            if (response.data.length > 0) {
                setShowAlreadyContacted(true);
            } else {
                setShowAlreadyContacted(false);
                toggleCreditCardModal();
            }
        }).catch(function (error) {
            console.log('Error checking if has contacted home owner = ', error);
        })
    }

    const toggleCreditCardModal = () => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/checkForOpenBalance`, { userId: userId, serviceType: 1 }).then((response) => {
            if (response.data.length > 0) {
                setTransactionCode(response.data[0].transaction_code);
                setShowUploadVideo(true);
            } else {
                setShowCreditCardModal(!showCreditCardModal);
                if (isPaymentApproved) {
                    setShowUploadVideo(true);
                }
            }
        }).catch(function (error) {
            console.log('Error checking for balance = ', error);
        })
    };

    const getFileName = (filename) => {
        const fileNameArray = filename.split('/');
        return fileNameArray[fileNameArray.length - 1];
    }

    const changeVideoToUpload = (event) => {
        event.preventDefault();
        setIsValidVideo(true);
        setShowErrorMessage(false);
        setSelectedSelfVideo(event.target.files);

        const chosenFile = event.target.files[0].name;
        if (!videoExtensionsRegex.test(chosenFile)) {
            setIsValidVideo(false);
        } else {
            setVideoFileName(chosenFile);
            setIsValidVideo(true);
        }
    };

    const sendEmail = (userId, transactionCode, packageId, uploadType) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/sendHomeOwnerPackageResponse`, { packageName: packageName, uploadType: uploadType }).then((response) => {
            if (response.data.length > 0) {
                setShowLibrary(false);
                setShowConfirmation(true);

            }
            //closeTransaction(transactionCode, 'credit-card');
        }).catch(function (error) {
            //closeTransaction(transactionCode, 'credit-card');
            console.log('sendEmail error = ', error);
        })
    }

    const goTopage = (type, pageNameTitle, packageName) => {
        if (type === 'survey') {
            navigate(`/survey`);
        } else if (pageNameTitle === 'pdf') {
            navigate(`/pdf/${packageName}/${leadtype}`)
        } else if (pageNameTitle === 'dwg') {
            navigate(`/dwg/${packageName}/${leadtype}`)
        } else if (pageNameTitle === 'still') {
            navigate(`/galleries/${packageName}/still-pictures/${leadtype}`)
        } else if (pageNameTitle === '360') {
            navigate(`/galleries/${packageName}/three-sixty-pictures/${leadtype}`)
        } else {
            navigate(`/gallery/${packageName}/${type}/${leadtype}`);
        }
    }

    const handleUpload = async (bucketName) => {
        try {
            setNoFiles(false);
            if (selectedUploads.length > 0 && videoDescription.trim() !== '') {
                const filesArray = [...selectedUploads];
                await Promise.all(
                    filesArray.map(async (file) => {
                        uploadFileName = cleanMediaUrl(file.name);
                        uploadFileName = `${userId}/video-library/${uploadFileName}`;
                        setUploadStarted(true);

                        await uploadWithSignedUrl(file, bucketName, uploadFileName, (myTotal) => {
                            if (parseInt(myTotal) === 100) {
                                setShowConfirmation(true);
                                setShowProcessing(0);
                                setShowProcessing(true);
                            }
                        });

                        setTimeout(() => {
                            if (prerecordedRef.current) {
                                prerecordedRef.current.value = '';
                            }
                            saveVideoUploaded(uploadFileName, videoDescription, userId);
                            setUploadStarted(false);
                            setShowConfirmation(false)
                            setVideoDescription('');
                        }, 50000);
                    })
                );
            } else {
                if (videoDescription.trim() === '') {
                    setErrorVideoDescription(true)
                }
                setShowProcessing(0);
                if (selectedUploads.length === 0) {
                    setNoFiles(true);
                }
            }
        } catch (e) {
            console.error(`Uploading Videos file error: ${e}`);
            setUploadStarted(false);
        }
    };

    const getPackage = async (packageName) => {
        try {
            const packageInfo = await getPackageInfo(packageName);
            if (packageInfo.response) {
                setPackageId(packageInfo.response.packageId);
                setHasFiles(packageInfo.hasFiles);
                setHas360Pictures(packageInfo.has360Pictures);
                setHasDronePictures(packageInfo.hasDronePictures);
                setHasDwgPictures(packageInfo.hasDwg);
                setHasPdfs(packageInfo.hasPdfs);
                setHasStillPictures(packageInfo.hasStillPictures);
                setPackages([packageInfo.response]);
                getPackageImage(packageInfo.response);
                setPackageOwner(packageInfo.response.userId);
            } else {
                setHasNoPackage(true);
            }
        } catch (e) {
            setShowLoader(false);
        }
    }

    const saveRating = (rating, userId, packageName, surveyorId) => {
        Axios.post(`${process.env.REACT_APP_LOCALHOST}/saveRating`, { rating: rating, userId: userId, packageName: packageName, surveyorId: surveyorId }).then((response) => {
            setShowRating(false);
            getRatings(packageName);
        }).catch(function (error) {
            console.log('saveRating error = ', error);
        })
    }

    return (
        <div id="fazzad-site-container">
            <Header />
            <main id="fazzad-main">
                <div id="app-container">
                    <BreadCrumbs
                        header1={'Home'}
                        link1={'profeed'}
                        header2={'Package Details'}
                        link2={``}
                        isLink1Clickable={true}
                        isLink2Clickable={false}
                        isLink2Navigation={false}
                        header3={''}
                        link3={''}
                        setIsPackageUpdated={false}
                        setUploadMoreFiles={false}
                    />
                    {showLoader && (<span className="loader loader--l"></span>)}
                    {packages.length > 0 && (
                        <PackageDetails
                            downloadPackage={downloadPackage}
                            filesText={'Files'}
                            getPackageDescription={getPackageDescription}
                            goTopage={goTopage}
                            handleReadMore={handleReadMore}
                            has360Pictures={has360Pictures}
                            hasDronePictures={hasDronePictures}
                            hasDwgPictures={hasDwgPictures}
                            hasFiles={hasfiles}
                            hasPdfs={hasPdfs}
                            hasStillPictures={hasStillPictures}
                            hasVideo={hasVideo}
                            headerTitle={'Package Details'}
                            iconPdf={iconPdf}
                            iconPictures={iconPictures}
                            icon360={icon360}
                            iconDrone={iconDrone}
                            iconCad={iconCad}
                            leadtype={leadtype}
                            NoCoverImage={NoCoverImage}
                            numberOfViews={null}
                            packages={packages}
                            packageDescriptionHeader={'Scope of work'}
                            packageName={packageName}
                            scopeOfWorkVideo={scopeOfWorkVideo}
                            scopeOfWorkThumbnail={scopeOfWorkThumbnail}
                            showContactHomeOnwerButton={true}
                            showDeletePackageButton={false}
                            showDownloadPackageButton={true}
                            showHeaderButtons={true}
                            showMore={showMore}
                            checkIfHasContactedHomeOwner={checkIfHasContactedHomeOwner}
                            videoText={'Scope of work video'}
                            currentPage={'pro package details'}
                            userId={userId}
                            userType={userType}
                            videoName={scopeOfWorkVideo}
                            ratingInfo={ratingInfo}
                        />
                    )}
                    <div>
                        {showAlreadyContacted && (
                            <div className="modal-container">
                                <div className="modal">
                                    <div className="modal__header">
                                        <AlertMessage
                                            messageHeader={"Property Owner was contacted already"}
                                            messageBody={""}
                                            messageType={"warning"}
                                            showMessageBody={true}
                                            onButtonClick={() => { setShowAlreadyContacted(false); }}
                                        />
                                    </div>
                                    <div className="modal__content">
                                        <p>You can only contact the Property Owner once per project.</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {hasNoPackage && (
                        <AlertMessage
                            messageHeader={"No packages found."}
                            messageBody={"The package you are trying to edit does not exist or doesn't belong to you."}
                            startDate={null}
                            setShowWarningContainerMessage={''}
                            messageType={"informational"}
                            showButton={false}
                        />
                    )}
                    {isDownloading && (
                        <ModalInfo
                            messageHeader={"Downloading your package..."}
                            messageBody={`Files will be saved on your ${isMobile ? 'mobile device' : 'computer'}.`}
                            messageType={"informational"}
                            showCloseButton={false}
                            showLoader={true}
                        />
                    )}
                    {showCreditCardModal && (
                        <PayPalForm
                            modalHeader='Contact Property Owner'
                            amountPaid={parseInt(process.env.REACT_APP_COST_TO_CONTACT_HOMEOWNER) * 100}
                            onClose={() => setShowCreditCardModal(false)}
                            packageName={packageName}
                            userId={userId}
                            userInfo={userInfo}
                            serviceType={1}
                            onTransactionApproval={(approval) => handleTransactionApproval(approval, userId, transactionCode, surveyorAmountPaid, packages[0].packageName, packages[0].packageId)}
                            builtPackage={packages[0]}
                        />
                    )}
                    {showUploadVideo && (
                        <div className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">Contact Property Owner</h2>
                                </div>
                                <div className="modal__content">
                                    {!showProcessing === 1 && uploadStarted === 0 && (
                                        <>
                                            <p>In order to contact the property owner, you have to upload a short video description of yourself and your company.</p>
                                            <p>After the video is uploaded, the property owner will be contacted immediately via email.</p>
                                        </>
                                    )}
                                    {uploadStarted === 0 && parseInt(fileProgressSelfVideo) !== 100 && (
                                        <>
                                            <section className="outlined collapsed">
                                                <label className='gray-800'>Choose video to upload</label>
                                                <div className='file-input-container'>
                                                    <div className={`form-field file-input ${!isValidVideo ? 'error' : ''}`}>
                                                        <input type="file" id="videotoupload" className="file-input__input" onClick={() => videoRef.current.blur()} onChange={changeVideoToUpload} ref={videoRef} />
                                                        <div className='button-group'>
                                                            <label htmlFor="videotoupload" className="button button-m button-tertiary">
                                                                {getSvg('upload')}
                                                                <span className="text">Video&hellip;</span>
                                                            </label>
                                                            {videoFileName !== '' && (
                                                                <p>{videoFileName}</p>
                                                            )}
                                                            {!isValidVideo && (
                                                                <p className="validation-message">{videoFormatError}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </>
                                    )}

                                    {
                                        parseInt(fileProgressSelfVideo) > 0 && parseInt(fileProgressSelfVideo) !== 100 ?
                                            <>
                                                <div className='fz-block-sm'>
                                                    <AlertMessage
                                                        messageHeader={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={''}
                                                        messageType={"warning"}
                                                        showButton={false}
                                                    />
                                                </div>
                                                <ProgressBar
                                                    fileProgress={fileProgressSelfVideo}
                                                    fileType='video'
                                                />
                                            </>
                                            : parseInt(fileProgressSelfVideo) === 0 && uploadStarted === 1 ?
                                                <p className='fz-align-center'>
                                                    Preparing video to be uploaded&nbsp;&nbsp; <span className="loader loader--s"></span>
                                                </p>
                                                : ''
                                    }
                                    {parseInt(fileProgressSelfVideo) === 100 && (
                                        <p className='fz-align-center'>{process.env.REACT_APP_PROCESSING_VIDEO_MESSAGE}&nbsp;
                                            <span className="loader loader--s"></span>
                                        </p>
                                    )}

                                    {uploadStarted === 0 && showProcessing === 0 && parseInt(fileProgressSelfVideo) === 0 && (
                                        <div className="button-group">
                                            <div className='fz-block-sm'>
                                                <button
                                                    type='button'
                                                    id='uploadbutton'
                                                    className="button-m button-primary"
                                                    onClick={() => { uploadVideo(packages[0].packageName, packages[0].packageId, transactionCode) }}
                                                >
                                                    {getSvg('upload')}
                                                    <span className='text'>Upload video</span>
                                                </button>
                                            </div>
                                            <div className='fz-block-sm'>
                                                <button
                                                    type='reset'
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className='button-m button-secondary'
                                                    onClick={() => { setShowUploadVideo(false); setShowErrorMessage(false); setSelectedSelfVideo([]); setIsPaymentApproved(false); setShowCreditCardModal(false); }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                        </div>

                                    )}
                                    {showErrorMessage && (
                                        <AlertMessage
                                            messageHeader={"Select a video to upload."}
                                            messageBody={""}
                                            startDate={null}
                                            setShowWarningContainerMessage={setSHowNoFiles}
                                            messageType={"error"}
                                            showButton={false}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {showLibrary && (
                        <div id="modal-1" className="modal-container">
                            <div className="modal">
                                <div className="modal__header">
                                    <h2 className="modal-title h4">Select video to send to property owner</h2>
                                    {libraryVideos.length === 0 ?
                                        <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setShowLibrary(false); }}>
                                            <span className="icon">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17 7L7 17M7 7L17 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                        </button>
                                        : ''
                                    }
                                </div>
                                <div className="modal__content">
                                    {libraryVideos.length > 0 ?
                                        <>
                                            <div className="video-container">
                                                {libraryVideos.map((video, index) => (
                                                    <div key={index} className="video-wrapper">
                                                        <div className="radio-wrapper">
                                                            <input
                                                                type="radio"
                                                                name="selectedVideo"
                                                                value={getFileName(video.videoFileName)}
                                                                onChange={() => { handleVideoSelect(video.videoReferenceNumber, video.videoFileName); }} />
                                                        </div>
                                                        <video controls width="100%">
                                                            <source src={`${process.env.REACT_APP_S3_PROJECTS_VIDEOS_PREFIX}/${video.videoFileName}`} type="video/mp4" />
                                                        </video>
                                                        <div className="video-details">
                                                            <p>{video.videoFileName}</p>
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                            <div className="button-group">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary fz-btn-sm btn-icon-left"
                                                    aria-label="Download as-build package"
                                                    value="Send video"
                                                    onClick={() => { uploadSelectedVideo(userId, packageId, packageName, videoFileName, 'library'); }}
                                                >
                                                    <i className="icon-mail"></i>
                                                    Reply to property owner
                                                </button>
                                                <button
                                                    type='reset'
                                                    aria-label='Exit'
                                                    value='Exit'
                                                    className='btn fz-btn-small btn-secondary'
                                                    onClick={() => {
                                                        setShowLibrary(false);
                                                        setShowUploadVideo(true);
                                                        setVideoFileName('');
                                                        setShowConfirmation(false);
                                                        setIsPaymentApproved(false);
                                                        setShowCreditCardModal(false);
                                                    }}
                                                >
                                                    Exit
                                                </button>
                                            </div>
                                            {showNoFiles && (
                                                <>
                                                    <div><br /></div>
                                                    <AlertMessage
                                                        messageHeader={"In order to reply to the property owner, you need to select one video."}
                                                        messageBody={""}
                                                        startDate={null}
                                                        setShowWarningContainerMessage={setSHowNoFiles}
                                                        messageType={"informational"}
                                                        showButton={true}
                                                    />
                                                </>
                                            )}
                                        </>
                                        :
                                        <div>
                                            <p>You don't have any videos in your library</p>
                                            <button
                                                type="button"
                                                className="button-m button-primary btn-icon-left"
                                                aria-label="New upload to library"
                                                value="Upload video"
                                                onClick={() => {
                                                    setIsPaymentApproved(false);
                                                    setShowCreditCardModal(false);
                                                    setShowUploadNewVideo(true);
                                                }}
                                            >
                                                <i className="icon-upload"></i>
                                                Upload new video
                                            </button>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    )}
                </div>

            </main>
            {showConfirmation ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4">Video sent</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => { setFileProgressSelfVideo(0); setShowConfirmation(false); setShowRating(true); }}>
                                {getSvg('close')}
                            </button>
                        </div>

                        <div className="modal__content">
                            <AlertMessage
                                messageHeader={"The property owner will receive an email with your video response."}
                                showButton={false}
                                messageType={"informational"}
                            />
                        </div>
                    </div>
                </div>
                : ''
            }

            {showNotAllowed ?
                <div className="modal-container">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 warning">You are not allowed to see this page.</h2>
                            <button type="button" className="button-tertiary button-s button-icon modal-close" onClick={() => navigate('/')}>
                                {getSvg('close')}
                            </button>
                        </div>
                        <div className="modal__content">
                            <p>You are not allowed to view this page or this package.</p>
                        </div>
                    </div>
                </div>
                : ''
            }
            {showRating ?
                <div className="modal-container rating-modal">
                    <div className="modal">
                        <div className="modal__header">
                            <h2 className="modal-title h4 info">Rate this package</h2>
                        </div>
                        <div className="modal__content">
                            <StarRating totalStars={5} rating={rating} setRating={setRating} />
                        </div>
                        <div className="modal__action">
                            <div className="button-group">
                                <button
                                    className="button button-m button-secondary"
                                    type="button"
                                    onClick={(e) => setRating(0)}
                                >
                                    Clear
                                </button>
                                <button
                                    className="button button-m button-primary"
                                    type="button"
                                    onClick={() => saveRating(rating, userId, packageName, packageOwner)}
                                >
                                    Rate
                                </button>
                            </div>
                            <button
                                className="button button-m button-tertiary"
                                type="button"
                                onClick={(e) => setShowRating(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
            {showUploadNewVideo ?
                <div>
                    {uploadStarted ?
                        <div className='block-xx'>
                            <ModalAlertMessage
                                messageHeader={"File upload progress will be lost!"}
                                messageBody={process.env.REACT_APP_NAVIGATE_AWAY_MESSAGE}
                                showMessageBody={true}
                                messageType={"informational"}
                            />
                        </div> : ''
                    }
                    {showLengthCheck ?
                        <div className='block-xx'>
                            <div className='fz-alert alert-warning'>
                                <i className='icon-warning'></i>
                                <strong>Checking for file duration</strong>
                            </div>
                        </div> : ''
                    }
                    {showLengthMessage ?
                        <div className='block-xx'>
                            <div className='fz-alert alert-error'>
                                <i className='icon-error'></i>
                                <strong>Videos can not be longer than 5 minutes. Select a different file adn try it again.</strong>
                            </div>
                        </div>
                        : ''
                    }

                    <div id="modal-1" className="modal-container">
                        <div className="modal">
                            <div className="modal__header">
                                <h2 className="modal-title h4">Video upload</h2>
                            </div>
                            <label className='gray-800'>Video</label>
                            <div className={`form-field file-input ${!isValidVideo || noFiles ? 'error' : ''}`}>
                                <input type="file" id="vido" className="file-input__input" onClick={() => prerecordedRef.current.blur()} onChange={changeHandlerUploads} ref={prerecordedRef} />
                                <label htmlFor="vido" className="button button-m button-tertiary">
                                    <span className="icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <span className="text">Choose video&hellip;</span>
                                </label>
                                {!isValidVideo ?
                                    <p className="validation-message">{videoFormatError}</p>
                                    : ''
                                }
                                {noFiles ?
                                    <p className="validation-message">Please select a video to be uploaded</p>
                                    : ''
                                }
                                {videoName !== '' ? <p>{videoName}</p> : ''}
                            </div>
                            <div className={`form-field text-area ${errorVideoDescription ? 'error' : ''}`}>
                                <label htmlFor='videodescription'>Video description</label>
                                <div className="input-container">
                                    <textarea
                                        placeholder="Enter a short description to identify this video in your library"
                                        value={videoDescription}
                                        id='videodescription'
                                        onChange={(e) => { setVideoDescription(e.target.value); }}
                                        onFocus={(e) => { setErrorVideoDescription(false); }}
                                    >
                                    </textarea>
                                </div>
                                {errorVideoDescription ?
                                    <p className="validation-message">Please enter a description for this video.</p>
                                    : ''
                                }
                            </div>

                            <div className="button-group">
                                <span className='file-input__files'></span>
                                {!showLengthCheck && !showLengthMessage ?
                                    <button
                                        type='button'
                                        aria-label='Upload'
                                        value='Upload'
                                        className='button-primary button-m'
                                        onClick={() => handleUpload(process.env.REACT_APP_PROJECT_VIDEO_SOURCE)}
                                    >
                                        Upload videos
                                    </button>
                                    : ''
                                }
                                <button
                                    type='reset'
                                    aria-label='Exit'
                                    value='Exit'
                                    className='button-m  button-secondary'
                                    onClick={() => { setErrorVideoDescription(''); setShowUploadNewVideo(false); setNoFiles(false); setVideoFileName(''); setIsValidVideo(true) }}
                                >
                                    Exit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ''}
        </div>
    )
}
